import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { getSubsidies } from "redux/reducers/subsidies"
import styled from "styled-components"
import { useCurrentUser } from "../../context/user/UserContext"
import { DbgPaths } from "../../../utils/Paths"
import { FormattedMessage, FormattedNumber } from "react-intl"
import { getCurrencyCode } from "redux/reducers/companyInfo"
import { GetCategoryName } from "../reimbursements/ReimbursementCategoryMessages"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  DownIcon,
  InternalIcon,
  Link,
  Typography
} from "@carrotfertility/carotene-core"
import { useHistory } from "react-router-dom"
import { RetinaImage } from "../atoms/images/RetinaImage"
import { PlanInfoBadgeContainer } from "../../containers/planinfo/PlanInfoBadgeContainer"
import { useFlags } from "launchdarkly-react-client-sdk"
import { getCategoryCoverage } from "../../../utils/CategoryCoverage"

const optionsKey = "available-funds-module"
const availableFundsOptions = JSON.parse(localStorage.getItem(optionsKey)) || { expanded: {} }

const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const CategoryExpands = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1%;
  inline-size: 90%;
  justify-content: space-between;
`

const CategoryBenefitContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const AvailableFundsAmounts = (subsidy, showRemainingAnnual, showRemainingLifetime) => {
  const lifetimeAvailable = subsidy.lifetimeAmounts.available
  const yearlyAvailable = subsidy.yearlyAmounts.available
  const hasUsedFunds = subsidy.lifetimeAmounts.claimed > 0
  const currencyCode = useSelector(getCurrencyCode)
  return (
    <CategoryExpands>
      {showRemainingAnnual && (
        <CategoryBenefitContainer>
          <div className={"flex flex-row flex-wrap"}>
            <Typography
              variant="overline"
              fontWeight="normal"
              color={(theme) => theme.palette.text.secondary}
              marginRight={(theme) => theme.tokens.spacing.xxxl}
            >
              {hasUsedFunds ? (
                <FormattedMessage defaultMessage="Remaining annual" />
              ) : (
                <FormattedMessage defaultMessage="Available annual" />
              )}
            </Typography>
          </div>
          <Typography variant="body1" fontWeight="bold" color={(theme) => theme.palette.text.secondary}>
            {subsidy.hasUnlimitedBenefit ? (
              <FormattedMessage defaultMessage="Unlimited" />
            ) : (
              <FormattedNumber
                value={Math.max(yearlyAvailable / 100, 0)}
                style="currency"
                currency={currencyCode}
                maximumFractionDigits="0"
                currencyDisplay="narrowSymbol"
              />
            )}
          </Typography>
        </CategoryBenefitContainer>
      )}
      {showRemainingLifetime && (
        <CategoryBenefitContainer>
          <div className={"flex flex-row flex-wrap"}>
            <Typography variant="overline" fontWeight="normal" color={(theme) => theme.palette.text.secondary}>
              {hasUsedFunds ? (
                <FormattedMessage defaultMessage="Remaining lifetime" />
              ) : (
                <FormattedMessage defaultMessage="Available lifetime" />
              )}
            </Typography>
          </div>
          <Typography variant="body1" fontWeight="bold" color={(theme) => theme.palette.text.secondary}>
            {subsidy.hasUnlimitedBenefit || subsidy.lifetimeAmounts.max === null ? (
              <FormattedMessage defaultMessage="Unlimited" />
            ) : (
              <FormattedNumber
                value={Math.max(lifetimeAvailable / 100, 0)}
                style="currency"
                currency={currencyCode}
                maximumFractionDigits="0"
                currencyDisplay="narrowSymbol"
              />
            )}
          </Typography>
        </CategoryBenefitContainer>
      )}
    </CategoryExpands>
  )
}

const AvailableFundsByCategory = (subsidy, hasQmeCategory, showRemainingAnnual, showRemainingLifetime) => {
  const { employeeId } = useCurrentUser()
  // eslint-disable-next-line no-prototype-builtins
  if (!availableFundsOptions.expanded.hasOwnProperty(employeeId)) {
    availableFundsOptions.expanded[employeeId] = {}
  }
  const categoryName = GetCategoryName(subsidy.reimbursementCategoryNameMessageKey, hasQmeCategory)
  return (
    <>
      <Box>
        <Accordion {...categoryName}>
          <AccordionSummary
            expandIcon={<DownIcon fontSize="large" />}
            aria-controls={`accordion-content-available-funds-${categoryName}`}
            id={`accordion-content-available-funds-${categoryName}`}
            marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
          >
            <Typography variant="h5">{categoryName}</Typography>
          </AccordionSummary>
          <AccordionDetails marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
            <Typography marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
              {AvailableFundsAmounts(subsidy, showRemainingAnnual, showRemainingLifetime)}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Divider />
    </>
  )
}

// Changed to component so passed props with new syntax -- more conventional doesn't necessarily add anything else
const AvailableFundsModuleCategories = (subsidies) => {
  const hasMultipleCategories = subsidies.length > 1
  const subsidiesToRender = useMemo(
    () => subsidies.filter((subsidy) => !!subsidy.reimbursementCategoryNameMessageKey),
    [subsidies]
  )
  const hasQmeCategory = getCategoryCoverage(subsidies).qme
  const showRemainingAnnual = subsidies.some((subsidy) => subsidy.yearlyAmounts.max !== subsidy.lifetimeAmounts.max)
  const showRemainingLifetime = subsidies.some((subsidy) => subsidy.lifetimeAmounts.max !== null)
  return (
    <>
      <CategoriesContainer>
        <Box paddingBottom={(theme) => theme.tokens.spacing.md} />
        <div>
          {subsidiesToRender.map((subsidy, idx) =>
            hasMultipleCategories ? (
              <React.Fragment key={idx}>
                {AvailableFundsByCategory(subsidy, hasQmeCategory, showRemainingAnnual, showRemainingLifetime)}
              </React.Fragment>
            ) : (
              <>
                <React.Fragment key={idx}>
                  {AvailableFundsAmounts(subsidy, showRemainingAnnual, showRemainingLifetime)}
                </React.Fragment>
                <PlanInfoBadgeContainer
                  subsidy={subsidy}
                  allSubsidies={subsidiesToRender}
                  sx={(theme) => ({ paddingBlockStart: theme.tokens.spacing.lg })}
                />
              </>
            )
          )}
        </div>
      </CategoriesContainer>
    </>
  )
}

const AvailableFundsModule = ({ appLockedDown = false }) => {
  const { benefitHighlightMvp } = useFlags()
  const subsidies = useSelector(getSubsidies)
  const hasMultipleCategories = subsidies.length > 1
  const history = useHistory()
  const handleMoreDetails = () => {
    history.push(appLockedDown ? DbgPaths.PAYING_FOR_CARE : "/transactions")
  }

  return (
    <Box
      borderRadius={(theme) => theme.tokens.borderRadius.md}
      padding={(theme) => theme.tokens.spacing.lg}
      sx={(theme) => ({ backgroundColor: theme.palette.success.light })}
      id="home-sidebar-available-benefit-module"
    >
      <Box
        padding={(theme) => theme.tokens.spacing.xs}
        borderRadius={(theme) => theme.tokens.borderRadius.round}
        height="40px"
        width="40px"
        sx={() => ({ backgroundColor: "#c2dbcc" })}
      >
        <RetinaImage name="illo-coins-and-bills" height="24px" width="24px" />
      </Box>
      <Typography
        variant="h4"
        component="h3"
        color={(theme) => theme.palette.text.secondary}
        marginTop={(theme) => theme.tokens.spacing.xs}
        marginBottom={(theme) => theme.tokens.spacing.xs}
      >
        {benefitHighlightMvp ? (
          <FormattedMessage defaultMessage="Available funds" />
        ) : (
          <FormattedMessage defaultMessage="Carrot funds" />
        )}
      </Typography>
      <Typography
        variant="body1"
        color={(theme) => theme.palette.text.secondary}
        paddingBottom={(theme) => theme.tokens.spacing.xs}
      >
        {hasMultipleCategories ? (
          <FormattedMessage defaultMessage="Your employer provides funds to cover eligible expenses in the following categories." />
        ) : (
          <FormattedMessage defaultMessage="Your employer provides funds to cover eligible expenses." />
        )}
      </Typography>
      {subsidies && AvailableFundsModuleCategories(subsidies)}
      <Box paddingBottom={(theme) => theme.tokens.spacing.lg} />

      {benefitHighlightMvp ? (
        <Link
          href={appLockedDown ? DbgPaths.PAYING_FOR_CARE : "/transactions"}
          endIcon={<InternalIcon fontSize="small" color="secondary" />}
        >
          <FormattedMessage defaultMessage="Learn about taxes, reimbursement, and paying for care" />
        </Link>
      ) : (
        <Button variant="outlined" color="secondary" onClick={handleMoreDetails}>
          <span className="nowrap">
            <FormattedMessage defaultMessage="More details" />
          </span>
        </Button>
      )}
    </Box>
  )
}

export default AvailableFundsModule
