import React from "react"
import { FormTextField, FormAutocomplete } from "@carrotfertility/carotene-core-x"
import { useIntl } from "react-intl"
import { UsaStates } from "utils/UsaStateOptions"
import { AboutYou } from "types/carrotPlanTypes"
import { Stack, useTheme } from "@carrotfertility/carotene-core"
import { useIntlSort } from "utils/hooks/useIntlSort"

export type AddressFields = Omit<AboutYou["address"], "internationalCountry" | "internationalAddressLine" | "state"> & {
  state: { label: string; value: string }
}

interface AddressProps {
  defaultValues: Partial<Omit<AboutYou["address"], "internationalCountry" | "internationalAddressLine">>
}

export function formatAddressFieldsToPayload(fields: AddressFields) {
  return {
    address: {
      address1: fields?.address1,
      address2: fields?.address2,
      city: fields?.city,
      state: fields?.state?.value,
      zip: fields?.zip
    }
  }
}

export function isValidAddress(
  address: Omit<AboutYou["address"], "internationalCountry" | "internationalAddressLine">
): boolean {
  return Boolean(address?.address1) && Boolean(address?.city) && Boolean(address?.state) && Boolean(address?.zip)
}

export function Address(props: AddressProps) {
  const intl = useIntl()
  const { localeComparator } = useIntlSort()
  const theme = useTheme()
  const requiredText = intl.formatMessage({ defaultMessage: "Required" })
  const stateOptions: { label: string; value: string }[] = React.useMemo(
    () =>
      UsaStates.map((state) => ({
        label: intl.formatMessage(state.label),
        value: state.id
      })).sort((a, b) => localeComparator(a.label, b.label)),
    [intl, localeComparator]
  )
  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
      <FormTextField
        name={"address1"}
        label={intl.formatMessage({ defaultMessage: "Address Line 1" })}
        registerOptions={{ required: requiredText }}
        inputProps={{ "data-testid": "address1" }}
        defaultValue={props?.defaultValues?.address1}
      />
      <FormTextField
        name={"address2"}
        label={intl.formatMessage({ defaultMessage: "Address Line 2" })}
        inputProps={{ "data-testid": "address2" }}
        defaultValue={props?.defaultValues?.address2}
      />
      <FormTextField
        name={"city"}
        label={intl.formatMessage({ defaultMessage: "City" })}
        inputProps={{ "data-testid": "city" }}
        registerOptions={{ required: requiredText }}
        defaultValue={props?.defaultValues?.city}
      />
      <FormAutocomplete
        name="state"
        fullWidth
        label={intl.formatMessage({ defaultMessage: "State" })}
        data-testid="state"
        options={stateOptions}
        controllerProps={{
          defaultValue: stateOptions.find(({ value }) => value === props?.defaultValues?.state),
          rules: { required: requiredText }
        }}
      />
      <FormTextField
        name={"zip"}
        label={intl.formatMessage({ defaultMessage: "Zip Code" })}
        inputProps={{ "data-testid": "zip" }}
        registerOptions={{
          required: requiredText,
          validate: (value) => {
            if (!value) {
              return requiredText
            }
            if (!value.match(/^\d{5}$/)) {
              return intl.formatMessage({ defaultMessage: "Zip code must be 5 digits long" })
            }
            return true
          }
        }}
        defaultValue={props?.defaultValues?.zip}
      />
    </Stack>
  )
}
