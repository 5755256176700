import React from "react"
import { CircleImage } from "../../../views/atoms/images/CircleImage"
import { FormattedMessage } from "react-intl"
import { Link, Typography } from "@carrotfertility/carotene-core"
import { RequirementCard } from "./requirement-card"

const CarrotRxMedicationCard = (): JSX.Element => (
  <RequirementCard
    icon={<CircleImage iconName="icn-carrot-rx" backgroundColor={"black-10"} />}
    title={<FormattedMessage defaultMessage="Carrot Rx required" />}
    body={[
      <Typography key="medications-carrot-rx" variant="body2">
        <FormattedMessage
          defaultMessage="All medications must be filled through <link>Carrot Rx</link> to be eligible."
          values={{
            link: (linkContent) => (
              <Link target="_blank" href={"/your-benefit-guide/pharmacy"} color="inherit">
                {linkContent}
              </Link>
            )
          }}
        />
      </Typography>,
      <Typography key="if-you-qualify" variant="body2">
        <FormattedMessage
          defaultMessage="If you qualify for an <link>exception</link>, please specify which one in the comment box on the last screen before you submit your expense."
          values={{
            link: (linkContent) => (
              <Link target="_blank" href={"/your-benefit-guide/pharmacy"} color="inherit">
                {linkContent}
              </Link>
            )
          }}
        />
      </Typography>
    ]}
  />
)

export default CarrotRxMedicationCard
