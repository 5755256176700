import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Box, DialogContentText, DialogTitle, UnorderedList, UnorderedListItem } from "@carrotfertility/carotene-core"
import { Form, FormCheckbox, FormOnSubmitHandler } from "@carrotfertility/carotene-core-x"
import { FormattedMessage, useIntl } from "react-intl"

import {
  getIsAvenuesMember,
  getIsNBAMember,
  getIsRomanHealthNYMandateMember,
  getIsWNBAMember
} from "redux/derivedSelectors"
import { useProgressTracker } from "components/views/modal/ProgressTracker"

import RequestCardWrapper from "pages/request-carrot-card-flow/requestCardWrapper"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import { InformationText, informationText } from "./RequestCardInformationText"

const RequestCardInformation = () => {
  const isAvenuesMember = useSelector(getIsAvenuesMember)
  const isNBAMember = useSelector(getIsNBAMember)
  const isRomanHealthNYMandateMember = useSelector(getIsRomanHealthNYMandateMember)
  const isWNBAMember = useSelector(getIsWNBAMember)

  const intl = useIntl()
  const { send } = useStateMachine(null)
  const { hideProgress } = useProgressTracker()

  useEffect(() => {
    hideProgress()
  }, [hideProgress])

  // @ts-expect-error TS7006
  const processSubText = (displayText) => {
    const { subText = "", bulletPoints = [] } = displayText

    let formattedText

    if (!bulletPoints.length && !Array.isArray(subText)) {
      formattedText = (
        <DialogContentText>
          <FormattedMessage {...subText} values={{ br: <br /> }} />
        </DialogContentText>
      )
    } else {
      const list = (
        <UnorderedList disablePadding={true}>
          {/*
           // @ts-expect-error TS7006 */}
          {bulletPoints.map((point, i) => (
            <UnorderedListItem key={`bullet-point-${i}`}>
              <DialogContentText>
                <FormattedMessage {...point} />
              </DialogContentText>
            </UnorderedListItem>
          ))}
        </UnorderedList>
      )

      formattedText = (
        <>
          <DialogContentText>
            <FormattedMessage {...subText[0]} />
          </DialogContentText>
          <DialogContentText>
            <FormattedMessage {...subText[1]} />
          </DialogContentText>
          {list}
          <DialogContentText>
            <FormattedMessage {...subText[2]} />
          </DialogContentText>
        </>
      )
    }

    return formattedText
  }

  let displayInfoText: InformationText[string] = { subText: {}, checkboxText: {} }

  if (isNBAMember) {
    displayInfoText = informationText.NBA
  } else if (isWNBAMember) {
    displayInfoText = informationText.WNBA
  } else if (isAvenuesMember) {
    displayInfoText = informationText.Avenues
  } else if (isRomanHealthNYMandateMember) {
    displayInfoText = informationText.RomanHealth
  }

  const onSubmission: FormOnSubmitHandler<{
    requestCardInformationCheckbox: boolean
  }> = () => {
    send("")
  }

  return (
    <Form onSubmit={onSubmission}>
      <RequestCardWrapper showCancelButton showContinueButton disableContinue={"requestCardInformationCheckbox"}>
        <DialogTitle>
          <FormattedMessage defaultMessage="What to know before you use your Carrot Card" />
        </DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginBlockEnd: (theme) => theme.tokens.spacing.xl,
            gap: (theme) => theme.spacing(theme.tokens.spacing.md)
          }}
        >
          {processSubText(displayInfoText)}
        </Box>
        <FormCheckbox
          name={"requestCardInformationCheckbox"}
          label={intl.formatMessage(displayInfoText.checkboxText)}
          required
        />
      </RequestCardWrapper>
    </Form>
  )
}

export { RequestCardInformation }
