import React from "react"
import { MessageReceivedModalBaseProps } from "components/talk-to-carrot/send-message/SendMessage"
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  BoxProps,
  Typography,
  DialogTitle
} from "@carrotfertility/carotene-core"
import { FormattedMessage, MessageDescriptor, defineMessage, useIntl } from "react-intl"
import { PiiText } from "services/tracking"

interface MessageReceivedModalProps extends MessageReceivedModalBaseProps {
  personalEmail?: string
  employeeEmail?: string
  partnerEmail?: string
  sentFromPartnerEmail?: boolean
}

function SentMessageImage(props: BoxProps<"img">): JSX.Element {
  return (
    <Box
      src={`/images/illo-paper-airplane-blue-circle.png`}
      srcSet={`/images/illo-paper-airplane-blue-circle-2x.png`}
      alt=""
      component="img"
      {...props}
    />
  )
}

const emailText: Record<string, MessageDescriptor> = {
  personal: defineMessage({ defaultMessage: "We'll get back to you at <bold>{email}</bold>, your preferred email." }),
  employee: defineMessage({ defaultMessage: "We'll get back to you at <bold>{email}</bold>, your account email." }),
  partner: defineMessage({ defaultMessage: "We'll get back to you at <bold>{email}</bold>, your partner's email." })
}

const MessageReceivedModal = ({
  onExit,
  personalEmail,
  employeeEmail,
  partnerEmail,
  sentFromPartnerEmail
}: MessageReceivedModalProps): JSX.Element => {
  const intl = useIntl()
  const title = intl.formatMessage({ defaultMessage: "Message received!" })

  function onClose(): void {
    onExit()
    window.location.href = "/"
  }

  return (
    <Dialog
      title={title}
      open
      aria-labelledby="message-received-modal-title"
      aria-describedby="message-received-modal-description"
      disableEscapeKeyDown
    >
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="row"
        paddingY={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      >
        <SentMessageImage height="10rem" width="10rem" />
      </Box>
      <DialogTitle id="message-received-modal-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="message-received-modal-description">
          {sentFromPartnerEmail
            ? intl.formatMessage(emailText.partner, {
                email: <PiiText>{partnerEmail}</PiiText>,
                bold: (boldContent) => (
                  <Typography component="span" fontWeight="bold">
                    {boldContent}
                  </Typography>
                )
              })
            : personalEmail
            ? intl.formatMessage(emailText.personal, {
                email: <PiiText>{personalEmail}</PiiText>,
                bold: (boldContent) => (
                  <Typography component="span" fontWeight="bold">
                    {boldContent}
                  </Typography>
                )
              })
            : intl.formatMessage(emailText.employee, {
                email: <PiiText>{employeeEmail}</PiiText>,
                bold: (boldContent) => (
                  <Typography component="span" fontWeight="bold">
                    {boldContent}
                  </Typography>
                )
              })}
        </DialogContentText>
      </DialogContent>
      <Box display="flex" justifyContent="flex-end" marginTop={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
        <Button onClick={onClose} color="primary" variant="contained">
          <FormattedMessage defaultMessage="Close" />
        </Button>
      </Box>
    </Dialog>
  )
}

export default MessageReceivedModal
