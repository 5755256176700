import { CircleImage } from "../../../views/atoms/images/CircleImage"
import React from "react"
import { FormattedMessage } from "react-intl"
import { Typography } from "@carrotfertility/carotene-core"
import { RequirementCard } from "./requirement-card"

export const IVFCard = (): JSX.Element => {
  return (
    <RequirementCard
      icon={<CircleImage iconName="icn-documents" backgroundColor={"black-10"} />}
      title={<FormattedMessage defaultMessage="Using Carrot funds for IVF" />}
      body={[
        <Typography key="healthy-outcomes" variant="body2">
          <FormattedMessage defaultMessage="To support healthy outcomes, members are required to complete two steps before they can use Carrot funds for IVF-related expenses." />
        </Typography>,
        <Typography key="once-done" variant="body2">
          <FormattedMessage defaultMessage="Once you're done submitting this expense, we'll show you how to complete those." />
        </Typography>
      ]}
    />
  )
}
