import React from "react"
import { Spacer } from "components/views/atoms/Atoms"
import { Container, FlexContainer } from "@carrotfertility/carotene"
import { Link, Typography } from "@carrotfertility/carotene-core"
import { useSelector } from "react-redux"
import { getShouldFillOutBankInfo } from "redux/derivedSelectors"
import { useStateMachine } from "../../../context/stateMachine/StateMachineContext"
import { IlloCoinsAndBills } from "../../../views/atoms/Illustrations"
import { Steps } from "../../workflow/steps"
import { useEnrollmentFlowModal } from "components/cmd-enrollment/EnrollmentModal"
import { FormattedMessage } from "react-intl"
import { BankInfoOptionButtons } from "components/cmd-enrollment/shared/BankInfoOptionButtons"
import { useBenefitEnrollment } from "components/context/enrollment/BenefitEnrollmentContext"

export function FinalStep(): JSX.Element {
  const { onExit } = useEnrollmentFlowModal()
  useStateMachine(Steps.FINAL, null)
  const needsBankInfo = useSelector(getShouldFillOutBankInfo)
  const { deductibleFlowType } = useBenefitEnrollment()

  const isEMD = deductibleFlowType === "EXTERNAL"

  return (
    <div>
      <Spacer height={2} />
      <FlexContainer justifyContent="center" padding="none" stack="large">
        <IlloCoinsAndBills />
      </FlexContainer>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.md}
      >
        <FormattedMessage defaultMessage="You're all set." />
      </Typography>
      <Container padding="none" stack="medium">
        {!needsBankInfo && (
          <Typography color={(theme) => theme.palette.text.secondary}>
            <FormattedMessage defaultMessage="We have all the information we need for now." />
          </Typography>
        )}
      </Container>
      <Container padding="none" stack="medium">
        <Typography color={(theme) => theme.palette.text.secondary}>
          {isEMD ? (
            <FormattedMessage
              defaultMessage="To check the status of any deductibles, check your health insurance portal. You can read more about deductibles in your <dBGLink>benefit guide</dBGLink>"
              values={{
                dBGLink: (linkContent) => (
                  <Link color={"inherit"} href="/your-benefit-guide/carrot-funds/deductibles" target="_blank">
                    {linkContent}
                  </Link>
                )
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="To check the status of any deductibles, see the <link>Financial support</link> page. You can read more about deductibles in your <dBGLink>benefit guide</dBGLink>"
              values={{
                link: (linkContent) => (
                  <Link color={"inherit"} href="/transactions" target="_blank">
                    {linkContent}
                  </Link>
                ),
                dBGLink: (linkContent) => (
                  <Link color={"inherit"} href="/your-benefit-guide/carrot-funds/deductibles" target="_blank">
                    {linkContent}
                  </Link>
                )
              }}
            />
          )}
        </Typography>
      </Container>
      <BankInfoOptionButtons {...{ needsBankInfo, onExit }} />
    </div>
  )
}

export function FinalNoDeductibleStep(): JSX.Element {
  const { onExit } = useEnrollmentFlowModal()
  useStateMachine(Steps.FINAL_NO_DEDUCTIBLE, null)
  const needsBankInfo = useSelector(getShouldFillOutBankInfo)

  return (
    <div>
      <Spacer height={2} />
      <FlexContainer justifyContent="center" padding="none" stack="large">
        <IlloCoinsAndBills />
      </FlexContainer>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.md}
      >
        <FormattedMessage defaultMessage="You're all set." />
      </Typography>
      <Container padding="none" stack="medium">
        <Typography color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage defaultMessage="We have all the information we need for now." />
        </Typography>
      </Container>
      <Container padding="none" stack="medium">
        <Typography color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage defaultMessage="It looks like you do not have a deductible associated with your Carrot benefit. That means we can start reimbursing eligible expenses right away." />
        </Typography>
      </Container>
      <BankInfoOptionButtons {...{ needsBankInfo, onExit }} />
    </div>
  )
}
