import React from "react"
import { Box, Tile, Typography } from "@carrotfertility/carotene-core"

interface RequirementCardProps {
  icon: React.ReactNode
  title: React.ReactNode
  body: React.ReactNode | React.ReactNode[]
}

export function RequirementCard(props: RequirementCardProps) {
  return (
    <Tile
      color="default"
      sx={{ display: "flex", flexDirection: "row", gap: (theme) => theme.spacing(theme.tokens.spacing.md) }}
    >
      <Box sx={{ flexShrink: 0, display: "flex" }}>{props.icon}</Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(theme.tokens.spacing.sm) }}>
        <Typography variant="h5">{props.title}</Typography>
        {props.body}
      </Box>
    </Tile>
  )
}
