import { RJSFSchema } from "@rjsf/utils"

export type CarrotJsonSchema = RJSFSchema & {
  fieldName?: string
  shortTitle?: string
  title?: string
  helperMessage?: string
  validationMessage?: string
  placeholder?: boolean
}

export enum SchemaErrorMetadataName {
  fieldName = "fieldName",
  length = "length",
  maxLength = "maxLength",
  minLength = "minLength"
}

export type SchemaErrorMetadata = Record<SchemaErrorMetadataName, string | number>
