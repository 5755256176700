import React from "react"
import { Spacer } from "./Atoms"

const render = (element) => element && (React.isValidElement(element) ? element : React.createElement(element))

export const BodyWithLeftSidebarLayout = ({ contentId = "", sideBarElements, children }) => (
  <div id={contentId} className="flex flex-row-l flex-column flex-auto">
    <div className="w-220px-l">
      {sideBarElements.map((sideBar) => (
        <div key={sideBar.key || ""}>{render(sideBar)}</div>
      ))}
    </div>
    <div className="db-l dn">
      <Spacer width={3} />
    </div>
    <div className="flex flex-auto">{children}</div>
  </div>
)
