import actionTypes from "../actions/actionTypes"
import { combineReducers } from "redux"

const reducers = {}

// @ts-expect-error TS(2339): Property 'persistLoginEmail' does not exist on typ... Remove this comment to see the full error message
reducers.persistLoginEmail = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.PERSIST_EMAIL:
      return { ...state, payload: action.payload }
    default:
      return state
  }
}

const loginInfo = combineReducers(reducers)

export const loginEmail = (state: any) => state.loginInfo.persistLoginEmail?.payload

export default loginInfo
