import React from "react"
import {
  StateMachineContextReturn,
  StateMachineReducerState,
  StateMachineReducerAction,
  UseStateMachineReturn,
  Section,
  Workflow,
  Step,
  TransitionBase,
  EnrichedStep,
  EndingTransition,
  CrossSectionTransition,
  SectionTransition,
  StateMachineProps
} from "./StateMachineTypes"
import { useTracking } from "./useTracking"

const StateMachineContext = React.createContext<StateMachineContextReturn>(null)

function stateMachineReducer(
  state: StateMachineReducerState,
  action: StateMachineReducerAction
): StateMachineReducerState {
  const { past, present, wentBack } = state
  const { type, newPresent, previousStep, newStartTrackingPageViews = wentBack } = action
  switch (type) {
    case "START_TRACKING": {
      return {
        past: past,
        present: present,
        wentBack: wentBack,
        startTrackingPageViews: true
      }
    }
    case "BACK": {
      if (past.length === 0) return state

      let newPresent = past[past.length - 1]
      let newPast = past.slice(0, past.length - 1)

      while (newPast.length > 0 && newPresent.decisionStep) {
        newPresent = newPast[newPast.length - 1]
        newPast = newPast.slice(0, newPast.length - 1)
      }

      if (newPresent.decisionStep) return { wentBack: true, ...state }

      return {
        past: newPast,
        present: newPresent,
        wentBack: true,
        startTrackingPageViews: true
      }
    }
    case "SET": {
      return {
        wentBack,
        past: [...past, present],
        present: newPresent,
        startTrackingPageViews: newStartTrackingPageViews
      }
    }
    case "REWIND": {
      const prevStepIndex = past.findIndex(({ name }) => name === previousStep)
      if (prevStepIndex < 0) {
        throw new Error(`Could not find previous step: ${previousStep}`)
      }
      const newPresent = past[prevStepIndex]
      const newPast = past.slice(0, prevStepIndex)
      return {
        past: newPast,
        present: newPresent,
        wentBack: true,
        startTrackingPageViews: true
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`)
    }
  }
}

export function StateMachineStepView(): JSX.Element {
  const context = React.useContext(StateMachineContext)
  const CurrentView = context.present.component
  return <CurrentView />
}

export function useStateMachine(defaultGiven?: string | number | null): UseStateMachineReturn {
  const { send, back, backToStep, ...state } = React.useContext(StateMachineContext)

  React.useLayoutEffect(() => {
    if (!state.wentBack && typeof defaultGiven !== "undefined" && defaultGiven !== null) {
      send(defaultGiven, true)
    }
  }, [state.wentBack, send, defaultGiven, state?.present?.name, state?.present?.sectionName])

  return {
    send,
    back,
    backToStep,
    stepName: state?.present?.name,
    canGoBack: Boolean(state.past.length),
    sectionName: state?.present?.sectionName,
    workflowName: state?.present?.workflowName
  }
}

function findSection(stateMachineDescription: Section[], sectionName: string): Section {
  return stateMachineDescription.find(({ name }) => name === sectionName)
}

function findWorkflowInSection(section: Section, workflowName: string): Workflow {
  return section.workflows.find(({ name }) => name === workflowName)
}

function findStepInSection(section: Section, stepName: string): Step {
  return section.steps.find(({ name }) => name === stepName)
}

function findTransitionInWorkflow(workflowDescription: Workflow, at: string, given: string): TransitionBase | null {
  return workflowDescription.workflow.find(
    (transition: TransitionBase) => transition.at === at && transition.given === given
  )
}

function getNewPresentStep(stateMachineDescription: Section[], presentStep: EnrichedStep, given: string): EnrichedStep {
  const { workflowName, sectionName, name: at } = presentStep
  const section = findSection(stateMachineDescription, sectionName)
  const workflowDescription = findWorkflowInSection(section, workflowName)
  const transition = findTransitionInWorkflow(workflowDescription, at, given)

  if (!transition) {
    throw new Error(
      `State machine transition not found for "${presentStep?.name}" given "${given}" in workflow "${presentStep.workflowName}"`
    )
  }

  if ((transition as EndingTransition).end) {
    // We hit the end of the road
    return null
  }

  if ((transition as CrossSectionTransition).sectionName) {
    const crossSectionTransition = transition as CrossSectionTransition
    // Transitioning to another section
    const newSection = findSection(stateMachineDescription, crossSectionTransition.sectionName)
    const step = findStepInSection(newSection, crossSectionTransition.goto)
    return {
      workflowName: newSection.desiredWorkflow,
      sectionName: crossSectionTransition.sectionName,
      ...step
    }
  }

  const step = findStepInSection(section, (transition as SectionTransition).goto)
  return {
    workflowName,
    sectionName,
    ...step
  }
}

export function StateMachineProvider({
  onComplete,
  skipToUnanswered = true,
  stateMachineDescription,
  children,
  initialSection
}: React.PropsWithChildren<StateMachineProps>): JSX.Element {
  const startingSection = findSection(stateMachineDescription, initialSection)
  if (!startingSection) {
    throw Error(`Could not find initial section in state machine: [${initialSection}]`)
  }

  const startingStep = findStepInSection(startingSection, startingSection.initialStep)
  if (!startingStep) {
    throw Error(`Could not find starting step [${startingStep}] in initial section [${initialSection}]`)
  }

  const [state, dispatch] = React.useReducer(stateMachineReducer, {
    past: [],
    present: { ...startingStep, sectionName: startingSection.name, workflowName: startingSection.desiredWorkflow },
    wentBack: !skipToUnanswered,
    startTrackingPageViews: false
  })

  const { trackCurrentPageOnFirstTransition, trackStepTransition } = useTracking({ state, dispatch })

  const send = React.useCallback(
    (given: string, skippingToUnanswered = false) => {
      const newPresent = getNewPresentStep(stateMachineDescription, state.present, given)

      if (newPresent) {
        const payload = { type: "SET", newPresent } as StateMachineReducerAction
        if (!skippingToUnanswered) {
          trackStepTransition(given)
          if (!state?.present?.decisionStep) {
            trackCurrentPageOnFirstTransition()
            payload.newStartTrackingPageViews = true
          }
        }
        dispatch(payload)
      } else {
        onComplete()
      }
    },
    [onComplete, state.present, stateMachineDescription, trackCurrentPageOnFirstTransition, trackStepTransition]
  )

  const backToStep = React.useCallback(
    (previousStep: string) => {
      trackCurrentPageOnFirstTransition()
      dispatch({ type: "REWIND", previousStep })
    },
    [trackCurrentPageOnFirstTransition]
  )

  const back = React.useCallback(() => {
    trackCurrentPageOnFirstTransition()
    trackStepTransition("go back")
    dispatch({ type: "BACK" })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [trackCurrentPageOnFirstTransition])

  return (
    <StateMachineContext.Provider value={{ back, send, backToStep, ...state }}>
      {children ? children : <StateMachineStepView />}
    </StateMachineContext.Provider>
  )
}
