import React from "react"
import styled from "styled-components"
import { VectorMap } from "@south-paw/react-vector-maps"
import { useQuery } from "@tanstack/react-query"
import { SlidingDotsLoader } from "@carrotfertility/carotene"

const MapDiv = styled.div`
  background: #f7f9fa;
  border-radius: 8px;
  margin-block-end: 10px;
  padding: 10px;

  svg {
    stroke: #ffffff;
    stroke-width: 1px;
    cursor: pointer;

    path {
      fill: #d3dfe3;

      &[aria-checked="true"] {
        fill: #e48400;
        stroke: #e48400;
        stroke-width: 1px;
      }

      &[aria-current="true"] {
        fill: #fae6cc;
        stroke: #fae6cc;
        stroke-width: 1px;
      }

      &:focus {
        outline: none;
      }
    }
  }
`

type WorldVectorMapProps = {
  selectedLocationId: string
  hoveredLocationId: string
  // @ts-expect-error TS7006
  onMouseEnter?: (layer) => void
  // @ts-expect-error TS7006
  onClick?: (layer) => void
  // @ts-expect-error TS7006
  onMouseLeave?: (layer) => void
}

const WorldVectorMap = ({
  selectedLocationId,
  hoveredLocationId,
  onMouseEnter = null,
  onClick = null,
  onMouseLeave = null
}: WorldVectorMapProps): JSX.Element => {
  const worldMapDataQuery = useQuery(
    ["worldData"],
    async () => import(/* webpackChunkName: "worldVectorMapData" */ "./WorldVectorMap.json")
  )

  return (
    <MapDiv>
      {worldMapDataQuery.isLoading ? (
        <SlidingDotsLoader />
      ) : (
        <VectorMap
          tabIndex={-1}
          {...worldMapDataQuery.data}
          checkedLayers={[selectedLocationId]}
          currentLayers={[hoveredLocationId]}
          layerProps={{ onMouseEnter, onMouseLeave, onClick }}
        />
      )}
    </MapDiv>
  )
}

export default WorldVectorMap
