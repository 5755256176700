import React from "react"
import { Error } from "./Error"
import { useCurrentUser } from "../context/user/UserContext"
import { getBenefitConfigurationLoadFailed } from "redux/reducers/benefitConfiguration"
import { useSelector } from "react-redux"

export const Loading = () => {
  const { userInfoLoadError } = useCurrentUser()
  const benefitLoadError = useSelector(getBenefitConfigurationLoadFailed)
  const hasError = userInfoLoadError || benefitLoadError
  return hasError ? <Error /> : <LoadingInner />
}

export const LoadingInner = () => {
  return (
    <div className="loading-wrapper bg-pattens-blue">
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}
