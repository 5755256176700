/* eslint-disable unused-imports/no-unused-vars -- we're not confident that the mobile bridge doesn't depend on these function signatures */

import { MobileBridgeClient } from "./MobileBridgeClient"

export class NullMobileBridgeClient implements MobileBridgeClient {
  helpCenter = (token: any, zdType: any, id: any) => {
    throw new Error("Not a mobile device")
  }

  isBiometricsCapable = async () => false

  isBiometricLoginAlreadyConfigured = async () => false

  disableBiometrics = async () => {
    throw new Error("Not a mobile device")
  }

  attemptBiometricsCredentialsRetrieval = async (login: any) => {
    throw new Error("Not a mobile device")
  }

  showBiometricsEnrollment = (
    username: any,
    password: any,
    biometricEnrollmentSuccess: any,
    biometricEnrollmentFailure: any
  ) => {
    throw new Error("Not a mobile device")
  }

  getAppVersion = async () => {
    throw new Error("Not a mobile device")
  }

  isPushPermissionsEnabled = async (): Promise<null> => null

  promptPushPermissions = async () => {
    throw new Error("Not a mobile device")
  }

  setUserInfo = (email: any, employeeId: any) => {
    throw new Error("Not a mobile device")
  }
}

/* eslint-enable unused-imports/no-unused-vars */
