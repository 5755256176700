import { reportErrorMessage } from "utils/ErrorReporting"
import { SupportCenterTypes } from "../../../utils/SupportCenterLinks"
import { determineDevicePlatform } from "services/tracking"

type MobileLoginControls = {
  // API for wiring up the mobile app bridge
  _login: (username: string, password: string) => Promise<unknown>
  _biometricEnrollmentSuccess: () => void
  _biometricEnrollmentFailure: () => void
  _isBiometricLoginAlreadyConfigured: boolean
  _isBiometricsCapable: boolean
  _bioAuthenticatedUsername: string | null
  _bioAuthenticatedPassword: string | null

  // API that the mobile apps use
  isBiometricsCapable: (isCapable: boolean) => void
  isBiometricLoginAlreadyConfigured: (isAlreadyConfigured: boolean) => void
  showEnrollmentSuccess: () => void
  showEnrollmentFailure: () => void
  biometricLoginFailure: () => void
  biometricLogin: (username: string, password: string) => void
}

export const mobileLoginControls = globalThis as unknown as MobileLoginControls

export function updateMobileLoginControls(key: keyof MobileLoginControls, val: unknown) {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  globalThis[key] = val
}

// Register some callbacks that the native implementations will dynamically call back with the response
// It's ugly but we don't have another choice at this time.
const initLoginGlobalCallbacks = () => {
  updateMobileLoginControls("isBiometricsCapable", (isCapable: boolean) => {
    updateMobileLoginControls("_isBiometricsCapable", isCapable)
  })

  updateMobileLoginControls("isBiometricLoginAlreadyConfigured", (isAlreadyConfigured: boolean) => {
    updateMobileLoginControls("_isBiometricLoginAlreadyConfigured", isAlreadyConfigured)
  })

  updateMobileLoginControls("showEnrollmentSuccess", () => {
    mobileLoginControls._biometricEnrollmentSuccess()
  })

  updateMobileLoginControls("showEnrollmentFailure", () => {
    mobileLoginControls._biometricEnrollmentFailure()
  })

  updateMobileLoginControls("biometricLoginFailure", () => {
    // Not currently used
  })

  updateMobileLoginControls("biometricLogin", (username: string, password: string) => {
    updateMobileLoginControls("_bioAuthenticatedUsername", username)
    updateMobileLoginControls("_bioAuthenticatedPassword", password)
    if (mobileLoginControls._login) {
      mobileLoginControls._login(username, password)
    } else {
      const device = determineDevicePlatform()
      reportErrorMessage("Mobile biometric login attempt before login function set", {
        fingerprint: [device, "biometric-login", "login-function-undefined"]
      })
    }
  })
}

type CarrotBridgePayload = {
  action: "showZendesk"
  data: {
    jwt: string
    sectionId?: number
    articleId?: number
  }
}

const getCarrotBridgePayload = (token: any, type: any, id: any) => {
  const payload: CarrotBridgePayload = {
    action: "showZendesk",
    data: {
      jwt: `${token}`
    }
  }
  if (type === SupportCenterTypes.SECTIONS && id) {
    payload.data.sectionId = Number(id)
  }

  if (type === SupportCenterTypes.ARTICLES && id) {
    payload.data.articleId = Number(id)
  }
  return payload
}

export { getCarrotBridgePayload, initLoginGlobalCallbacks }
