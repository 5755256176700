import React from "react"
import { useSelector } from "react-redux"
import { getInsuranceSyncInfo, getReimbursementDetails } from "redux/reducers/reimbursementDetails"
import { COMPANY_DEDUCTIBLE_STATUS, CMD_CONFIGURATION, useUserDeductible } from "../user/DeductibleContext"
import { useCurrentUser } from "../user/UserContext"
import { useBenefitEnrollmentStatus } from "./hooks/useBenefitEnrollmentStatus"
import { TradingPartner } from "../../cmd-enrollment/steps/insurance/ConnectHealthPlanStep/types"
import { BenefitAvailabilityStatus, BenefitEnrollmentStatus, HraEligibility } from "types/benefitEnrollmentStatus"

type BenefitEnrollmentContextProps = {
  availableTradingPartners: TradingPartner[]
  status: BenefitEnrollmentStatus
  companyHasCMDEnabled: boolean
  companyIsExternalOnly: boolean
  companyHasHDHP: boolean
  shouldShowDeductibleStatus: boolean
  shouldSkipPartnerCoverageDate: boolean
  shouldSkipMemberCoverageDate: boolean
  shouldCollectPartnersCoverageDates: boolean
  deductibleFlowType: string
  shouldSkipPeekEnrollment: boolean
  memberHasInsurance: boolean
  companyHasExternalDeductible: boolean
  partnerHasInsurance: boolean
  needsBenefitEnrollment: boolean
  availabilityStatus: BenefitAvailabilityStatus
  hraEligibility: HraEligibility
  shouldDisplayInsuranceDetails: boolean
  knownTradingPartnerOutages: string[]
  companySupportsOOPM: boolean
}

const BenefitEnrollmentContext = React.createContext<BenefitEnrollmentContextProps>(null)

const getBenefitAvailabilityStatus = (benefitEnrollmentStatus: BenefitEnrollmentStatus): BenefitAvailabilityStatus => {
  if (benefitEnrollmentStatus.hasEnrollmentAndNeedsBenefitEnrollment)
    return BenefitAvailabilityStatus.ENROLLMENT_PLAN_EXPIRED
  else if (benefitEnrollmentStatus.hasExpensesAndNeedsBenefitEnrollment)
    return BenefitAvailabilityStatus.COMPANY_ADDED_DEDUCTIBLE
  else if (!benefitEnrollmentStatus.needsBenefitEnrollment) return BenefitAvailabilityStatus.DOES_NOT_NEED_ENROLLMENT
  else return BenefitAvailabilityStatus.NEEDS_BENEFIT_ENROLLMENT
}

export const useBenefitEnrollment = () => React.useContext(BenefitEnrollmentContext)

export function BenefitEnrollmentProvider({
  children
}: {
  children: React.ReactNode | React.ReactNode[]
}): JSX.Element {
  const { data: benefitEnrollmentStatus } = useBenefitEnrollmentStatus()
  const insuranceSyncInfo = useSelector(getInsuranceSyncInfo)
  const knownTradingPartnerOutages = useSelector(getReimbursementDetails)?.knownTradingPartnerOutages || []
  const companySupportsOOPM = useSelector(getReimbursementDetails)?.companySupportsOOPM
  const companyHasHDHP = useSelector(getReimbursementDetails)?.companyHasHDHP
  const { deductible, companyDeductibleStatus, cmdConfiguration, hraEligibility } = useUserDeductible()
  const {
    isUsa,
    memberCoverageStartDate,
    memberCoverageEndDate,
    partnerCoverageStartDate,
    partnerCoverageEndDate,
    memberHasLegalPartner,
    memberPlanSourceSelectValue,
    partnerPlanSourceSelectValue,
    company: { hasACA }
  } = useCurrentUser()

  if (!benefitEnrollmentStatus) {
    return null
  }

  const sourceName = deductible?.sourceName
  const memberHasInsurance = memberPlanSourceSelectValue !== "NOT_INSURED"
  const partnerHasInsurance = memberHasLegalPartner && partnerPlanSourceSelectValue !== "NOT_INSURED"
  const memberAndPartnerOnSamePlan =
    memberPlanSourceSelectValue === "PARTNER" || partnerPlanSourceSelectValue === "MEMBER"
  const shouldCollectPartnersCoverageDates = Boolean(
    memberHasLegalPartner && partnerHasInsurance && !memberAndPartnerOnSamePlan
  )
  const companyHasExternalDeductible = companyDeductibleStatus === COMPANY_DEDUCTIBLE_STATUS.EXTERNAL
  const needsBenefitEnrollment = benefitEnrollmentStatus.needsBenefitEnrollment
  const shouldSkipPeekEnrollment = !needsBenefitEnrollment

  const deductibleFlowType = ((): string => {
    if (!memberHasInsurance) {
      return "NO_DEDUCTIBLE"
    }
    if (companyHasExternalDeductible) {
      return "EXTERNAL"
    }
    if (companyDeductibleStatus === COMPANY_DEDUCTIBLE_STATUS.CARROT_MANAGED_DEDUCTIBLE) {
      return "CMD"
    }
    return "NO_DEDUCTIBLE"
  })()

  const shouldSkipPartnerCoverageDate = Boolean(partnerCoverageStartDate || partnerCoverageEndDate)
  const shouldSkipMemberCoverageDate = Boolean(memberCoverageStartDate || memberCoverageEndDate)

  const availabilityStatus = getBenefitAvailabilityStatus(benefitEnrollmentStatus)

  const shouldDisplayInsuranceDetails =
    availabilityStatus !== BenefitAvailabilityStatus.NEEDS_BENEFIT_ENROLLMENT &&
    availabilityStatus !== BenefitAvailabilityStatus.COMPANY_ADDED_DEDUCTIBLE &&
    isUsa &&
    hasACA

  return (
    <BenefitEnrollmentContext.Provider
      value={{
        availableTradingPartners: insuranceSyncInfo.availableTradingPartners,
        companyHasCMDEnabled: sourceName === "CarrotSpecificDeductibleSource",
        companyIsExternalOnly: sourceName === "ExternallyManagedDeductibleSource",
        companyHasHDHP: companyHasHDHP,
        shouldShowDeductibleStatus: cmdConfiguration !== CMD_CONFIGURATION.NEITHER,
        status: benefitEnrollmentStatus,
        shouldSkipPartnerCoverageDate,
        shouldSkipMemberCoverageDate,
        shouldCollectPartnersCoverageDates,
        deductibleFlowType,
        shouldSkipPeekEnrollment,
        memberHasInsurance,
        companyHasExternalDeductible,
        partnerHasInsurance,
        needsBenefitEnrollment,
        availabilityStatus,
        hraEligibility,
        shouldDisplayInsuranceDetails,
        knownTradingPartnerOutages: knownTradingPartnerOutages,
        companySupportsOOPM: companySupportsOOPM
      }}
    >
      {children}
    </BenefitEnrollmentContext.Provider>
  )
}
