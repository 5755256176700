import React, { useContext, useState } from "react"
import { useFormErrorMessage } from "../errors"
import { CarrotJsonSchema } from "../types"
import { TextField } from "@carrotfertility/carotene-core"
import { MessageOverrideContext } from "../form"
import { WidgetProps } from "@rjsf/utils"

export function BaseInputTemplate(props: WidgetProps) {
  const { usePlaceholders, overrideTitle } = useContext(MessageOverrideContext)
  const { label, value: formValue, rawErrors, disabled, options, required, onChange, schema, id, name } = props
  const [edited, setEdited] = useState(false)

  const carrotSchema = schema as CarrotJsonSchema

  const showPlaceholder = !edited && carrotSchema?.placeholder === true && usePlaceholders

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target
    setEdited(true)
    return onChange(value === "" ? options.emptyValue : value)
  }

  const helperMessage = carrotSchema?.helperMessage
  const hasError = rawErrors?.length > 0
  const errorMessage = useFormErrorMessage({ schema: carrotSchema, hasError })
  const placeholder = showPlaceholder ? formValue || "" : ""
  const value = showPlaceholder ? "" : formValue || ""
  return (
    <TextField
      id={id}
      name={name}
      label={overrideTitle ? overrideTitle(label) : label}
      onChange={handleOnChange}
      required={required}
      error={hasError}
      helperText={errorMessage || helperMessage}
      value={value}
      disabled={disabled}
      placeholder={placeholder}
    />
  )
}
