import { UserConditionSubset } from "lib/carrot-api/types/UserInfo"
import useGetUserInfoConditionsSubset, {
  useCreateOverride
} from "services/user/hooks/useGetCurrentUserConditionsSubset"
import { Journey } from "../../../../lib/contentful/utils/journeyOrderMapping"

export const useUserInfoMapper = () => {
  const { data } = useGetUserInfoConditionsSubset()

  const toggleBenefitData = useCreateOverride()

  const deepClone = (oldUserInfo: UserConditionSubset): UserConditionSubset => JSON.parse(JSON.stringify(oldUserInfo))

  const mapper = {
    carrot_card: {
      value: data?.isCarrotCardEligible,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.isCarrotCardEligible = !data.isCarrotCardEligible
        toggleBenefitData(overrideData)
      }
    },
    cmd_non_hdhp: {
      value: data?.company.hasCmdNonHDHP,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.company.hasCmdNonHDHP = !data.company.hasCmdNonHDHP
        toggleBenefitData(overrideData)
      }
    },
    supports_deductible_plans: {
      value: data?.company.supportsDeductiblePlans,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.company.supportsDeductiblePlans = !data.company.supportsDeductiblePlans
        toggleBenefitData(overrideData)
      }
    },
    lock_down_and_carrot_plan_check: {
      value: !data?.isInAppLockdown,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.isInAppLockdown = !data.isInAppLockdown
        toggleBenefitData(overrideData)
      }
    },
    external_deductible: {
      value: data?.company.hasExternalDeductible,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.company.hasExternalDeductible = !data.company.hasExternalDeductible
        toggleBenefitData(overrideData)
      }
    },
    sdoh_has_been_answered: {
      value: data?.hasAnsweredSocialDeterminantsOfHealth,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.hasAnsweredSocialDeterminantsOfHealth = !data.hasAnsweredSocialDeterminantsOfHealth
        toggleBenefitData(overrideData)
      }
    },
    selected_journey_pregnant_or_postpartum: {
      value: data?.memberActiveJourney === Journey.PREGNANT,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.memberActiveJourney = data.memberActiveJourney === Journey.PREGNANT ? "" : Journey.PREGNANT
        toggleBenefitData(overrideData)
      }
    },
    selected_journey_adoption: {
      value: data?.memberActiveJourney === Journey.ADOPTION,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.memberActiveJourney = data.memberActiveJourney === Journey.ADOPTION ? "" : Journey.ADOPTION
        toggleBenefitData(overrideData)
      }
    },
    selected_journey_gestational_surrogacy: {
      value: data?.memberActiveJourney === Journey.GESTATIONAL,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.memberActiveJourney = data.memberActiveJourney === Journey.GESTATIONAL ? "" : Journey.GESTATIONAL
        toggleBenefitData(overrideData)
      }
    },
    selected_journey_fertility_preservation: {
      value: data?.memberActiveJourney === Journey.PRESERVATION,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.memberActiveJourney = data.memberActiveJourney === Journey.PRESERVATION ? "" : Journey.PRESERVATION
        toggleBenefitData(overrideData)
      }
    },
    selected_journey_assisted_reproduction: {
      value: data?.memberActiveJourney === Journey.ASSISTED_REPRODUCTION,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.memberActiveJourney =
          data.memberActiveJourney === Journey.ASSISTED_REPRODUCTION ? "" : Journey.ASSISTED_REPRODUCTION
        toggleBenefitData(overrideData)
      }
    },
    selected_journey_trying_pregnant: {
      value: data?.memberActiveJourney === Journey.TRY_PREGNANT,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.memberActiveJourney = data.memberActiveJourney === Journey.TRY_PREGNANT ? "" : Journey.TRY_PREGNANT
        toggleBenefitData(overrideData)
      }
    },
    selected_journey_exploring_options: {
      value: data?.memberActiveJourney === Journey.EXPLORING,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.memberActiveJourney = data.memberActiveJourney === Journey.EXPLORING ? "" : Journey.EXPLORING
        toggleBenefitData(overrideData)
      }
    },
    selected_journey_parenting: {
      value: data?.memberActiveJourney === Journey.PARENTING,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.memberActiveJourney = data.memberActiveJourney === Journey.PARENTING ? "" : Journey.PARENTING
        toggleBenefitData(overrideData)
      }
    },
    selected_journey_menopause: {
      value: data?.memberActiveJourney === Journey.MENOPAUSE,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.memberActiveJourney = data.memberActiveJourney === Journey.MENOPAUSE ? "" : Journey.MENOPAUSE
        toggleBenefitData(overrideData)
      }
    },
    selected_journey_low_testosterone: {
      value: data?.memberActiveJourney === Journey.LOW_TESTOSTERONE,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.memberActiveJourney =
          data.memberActiveJourney === Journey.LOW_TESTOSTERONE ? "" : Journey.LOW_TESTOSTERONE
        toggleBenefitData(overrideData)
      }
    },
    selected_journey_gender_affirming_care: {
      value: data?.memberActiveJourney === Journey.GENDER_AFFIRMING_CARE,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.memberActiveJourney =
          data.memberActiveJourney === Journey.GENDER_AFFIRMING_CARE ? "" : Journey.GENDER_AFFIRMING_CARE
        toggleBenefitData(overrideData)
      }
    },
    selected_journey_something_else: {
      value: data?.memberActiveJourney === Journey.SOMETHING_ELSE,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.memberActiveJourney =
          data.memberActiveJourney === Journey.SOMETHING_ELSE ? "" : Journey.SOMETHING_ELSE
        toggleBenefitData(overrideData)
      }
    }
  }
  return mapper
}
