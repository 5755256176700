import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useCheckRoutingInfoQueryCache } from "components/carrot-plans/shared/useQueryRoutingInfo"
import { useParams } from "react-router-dom"
import { AfabKeys } from "components/carrot-plans/hooks/useMedicalQuestionMenuItem"

const AfabSteps: { [key: string]: string } = {
  ASSISTED_REPRODUCTION: "PAST_FERTILITY_TREATMENT",
  PRESERVATION: "PAST_FERTILITY_TREATMENT",
  PREGNANT: "PREGNANT",
  TRY_PREGNANT: "TRY_PREGNANT",
  MENOPAUSE: "MENOPAUSE_DIAGNOSIS",
  LOW_TESTOSTERONE: "MENOPAUSE_DIAGNOSIS"
}

export default function WhatKindOfJourneyAfabDecisionStep(): JSX.Element {
  const routingData = useCheckRoutingInfoQueryCache()
  const { journeyParam } = useParams<{ journeyParam: string }>()
  const journeyValue = journeyParam ? AfabKeys[journeyParam] : AfabSteps[routingData?.journey]
  const { send } = useStateMachine()

  useEffect(() => {
    send(journeyValue)
  })
  return <></>
}
