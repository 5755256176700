import { useQueryClient, QueryObserver, QueryObserverResult } from "@tanstack/react-query"
import { getChannelIdQueryKey } from "../../components/home/quick-links/hooks/useGetChannelId"
import { addHeapUserProperties, HeapProperties, HeapUserPropertyName } from "../heap"
import { carrotClient } from "../CarrotClient"

const currentHeapPropertyValues: HeapProperties = {
  [HeapUserPropertyName.ChannelId]: null
}

export default function useHeapTrackingReactQuery() {
  const queryClient = useQueryClient()
  const observer = new QueryObserver(queryClient, {
    queryKey: getChannelIdQueryKey(),
    queryFn: () => carrotClient.getChannelId(),
    refetchOnWindowFocus: false
  })

  observer.subscribe((query: QueryObserverResult<number>) => {
    if (query.status === "loading") {
      currentHeapPropertyValues[HeapUserPropertyName.ChannelId] = null
    }
    if (query.status === "success" && currentHeapPropertyValues[HeapUserPropertyName.ChannelId] !== query.data) {
      currentHeapPropertyValues[HeapUserPropertyName.ChannelId] = query.data
      addHeapUserProperties(currentHeapPropertyValues)
    }
  })
}
