import React, { useContext, useState } from "react"
import { useSelector } from "react-redux"
import { Alert, Box, DialogContentText, DialogTitle, Link } from "@carrotfertility/carotene-core"
import { Form, FormOnSubmitHandler, FormSelect, FormTextField } from "@carrotfertility/carotene-core-x"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import { RequestCardContext } from "pages/request-carrot-card-flow"
import RequestCardWrapper from "pages/request-carrot-card-flow/requestCardWrapper"
import { getCountryCode } from "redux/reducers/companyInfo"
import { FormattedMessage, useIntl } from "react-intl"
import { CountryCode, CountryCodes } from "utils/CountryCode"
import { HttpErrors } from "utils/HttpErrors"
import { getCountryCallingCode } from "libphonenumber-js"
import { TtcPaths } from "../../../utils/Paths"
import { carrotClient } from "utils/CarrotClient"

const ConfirmPhoneNumber = () => {
  const intl = useIntl()
  const { completeConfirmPhoneNumber } = useContext(RequestCardContext)

  const { canGoBack, send } = useStateMachine()

  const countryCode = useSelector(getCountryCode)

  const [errors, setErrors] = useState({
    isInvalidPhoneNumber: false,
    isOtherError: false
  })

  const onSubmission: FormOnSubmitHandler<{
    countryCode: CountryCode
    phoneNumber: string
  }> = async (formValues) => {
    const { phoneNumber, countryCode: countryCodeFormValue } = formValues
    const sanitizedPhoneNumber = phoneNumber.startsWith("+") ? phoneNumber.substring(1) : phoneNumber

    const countryCode = countryCodeFormValue ?? CountryCode.United_States

    const phoneCountryCode = getCountryCallingCode(countryCode)
    const phoneNumberWithCountryCode = `${phoneCountryCode}${sanitizedPhoneNumber}`

    try {
      setErrors({
        isOtherError: false,
        isInvalidPhoneNumber: false
      })
      const response = await carrotClient.startPhoneVerification(phoneNumberWithCountryCode, countryCode)
      const standardizedPhoneNumber = await response.json()

      completeConfirmPhoneNumber(standardizedPhoneNumber, countryCode)
      send("")
    } catch (e) {
      if (e.name === HttpErrors.NOT_FOUND) {
        setErrors({ isOtherError: false, isInvalidPhoneNumber: true })
      } else {
        setErrors({
          isOtherError: true,
          isInvalidPhoneNumber: false
        })
      }
    }
  }

  const phoneCodeSelectOptions: { label: string; value: string }[] = CountryCodes.map((countryCode) => ({
    label: `${intl.formatDisplayName(countryCode, { type: "region" })} (+${getCountryCallingCode(countryCode)})`,
    value: countryCode
  }))

  return (
    <Form onSubmit={onSubmission} defaultValues={{ countryCode }}>
      <RequestCardWrapper showBackButton={canGoBack} showContinueButton>
        <DialogTitle>
          <FormattedMessage defaultMessage="Confirm your phone number" />
        </DialogTitle>
        <DialogContentText marginBottom={(theme) => theme.tokens.spacing.xl}>
          <FormattedMessage
            defaultMessage="We'll send text message alerts to this number if there are any issues with your Carrot Card. You can
              opt out at any time."
          />
        </DialogContentText>
        <Box sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(theme.tokens.spacing.lg) }}>
          {errors.isInvalidPhoneNumber && (
            <Alert severity="error">
              <FormattedMessage defaultMessage="Please enter a valid phone number." />
            </Alert>
          )}
          <FormSelect
            name={"countryCode"}
            label={intl.formatMessage({ defaultMessage: "Country Code" })}
            controllerProps={{
              rules: { required: intl.formatMessage({ defaultMessage: "Please enter a phone number" }) }
            }}
            options={phoneCodeSelectOptions}
            required
          />
          <FormTextField
            name={"phoneNumber"}
            type="tel"
            label={intl.formatMessage({ defaultMessage: "Phone Number" })}
            registerOptions={{ required: intl.formatMessage({ defaultMessage: "Required" }) }}
            error={errors.isInvalidPhoneNumber}
            required
          />
          {errors.isOtherError && (
            <Alert severity="error">
              <FormattedMessage
                defaultMessage="An unexpected error occurred. Please <link>contact us</link> for assistance."
                values={{
                  link: (linkContent) => (
                    <Link color="inherit" href={TtcPaths.TTC_CARE_NAV} target={"_blank"}>
                      {linkContent}
                    </Link>
                  )
                }}
              />
            </Alert>
          )}
        </Box>
      </RequestCardWrapper>
    </Form>
  )
}

export { ConfirmPhoneNumber }
