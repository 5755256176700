import WelcomeStep from "./WelcomeStep"
import BiologicalChildrenStep from "./BiologicalChildrenStep"
import MedicalHistoryStep from "./MedicalHistoryStep"
import ConditionsStep from "./ConditionsStep"
import FamilyConditionsStep from "./FamilyConditionsStep"
import CurrentlyTakingStep from "./CurrentlyTakingStep"
import SmokeDrink from "./SmokeDrinkStep"
import ExperiencingSymptomsStep from "./ExperiencingSymptomsStep"
import HeightWeightStep from "./HeightWeightStep"
import WhatKindOfJourneyAfabDecisionStep from "./WhatKindOfJourneyAfabDecisionStep"
import WhatKindOfJourneyAmabDecisionStep from "./WhatKindOfJourneyAmabDecisionStep"
import PastFertilityTreatmentStep from "./PastFertilityTreatmentStep"
import PregnancyMetWithObgynStep from "./PregnancyMetWithObgynStep"
import CyclesAreRegularStep from "./CyclesAreRegularStep"
import CycleDetailsStep from "./CycleDetailsStep"
import PeriodsPainfulStep from "./PeriodsPainfulStep"
import PeriodsPainfulDetailsStep from "./PeriodsPainfulDetailsStep"
import PregnantPreviouslyStep from "./PregnantPreviouslyStep"
import PregnantPreviouslyDetailsStep from "./PregnantPreviouslyDetailsStep"
import PelvicSurgeryDetailsStep from "./PelvicSurgeryDetailsStep"
import { Steps as JourneySteps } from "../journey/step-library"
import LowTestosteroneSymptomsStep from "./LowTestosteroneSymptomsStep"
import MenopauseSymptomsStep from "./MenopauseSymptomsStep"
import MenopauseDiagnosisDecisionStep from "./MenopauseDiagnosisDecisionStep"
import MenopauseDiagnosisStep from "./MenopauseDiagnosisStep"
import MenopauseDiagnosisDetailsStep from "./MenopauseDiagnosisDetailsStep"
import ThanksStep from "./ThanksStep"
import AnythingElseDecisionStep from "./AnythingElseDecisionStep"
import AnythingElseStep from "./AnythingElseStep"

export enum Workflows {
  AMAB_MEDICAL_QUESTIONS = "AMAB",
  AFAB_MEDICAL_QUESTIONS = "AFAB",
  DECLINE_MEDICAL_QUESTIONS = "DECLINE"
}

export enum Steps {
  WELCOME = "Medical Questions Welcome",
  THANKS = "Medical Questions Complete",
  BIOLOGICAL_CHILDREN = "biologicalChildren",
  MEDICAL_HISTORY = "medicalHistory",
  MEDICAL_CONDITIONS = "conditions",
  FAMILY_MEDICAL_CONDITIONS = "familyConditions",
  CURRENTLY_TAKING = "currentlyTaking",
  SMOKE_DRINK = "smokeDrink",
  CURRENTLY_EXPERIENCING = "experiencingSymptoms",
  HEIGHT_WEIGHT = "heightWeight",
  WHAT_KIND_OF_JOURNEY_AFAB_DECISION = "What kind of journey AFAB decision",
  WHAT_KIND_OF_JOURNEY_AMAB_DECISION = "What kind of journey AMAB decision",
  PAST_FERTILITY_TREATMENT = "pregnancyPastFertilityTreatment",
  PREGNANCY_MET_WITH_OBGYN = "pregnancyMetWithObgyn",
  CYCLES_ARE_REGULAR = "cyclesAreRegular",
  CYCLE_DETAILS = "cycleDetails",
  PERIODS_PAINFUL = "periodsPainful",
  PERIODS_PAINFUL_DETAILS = "periodsPainfulDetails",
  PREGNANT_PREVIOUSLY = "pregnantPreviously",
  PREGNANT_PREVIOUSLY_DETAILS = "pregnantPreviouslyDetails",
  PELVIC_SURGERY = "pelvicSurgeryDetails",
  LOW_TESTOSTERONE_SYMPTOMS = "lowTestosteroneSymptoms",
  MENOPAUSE_DIAGNOSIS = "menopauseDiagnosis",
  MENOPAUSE_DIAGNOSIS_DETAILS = "menopauseDiagnosisDetails",
  MENOPAUSE_SYMPTOMS = "menopauseSymptoms",
  MENOPAUSE_DIAGNOSIS_DECISION = "menopauseDiagnosisDecision",
  ANYTHING_ELSE_DECISION = "anythingElseDecision",
  ANYTHING_ELSE = "anythingElse"
}

export const medicalQuestionsSteps = [
  { name: Steps.WELCOME, component: WelcomeStep },
  { name: Steps.BIOLOGICAL_CHILDREN, component: BiologicalChildrenStep },
  { name: Steps.MEDICAL_HISTORY, component: MedicalHistoryStep },
  { name: Steps.MEDICAL_CONDITIONS, component: ConditionsStep },
  { name: Steps.FAMILY_MEDICAL_CONDITIONS, component: FamilyConditionsStep },
  { name: Steps.CURRENTLY_TAKING, component: CurrentlyTakingStep },
  { name: Steps.SMOKE_DRINK, component: SmokeDrink },
  { name: Steps.CURRENTLY_EXPERIENCING, component: ExperiencingSymptomsStep },
  { name: Steps.HEIGHT_WEIGHT, component: HeightWeightStep },
  { name: Steps.WHAT_KIND_OF_JOURNEY_AFAB_DECISION, component: WhatKindOfJourneyAfabDecisionStep, decisionStep: true },
  { name: Steps.WHAT_KIND_OF_JOURNEY_AMAB_DECISION, component: WhatKindOfJourneyAmabDecisionStep, decisionStep: true },
  { name: Steps.PREGNANCY_MET_WITH_OBGYN, component: PregnancyMetWithObgynStep },
  { name: Steps.PAST_FERTILITY_TREATMENT, component: PastFertilityTreatmentStep },
  { name: Steps.CYCLES_ARE_REGULAR, component: CyclesAreRegularStep },
  { name: Steps.CYCLE_DETAILS, component: CycleDetailsStep },
  { name: Steps.PERIODS_PAINFUL, component: PeriodsPainfulStep },
  { name: Steps.PERIODS_PAINFUL_DETAILS, component: PeriodsPainfulDetailsStep },
  { name: Steps.PREGNANT_PREVIOUSLY, component: PregnantPreviouslyStep },
  { name: Steps.PREGNANT_PREVIOUSLY_DETAILS, component: PregnantPreviouslyDetailsStep },
  { name: Steps.PELVIC_SURGERY, component: PelvicSurgeryDetailsStep },
  { name: Steps.LOW_TESTOSTERONE_SYMPTOMS, component: LowTestosteroneSymptomsStep },
  { name: Steps.MENOPAUSE_DIAGNOSIS, component: MenopauseDiagnosisStep },
  { name: Steps.MENOPAUSE_DIAGNOSIS_DETAILS, component: MenopauseDiagnosisDetailsStep },
  { name: Steps.MENOPAUSE_SYMPTOMS, component: MenopauseSymptomsStep },
  { name: Steps.MENOPAUSE_DIAGNOSIS_DECISION, component: MenopauseDiagnosisDecisionStep, decisionStep: true },
  { name: Steps.ANYTHING_ELSE_DECISION, component: AnythingElseDecisionStep, decisionStep: true },
  { name: Steps.ANYTHING_ELSE, component: AnythingElseStep },
  { name: Steps.THANKS, component: ThanksStep }
]

//prettier-ignore
const afabFlow = [
  { at: Steps.WELCOME,                            given: "",                          goto: Steps.WHAT_KIND_OF_JOURNEY_AFAB_DECISION },
  { at: Steps.WHAT_KIND_OF_JOURNEY_AFAB_DECISION, given: "PREGNANT",                  goto: Steps.PREGNANCY_MET_WITH_OBGYN },
  { at: Steps.WHAT_KIND_OF_JOURNEY_AFAB_DECISION, given: "TRY_PREGNANT",              goto: Steps.CYCLES_ARE_REGULAR },
  { at: Steps.WHAT_KIND_OF_JOURNEY_AFAB_DECISION, given: "PAST_FERTILITY_TREATMENT",  goto: Steps.PAST_FERTILITY_TREATMENT },
  { at: Steps.WHAT_KIND_OF_JOURNEY_AFAB_DECISION, given: "MENOPAUSE_DIAGNOSIS",                 goto: Steps.MENOPAUSE_DIAGNOSIS },
  { at: Steps.MENOPAUSE_DIAGNOSIS,                given: "YES",                       goto: Steps.MENOPAUSE_DIAGNOSIS_DETAILS },
  { at: Steps.MENOPAUSE_DIAGNOSIS,                given: "NO",                        goto: Steps.MENOPAUSE_SYMPTOMS },
  { at: Steps.MENOPAUSE_DIAGNOSIS_DETAILS,        given: "",                          goto: Steps.MENOPAUSE_SYMPTOMS },
  { at: Steps.MENOPAUSE_SYMPTOMS,                 given: "",                          goto: Steps.MENOPAUSE_DIAGNOSIS_DECISION },
  { at: Steps.MENOPAUSE_DIAGNOSIS_DECISION ,      given: "YES",                       goto: Steps.PREGNANT_PREVIOUSLY },
  { at: Steps.MENOPAUSE_DIAGNOSIS_DECISION,       given: "NO",                        goto: Steps.CYCLES_ARE_REGULAR },
  { at: Steps.PREGNANCY_MET_WITH_OBGYN,           given: "",                          goto: Steps.CYCLES_ARE_REGULAR },
  { at: Steps.PAST_FERTILITY_TREATMENT,           given: "",                          goto: Steps.CYCLES_ARE_REGULAR },
  { at: Steps.CYCLES_ARE_REGULAR,                 given: "NO",                        goto: Steps.CYCLE_DETAILS },
  { at: Steps.CYCLES_ARE_REGULAR,                 given: "NOT_SURE",                  goto: Steps.CYCLE_DETAILS },
  { at: Steps.CYCLES_ARE_REGULAR,                 given: "YES",                       goto: Steps.PERIODS_PAINFUL },
  { at: Steps.CYCLES_ARE_REGULAR,                 given: "",                          goto: Steps.PERIODS_PAINFUL },
  { at: Steps.CYCLE_DETAILS,                      given: "",                          goto: Steps.PERIODS_PAINFUL },
  { at: Steps.PERIODS_PAINFUL,                    given: "YES",                       goto: Steps.PERIODS_PAINFUL_DETAILS },
  { at: Steps.PERIODS_PAINFUL,                    given: "NO",                        goto: Steps.PREGNANT_PREVIOUSLY },
  { at: Steps.PERIODS_PAINFUL,                    given: "",                          goto: Steps.PREGNANT_PREVIOUSLY },
  { at: Steps.PERIODS_PAINFUL_DETAILS,            given: "",                          goto: Steps.PREGNANT_PREVIOUSLY },
  { at: Steps.PREGNANT_PREVIOUSLY,                given: "YES",                       goto: Steps.PREGNANT_PREVIOUSLY_DETAILS },
  { at: Steps.PREGNANT_PREVIOUSLY,                given: "NO",                        goto: Steps.MEDICAL_HISTORY },
  { at: Steps.PREGNANT_PREVIOUSLY,                given: "",                          goto: Steps.MEDICAL_HISTORY },
  { at: Steps.PREGNANT_PREVIOUSLY_DETAILS,        given: "",                          goto: Steps.MEDICAL_HISTORY },
  { at: Steps.MEDICAL_HISTORY,                    given: "",                          goto: Steps.MEDICAL_CONDITIONS },
  { at: Steps.MEDICAL_HISTORY,                    given: "PELVIC_ABDOMEN_SURGERY",    goto: Steps.PELVIC_SURGERY },
  { at: Steps.PELVIC_SURGERY,                     given: "",                          goto: Steps.MEDICAL_CONDITIONS },
  { at: Steps.MEDICAL_CONDITIONS,                 given: "",                          goto: Steps.FAMILY_MEDICAL_CONDITIONS },
  { at: Steps.FAMILY_MEDICAL_CONDITIONS,          given: "",                          goto: Steps.CURRENTLY_TAKING },
  { at: Steps.CURRENTLY_TAKING,                   given: "",                          goto: Steps.SMOKE_DRINK },
  { at: Steps.SMOKE_DRINK,                        given: "",                          goto: Steps.CURRENTLY_EXPERIENCING },
  { at: Steps.CURRENTLY_EXPERIENCING,             given: "",                          goto: Steps.HEIGHT_WEIGHT },
  { at: Steps.HEIGHT_WEIGHT,                      given: "",                          goto: Steps.ANYTHING_ELSE_DECISION },
  { at: Steps.ANYTHING_ELSE_DECISION,             given: "JOURNEY_ANYTHING_ELSE",     goto: JourneySteps.ANYTHING_ELSE, sectionName: "Journey" },
  { at: Steps.ANYTHING_ELSE_DECISION,             given: "MEDICAL_ANYTHING_ELSE",     goto: Steps.ANYTHING_ELSE },
  { at: Steps.ANYTHING_ELSE,                      given: "",                          goto: Steps.THANKS}
]

//prettier-ignore
const amabFlow = [
  { at: Steps.WELCOME,                            given: "",                          goto: Steps.WHAT_KIND_OF_JOURNEY_AMAB_DECISION },
  { at: Steps.WHAT_KIND_OF_JOURNEY_AMAB_DECISION, given: "NOT_LOW_T",                 goto: Steps.BIOLOGICAL_CHILDREN },
  { at: Steps.WHAT_KIND_OF_JOURNEY_AMAB_DECISION, given: "LOW_TESTOSTERONE",          goto: Steps.LOW_TESTOSTERONE_SYMPTOMS },
  { at: Steps.LOW_TESTOSTERONE_SYMPTOMS,          given: "",                          goto: Steps.MEDICAL_HISTORY },
  { at: Steps.BIOLOGICAL_CHILDREN,                given: "",                          goto: Steps.MEDICAL_HISTORY },
  { at: Steps.MEDICAL_HISTORY,                    given: "",                          goto: Steps.MEDICAL_CONDITIONS },
  { at: Steps.MEDICAL_CONDITIONS,                 given: "",                          goto: Steps.FAMILY_MEDICAL_CONDITIONS },
  { at: Steps.FAMILY_MEDICAL_CONDITIONS,          given: "",                          goto: Steps.CURRENTLY_TAKING },
  { at: Steps.CURRENTLY_TAKING,                   given: "",                          goto: Steps.SMOKE_DRINK },
  { at: Steps.SMOKE_DRINK,                        given: "",                          goto: Steps.CURRENTLY_EXPERIENCING },
  { at: Steps.CURRENTLY_EXPERIENCING,             given: "",                          goto: Steps.HEIGHT_WEIGHT },
  { at: Steps.HEIGHT_WEIGHT,                      given: "",                          goto: Steps.ANYTHING_ELSE_DECISION },
  { at: Steps.ANYTHING_ELSE_DECISION,             given: "JOURNEY_ANYTHING_ELSE",     goto: JourneySteps.ANYTHING_ELSE, sectionName: "Journey" },
  { at: Steps.ANYTHING_ELSE_DECISION,             given: "MEDICAL_ANYTHING_ELSE",     goto: Steps.ANYTHING_ELSE },
  { at: Steps.ANYTHING_ELSE,                      given: "",                          goto: Steps.THANKS}
]

export const medicalQuestionsWorkflows = [
  { name: Workflows.AMAB_MEDICAL_QUESTIONS, workflow: amabFlow },
  { name: Workflows.AFAB_MEDICAL_QUESTIONS, workflow: afabFlow }
]
