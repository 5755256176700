import { useState, useEffect } from "react"

const useToggle = (initialState?: boolean) => {
  const [boolValue, setBoolValue] = useState(initialState)

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  const toggle = (value = null) => {
    setBoolValue(typeof value === "boolean" ? value : !boolValue)
  }

  return [boolValue, toggle] as const
}

// eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
const useDidMount = (func: any) => useEffect(func, [])

const useBodyClass = (className: any) => {
  useEffect(() => {
    const body = document.body
    body.classList.add(className)

    return () => {
      body.classList.remove(className)
    }
  }, [className])
}

const useWindowInnerHeight = () => {
  const [windowInnerHeight, setWindowInnerHeight] = useState()

  useDidMount(() => {
    const handleResize = () => {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      setWindowInnerHeight(window.innerHeight)
    }

    handleResize()
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  return [windowInnerHeight]
}

export { useToggle, useDidMount, useBodyClass, useWindowInnerHeight }
