import { Autocomplete, TextField } from "@carrotfertility/carotene-core"
import { WidgetProps } from "@rjsf/utils"
import React, { useContext } from "react"
import { useFormErrorMessage } from "../errors"
import { CarrotJsonSchema } from "../types"
import { MessageOverrideContext } from "../form"

type EnumOption = { value: string | number; label: string | number }
interface OverriddenWidgetProps extends WidgetProps {
  options: WidgetProps["options"] & { enumOptions?: EnumOption[] }
}

export function SelectWidget(props: OverriddenWidgetProps) {
  const { overrideTitle, overrideSelectLabel } = useContext(MessageOverrideContext)
  const carrotSchema = props.schema as CarrotJsonSchema

  const hasError = props.rawErrors?.length > 0
  const errorMessage = useFormErrorMessage({ schema: carrotSchema, hasError })

  const { options: schemaOptions } = props
  const options = React.useMemo(
    () =>
      schemaOptions?.enumOptions?.map((option) => ({
        value: option.value,
        label: overrideSelectLabel ? overrideSelectLabel(carrotSchema.fieldName, option.value) : option.label
      })),
    [carrotSchema.fieldName, overrideSelectLabel, schemaOptions?.enumOptions]
  )
  const selectedValue = React.useMemo(
    () => options.find((option) => option.value === props.value),
    [options, props.value]
  )

  return (
    <Autocomplete
      options={options}
      value={selectedValue || null}
      id={props.id}
      disabled={props.disabled}
      onFocus={(event) => props.onFocus(props.id, (event.target as HTMLInputElement).value)}
      onBlur={(event) => props.onBlur(props.id, (event.target as HTMLInputElement).value)}
      onChange={(_, newValue: { value: string | number; label: React.ReactNode | React.ReactNode[] }) =>
        props.onChange(newValue?.value)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          required={props.required}
          label={overrideTitle ? overrideTitle(props.label) : props.label}
          helperText={props.helperMessage || errorMessage}
          placeholder={props.placeholder}
          error={hasError}
        />
      )}
    />
  )
}
