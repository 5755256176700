import { combineReducers } from "redux"
import actionTypes from "redux/actions/actionTypes"
import moment from "moment"
import CardStates from "utils/CardStates"
import { createIsLoadedReducer, createReducer } from "redux/reducers/shared"
import TestUserActionTypes from "redux/actions/testUserActionTypes"

const { FETCH_USER_INFO_SUCCESS, FETCH_BENEFIT_ENROLLMENT_SUCCESS } = actionTypes

const reducers = {}

// @ts-expect-error TS(2339): Property 'loaded' does not exist on type '{}'.
reducers.loaded = createIsLoadedReducer("FETCH_USER_INFO")

// @ts-expect-error TS(2339): Property 'userInfoLoadError' does not exist on typ... Remove this comment to see the full error message
reducers.userInfoLoadError = (state = false, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_FAILURE:
      return action.response
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'firstName' does not exist on type '{}'.
reducers.firstName = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.firstName
    default:
      return state
  }
}
// @ts-expect-error TS(2339): Property 'lastName' does not exist on type '{}'.
reducers.lastName = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.lastName
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'sex' does not exist on type '{}'.
reducers.sex = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_SEX_SUCCESS:
      return action.response.sex
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'genderIdentity' does not exist on type '... Remove this comment to see the full error message
reducers.genderIdentity = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_GENDER_IDENTITY_SUCCESS:
      return action.response.genderIdentity
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'email' does not exist on type '{}'.
reducers.email = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.email
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'personalEmail' does not exist on type '{... Remove this comment to see the full error message
reducers.personalEmail = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.personalEmail
    case actionTypes.UPDATE_PERSONAL_EMAILS_SUCCESS:
      return action.response.personalEmail
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'emailOptIn' does not exist on type '{}'.
reducers.emailOptIn = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.emailOptIn
    case actionTypes.UPDATE_EMAIL_OPT_IN_SUCCESS:
      return action.response.emailOptIn
    default:
      return state
  }
}
// @ts-expect-error TS(2339): Property 'pushNotificationOptIn' does not exist on... Remove this comment to see the full error message
reducers.pushNotificationOptIn = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.pushNotificationOptIn
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'partnerFirstName' does not exist on type... Remove this comment to see the full error message
reducers.partnerFirstName = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_PARTNER_NAME_SUCCESS:
      return action.response.partnerFirstName
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'partnerLastName' does not exist on type ... Remove this comment to see the full error message
reducers.partnerLastName = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_PARTNER_NAME_SUCCESS:
      return action.response.partnerLastName
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'partnerFullName' does not exist on type ... Remove this comment to see the full error message
reducers.partnerFullName = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.partnerFullName
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'partnerDateOfBirth' does not exist on ty... Remove this comment to see the full error message
reducers.partnerDateOfBirth = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_PARTNER_DOB_SUCCESS:
      return action.response.partnerDateOfBirth
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'memberCoverageStartDate' does not exist ... Remove this comment to see the full error message
reducers.memberCoverageStartDate = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_MEMBER_COVERAGE_START_DATE:
    case FETCH_BENEFIT_ENROLLMENT_SUCCESS:
      return action.response.memberCoverageStartDate
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'memberCoverageEndDate' does not exist on... Remove this comment to see the full error message
reducers.memberCoverageEndDate = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_MEMBER_COVERAGE_END_DATE:
    case FETCH_BENEFIT_ENROLLMENT_SUCCESS:
      return action.response.memberCoverageEndDate
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'partnerCoverageStartDate' does not exist... Remove this comment to see the full error message
reducers.partnerCoverageStartDate = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_PARTNER_COVERAGE_START_DATE:
    case FETCH_BENEFIT_ENROLLMENT_SUCCESS:
      return action.response.partnerCoverageStartDate
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'partnerCoverageEndDate' does not exist o... Remove this comment to see the full error message
reducers.partnerCoverageEndDate = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_PARTNER_COVERAGE_END_DATE:
    case FETCH_BENEFIT_ENROLLMENT_SUCCESS:
      return action.response.partnerCoverageEndDate
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'legallyMarried' does not exist on type '... Remove this comment to see the full error message
reducers.legallyMarried = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_LEGALLY_MARRIED_SUCCESS:
    case FETCH_BENEFIT_ENROLLMENT_SUCCESS:
      return action.response.legallyMarried
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'partnerAsDependent' does not exist on ty... Remove this comment to see the full error message
reducers.partnerAsDependent = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_PARTNER_AS_DEPENDENT_SUCCESS:
    case FETCH_BENEFIT_ENROLLMENT_SUCCESS:
      return action.response.partnerAsDependent
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'partnerSex' does not exist on type '{}'.
reducers.partnerSex = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_PARTNER_SEX_SUCCESS:
      return action.response.partnerSex
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'partnerEmail' does not exist on type '{}... Remove this comment to see the full error message
reducers.partnerEmail = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_PERSONAL_EMAILS_SUCCESS:
    case actionTypes.UPDATE_PARTNER_EMAIL_SUCCESS:
      return action.response.partnerEmail
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'partnerGenderIdentity' does not exist on... Remove this comment to see the full error message
reducers.partnerGenderIdentity = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_PARTNER_GENDER_IDENTITY_SUCCESS:
      return action.response.partnerGenderIdentity
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'isInAppLockdown' does not exist on type ... Remove this comment to see the full error message
reducers.isInAppLockdown = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.isInAppLockdown
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'address1' does not exist on type '{}'.
reducers.address1 = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.address1
    case actionTypes.UPDATE_ADDRESS_SUCCESS:
      return action.response.address1
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'address2' does not exist on type '{}'.
reducers.address2 = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.address2
    case actionTypes.UPDATE_ADDRESS_SUCCESS:
      return action.response.address2
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'city' does not exist on type '{}'.
reducers.city = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.city
    case actionTypes.UPDATE_ADDRESS_SUCCESS:
      return action.response.city
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'state' does not exist on type '{}'.
reducers.state = (state = null, action: any) => {
  if (!action.response || !action.response.state) {
    return state
  }
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.state
    case actionTypes.UPDATE_ADDRESS_SUCCESS:
      return action.response.state
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'zip' does not exist on type '{}'.
reducers.zip = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.zip
    case actionTypes.UPDATE_ADDRESS_SUCCESS:
      return action.response.zip
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'internationalCountry' does not exist on ... Remove this comment to see the full error message
reducers.internationalCountry = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.internationalCountry
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'internationalAddressLine' does not exist... Remove this comment to see the full error message
reducers.internationalAddressLine = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.internationalAddressLine
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'nonUsaAddress' does not exist on type '{... Remove this comment to see the full error message
reducers.nonUsaAddress = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.nonUsaAddress
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'isUsa' does not exist on type '{}'.
reducers.isUsa = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.isUsa
    case TestUserActionTypes.TOGGLE_IS_USA:
      return !state
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'eligibilityDate' does not exist on type ... Remove this comment to see the full error message
reducers.eligibilityDate = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      // @ts-expect-error TS(7009): 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
      return action.response.eligibilityDate ? new moment(action.response.eligibilityDate) : null
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'dateOfBirth' does not exist on type '{}'... Remove this comment to see the full error message
reducers.dateOfBirth = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.dateOfBirth
    case actionTypes.UPDATE_DATE_OF_BIRTH_SUCCESS:
      return action.response.dateOfBirth
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'hasCardholderId' does not exist on type ... Remove this comment to see the full error message
reducers.hasCardholderId = (state = false, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.hasCardholderId
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'cardState' does not exist on type '{}'.
reducers.cardState = (state = null, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.cardState
    case actionTypes.ACTIVATE_CARD_SUCCESS:
      return CardStates.ACTIVE
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'isActivateSubmitting' does not exist on ... Remove this comment to see the full error message
reducers.isActivateSubmitting = (state = false, action: any) => {
  switch (action.type) {
    case actionTypes.ACTIVATE_CARD_REQUEST:
      return true
    case actionTypes.ACTIVATE_CARD_FAILURE:
      return false
    case actionTypes.ACTIVATE_CARD_SUCCESS:
      return false
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'isActivateError' does not exist on type ... Remove this comment to see the full error message
reducers.isActivateError = (state = false, action: any) => {
  switch (action.type) {
    case actionTypes.ACTIVATE_CARD_REQUEST:
      return false
    case actionTypes.ACTIVATE_CARD_FAILURE:
      return true
    case actionTypes.ACTIVATE_CARD_SUCCESS:
      return false
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'isCarrotCardEligible' does not exist on ... Remove this comment to see the full error message
reducers.isCarrotCardEligible = (state = false, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.isCarrotCardEligible
    case TestUserActionTypes.TOGGLE_IS_CARROT_CARD_ELIGIBLE:
      return !state
    default:
      return state
  }
}

// @ts-expect-error TS(2339): Property 'employeeId' does not exist on type '{}'.
reducers.employeeId = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.employeeId
})

// @ts-expect-error TS(2339): Property 'carrotUserId' does not exist on type '{}... Remove this comment to see the full error message
reducers.carrotUserId = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.carrotUserId
})

// @ts-expect-error TS(2339): Property 'hasBankInfoFilledOut' does not exist on ... Remove this comment to see the full error message
reducers.hasBankInfoFilledOut = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.hasBankInfoFilledOut
})

// @ts-expect-error TS(2339): Property 'carrotUserCreatedDate' does not exist on... Remove this comment to see the full error message
reducers.carrotUserCreatedDate = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) =>
    // @ts-expect-error TS(7009): 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
    action.response.carrotUserCreatedDate ? new moment(action.response.carrotUserCreatedDate) : null
})

// @ts-expect-error TS(2339): Property 'hasMetSignatureRequirement' does not exi... Remove this comment to see the full error message
reducers.hasMetSignatureRequirement = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.hasMetSignatureRequirement,
  [actionTypes.UPDATE_HAS_MET_SIGNATURE_REQUIREMENT_IN_MEMORY]: (state: any, action: any) =>
    action.response.hasMetSignatureRequirement
})

// @ts-expect-error TS(2339): Property 'hasAnUpcomingOrCompletedExpertChat' does... Remove this comment to see the full error message
reducers.hasAnUpcomingOrCompletedExpertChat = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.hasAnUpcomingOrCompletedExpertChat
})

// @ts-expect-error TS(2339): Property 'phoneNumber' does not exist on type '{}'... Remove this comment to see the full error message
reducers.phoneNumber = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.phoneNumber
})

// @ts-expect-error TS(2339): Property 'planStarted' does not exist on type '{}'... Remove this comment to see the full error message
reducers.planStarted = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.planStarted
})

// @ts-expect-error TS(2339): Property 'deductibleStatus' does not exist on type... Remove this comment to see the full error message
reducers.deductibleStatus = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.deductibleStatus,
  [actionTypes.UPDATE_MEMBER_ENROLLMENTS_SUCCESS]: (state: any, action: any) => {
    return action.response.deductibleStatus
  }
})

// @ts-expect-error TS(2339): Property 'memberHasLegalPartner' does not exist on... Remove this comment to see the full error message
reducers.memberHasLegalPartner = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.memberHasLegalPartner,
  [actionTypes.UPDATE_MEMBER_HAS_LEGAL_PARTNER_SUCCESS]: (state: any, action: any) => action.response,
  [TestUserActionTypes.TOGGLE_MEMBER_HAS_LEGAL_PARTNER]: (state: any) => !state,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.memberHasLegalPartner
})

// @ts-expect-error TS(2339): Property 'memberCoverageType' does not exist on ty... Remove this comment to see the full error message
reducers.memberCoverageType = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.memberCoverageType,
  [actionTypes.UPDATE_MEMBER_COVERAGE_TYPE_SUCCESS]: (state: any, action: any) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.memberCoverageType
})

// @ts-expect-error TS(2339): Property 'memberCoverageTypeSelectValue' does not ... Remove this comment to see the full error message
reducers.memberCoverageTypeSelectValue = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.memberCoverageTypeSelectValue,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.memberCoverageTypeSelectValue
})
// @ts-expect-error TS(2339): Property 'memberPlanSource' does not exist on type... Remove this comment to see the full error message
reducers.memberPlanSource = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.memberPlanSource,
  [actionTypes.UPDATE_MEMBER_PLAN_SOURCE_SUCCESS]: (state: any, action: any) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.memberPlanSource
})
// @ts-expect-error TS(2339): Property 'memberPlanSourceSelectValue' does not ex... Remove this comment to see the full error message
reducers.memberPlanSourceSelectValue = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.memberPlanSourceSelectValue,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.memberPlanSourceSelectValue
})
// @ts-expect-error TS(2339): Property 'memberHasHSA' does not exist on type '{}... Remove this comment to see the full error message
reducers.memberHasHSA = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.memberHasHSA,
  [actionTypes.UPDATE_MEMBER_HAS_HSA_SUCCESS]: (state: any, action: any) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.memberHasHSA
})
// @ts-expect-error TS(2339): Property 'memberHsaContributionStatus' does not ex... Remove this comment to see the full error message
reducers.memberHsaContributionStatus = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.memberHsaContributionStatus,
  [actionTypes.UPDATE_MEMBER_HSA_CONTRIBUTION_STATUS_SUCCESS]: (state: any, action: any) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.memberHsaContributionStatus
})
// @ts-expect-error TS(2339): Property 'memberHasHDHP' does not exist on type '{... Remove this comment to see the full error message
reducers.memberHasHDHP = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.memberHasHDHP,
  [actionTypes.UPDATE_MEMBER_HAS_HDHP_SUCCESS]: (state: any, action: any) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.memberHasHDHP
})
// @ts-expect-error TS(2339): Property 'partnerCoverageType' does not exist on t... Remove this comment to see the full error message
reducers.partnerCoverageType = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.partnerCoverageType,
  [actionTypes.UPDATE_PARTNER_COVERAGE_TYPE_SUCCESS]: (state: any, action: any) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.partnerCoverageType
})
// @ts-expect-error TS(2339): Property 'partnerCoverageTypeSelectValue' does not... Remove this comment to see the full error message
reducers.partnerCoverageTypeSelectValue = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.partnerCoverageTypeSelectValue,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.partnerCoverageTypeSelectValue
})
// @ts-expect-error TS(2339): Property 'partnerPlanSource' does not exist on typ... Remove this comment to see the full error message
reducers.partnerPlanSource = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.partnerPlanSource,
  [actionTypes.UPDATE_PARTNER_PLAN_SOURCE_SUCCESS]: (state: any, action: any) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.partnerPlanSource
})
// @ts-expect-error TS(2339): Property 'partnerPlanSourceSelectValue' does not e... Remove this comment to see the full error message
reducers.partnerPlanSourceSelectValue = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.partnerPlanSourceSelectValue,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.partnerPlanSourceSelectValue
})
// @ts-expect-error TS(2339): Property 'partnerHasHSA' does not exist on type '{... Remove this comment to see the full error message
reducers.partnerHasHSA = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.partnerHasHSA,
  [actionTypes.UPDATE_PARTNER_HAS_HSA_SUCCESS]: (state: any, action: any) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.partnerHasHSA
})
// @ts-expect-error TS(2339): Property 'partnerHsaContributionStatus' does not e... Remove this comment to see the full error message
reducers.partnerHsaContributionStatus = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.partnerHsaContributionStatus,
  [actionTypes.UPDATE_PARTNER_HSA_CONTRIBUTION_STATUS_SUCCESS]: (state: any, action: any) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.partnerHsaContributionStatus
})
// @ts-expect-error TS(2339): Property 'partnerHasHDHP' does not exist on type '... Remove this comment to see the full error message
reducers.partnerHasHDHP = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.partnerHasHDHP,
  [actionTypes.UPDATE_PARTNER_HAS_HDHP_SUCCESS]: (state: any, action: any) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.partnerHasHDHP
})
// @ts-expect-error TS(2339): Property 'partnerHealthPlanMemberId' does not exis... Remove this comment to see the full error message
reducers.partnerHealthPlanMemberId = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.partnerHealthPlanMemberId,
  [actionTypes.UPDATE_MEMBER_ID_SUCCESS]: (state: any, action: any) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.partnerHealthPlanMemberId
})
// @ts-expect-error TS(2339): Property 'memberId' does not exist on type '{}'.
reducers.memberId = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.memberId,
  [actionTypes.UPDATE_MEMBER_ID_SUCCESS]: (state: any, action: any) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.memberId
})
// @ts-expect-error TS(2339): Property 'partnerHealthPlanSelectedTradingPartnerI... Remove this comment to see the full error message
reducers.partnerHealthPlanSelectedTradingPartnerId = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.partnerHealthPlanSelectedTradingPartnerId,
  [actionTypes.UPDATE_SELECTED_TRADING_PARTNER_SUCCESS]: (state: any, action: any) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) =>
    action.response.partnerHealthPlanSelectedTradingPartnerId
})
// @ts-expect-error TS(2339): Property 'partnerHealthPlanSelectedTradingPartnerP... Remove this comment to see the full error message
reducers.partnerHealthPlanSelectedTradingPartnerPk = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.partnerHealthPlanSelectedTradingPartnerPk,
  [actionTypes.UPDATE_SELECTED_TRADING_PARTNER_SUCCESS]: (state: any, action: any) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) =>
    action.response.partnerHealthPlanSelectedTradingPartnerPk
})
// @ts-expect-error TS(2339): Property 'selectedTradingPartnerId' does not exist... Remove this comment to see the full error message
reducers.selectedTradingPartnerId = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.selectedTradingPartnerId,
  [actionTypes.UPDATE_SELECTED_TRADING_PARTNER_SUCCESS]: (state: any, action: any) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.selectedTradingPartnerId
})
// @ts-expect-error TS(2339): Property 'selectedTradingPartnerPk' does not exist... Remove this comment to see the full error message
reducers.selectedTradingPartnerPk = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.selectedTradingPartnerPk,
  [actionTypes.UPDATE_SELECTED_TRADING_PARTNER_SUCCESS]: (state: any, action: any) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.selectedTradingPartnerPk
})
// @ts-expect-error TS(2339): Property 'memberHasSuccessfullySyncedInsurance' do... Remove this comment to see the full error message
reducers.memberHasSuccessfullySyncedInsurance = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.memberHasSuccessfullySyncedInsurance,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.memberHasSuccessfullySyncedInsurance
})
// @ts-expect-error TS(2339): Property 'partnerHasSuccessfullySyncedInsurance' d... Remove this comment to see the full error message
reducers.partnerHasSuccessfullySyncedInsurance = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.partnerHasSuccessfullySyncedInsurance,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state: any, action: any) => action.response.partnerHasSuccessfullySyncedInsurance
})
// @ts-expect-error TS(2339): Property 'isTestUser' does not exist on type '{}'.
reducers.isTestUser = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.isTestUser
})
// @ts-expect-error TS(2339): Property 'isPosingAsMember' does not exist on type... Remove this comment to see the full error message
reducers.isPosingAsMember = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.isPosingAsMember
})
// @ts-expect-error TS(2339): Property 'genderIdentityOther' does not exist on t... Remove this comment to see the full error message
reducers.genderIdentityOther = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.genderIdentityOther
})
// @ts-expect-error TS(2339): Property 'partnerGenderIdentityOther' does not exi... Remove this comment to see the full error message
reducers.partnerGenderIdentityOther = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.partnerGenderIdentityOther
})
// @ts-expect-error TS(2339): Property 'preferredName' does not exist on type '{... Remove this comment to see the full error message
reducers.preferredName = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.preferredName
})
// @ts-expect-error TS(2339): Property 'partnerPreferredName' does not exist on ... Remove this comment to see the full error message
reducers.partnerPreferredName = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.partnerPreferredName
})
// @ts-expect-error TS(2339): Property 'onCarrotCardNonQmeJourney' does not exis... Remove this comment to see the full error message
reducers.onCarrotCardNonQmeJourney = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.onCarrotCardNonQmeJourney
})
// @ts-expect-error TS(2339): Property 'carrotPlansRoutingStarted' does not exis... Remove this comment to see the full error message
reducers.carrotPlansRoutingStarted = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.carrotPlansRoutingStarted
})
// @ts-expect-error TS(2339): Property 'carrotPlansRoutingCompleted' does not ex... Remove this comment to see the full error message
reducers.carrotPlansRoutingCompleted = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.carrotPlansRoutingCompleted
})
// @ts-expect-error TS(2339): Property 'preferredEmail' does not exist on type '... Remove this comment to see the full error message
reducers.preferredEmail = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.preferredEmail
})
// @ts-expect-error TS(2339): Property 'preferredLocale' does not exist on type ... Remove this comment to see the full error message
// eslint-disable-next-line no-restricted-syntax -- Handling the preferredLocale value included in the userInfo response
reducers.preferredLocale = createReducer(null, {
  // eslint-disable-next-line no-restricted-syntax
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.preferredLocale
})
// @ts-expect-error TS(2339): Property 'dismissedPhoneNumberFeatureModal' does n... Remove this comment to see the full error message
reducers.dismissedPhoneNumberFeatureModal = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.dismissedPhoneNumberFeatureModal
})
// @ts-expect-error TS(2339): Property 'locale' does not exist on type '{}'.
reducers.locale = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state: any, action: any) => action.response.locale
})

const userInfo = combineReducers(reducers)

export const getUserInfo = (state: any) => state.userInfo

export const getCardState = (state: any) => state.userInfo.cardState
export const getEligibilityDate = (state: any) => state.userInfo.eligibilityDate
export const getEmployeeId = (state: any) => state.userInfo.employeeId
export const getIsPosingAsMember = (state: any) => state.userInfo.isPosingAsMember
export const getHasBankInfoFilledOut = (state: any) => state.userInfo.hasBankInfoFilledOut
export const getHasCardholderId = (state: any) => state.userInfo.hasCardholderId
export const getIsActivateError = (state: any) => state.userInfo.isActivateError
export const getIsActivateSubmitting = (state: any) => state.userInfo.isActivateSubmitting
export const getIsCarrotCardEligible = (state: any) => state.userInfo.isCarrotCardEligible
export const getIsUsa = (state: any) => state.userInfo.isUsa
export const getFirstName = (state: any) => state.userInfo.firstName
export const getLastName = (state: any) => state.userInfo.lastName
export const getUserInfoLoaded = (state: any) => state.userInfo.loaded
export const getCarrotUserId = (state: any) => state.userInfo.carrotUserId
export const getDeductibleStatus = (state: any) => state.userInfo.deductibleStatus
export const getPartnerPlanSourceSelectValue = (state: any) => state.userInfo.partnerPlanSourceSelectValue
export const getMemberHasSuccessfullySyncedInsurance = (state: any) =>
  state.userInfo.memberHasSuccessfullySyncedInsurance
export const getPartnerHasSuccessfullySyncedInsurance = (state: any) =>
  state.userInfo.partnerHasSuccessfullySyncedInsurance
export const getPreferredName = (state: any) => state.userInfo.preferredName
// eslint-disable-next-line no-restricted-syntax -- We use this selector for a PreferredLocale HeapUserProperty
export const getPreferredLocale = (state: any) => state.userInfo.preferredLocale

export default userInfo
