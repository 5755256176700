import React, { FC } from "react"
import { Reimbursement } from "types/reimbursement"
import { FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux"
import { getCompanyCurrencyCode } from "redux/reducers/companyInfo"

type ReimbursementDeductibleStringProps = {
  reimbursement: Reimbursement
}

const EligibleLabel: FC<ReimbursementDeductibleStringProps> = ({ reimbursement }) => {
  const intl = useIntl()
  const currencySymbol = useSelector(getCompanyCurrencyCode)
  const amountAppliedToDeductibleDisplay = intl.formatNumber(reimbursement.eligibleAmount / 100, {
    style: "currency",
    currency: currencySymbol,
    currencyDisplay: "narrowSymbol"
  })
  return (
    <div>
      <FormattedMessage
        defaultMessage="Eligible for reimbursement: {amountAppliedToDeductibleDisplay}"
        values={{ amountAppliedToDeductibleDisplay }}
      />
    </div>
  )
}

const AppliedToDeductibleLabel: FC<ReimbursementDeductibleStringProps> = ({ reimbursement }) => {
  const intl = useIntl()
  const currencySymbol = "USD"
  const amountAppliedToDeductibleDisplay = intl.formatNumber(reimbursement.amountAppliedToDeductible / 100, {
    style: "currency",
    currency: currencySymbol,
    currencyDisplay: "narrowSymbol"
  })
  return (
    <div>
      <FormattedMessage
        defaultMessage="Applied to deductible: {amountAppliedToDeductibleDisplay}"
        values={{ amountAppliedToDeductibleDisplay }}
      />
    </div>
  )
}

const AppliedToCostShareLabel: FC<ReimbursementDeductibleStringProps> = ({ reimbursement }) => {
  const intl = useIntl()
  const currencySymbol = useSelector(getCompanyCurrencyCode)
  const membersCostShareContributionDisplay = intl.formatNumber(reimbursement.membersCostShareContribution / 100, {
    style: "currency",
    currency: currencySymbol,
    currencyDisplay: "narrowSymbol"
  })
  return (
    <div>
      <FormattedMessage
        defaultMessage="Your contribution: {membersCostShareContributionDisplay}"
        values={{ membersCostShareContributionDisplay }}
      />
    </div>
  )
}

const ReimbursementsListDeductibleString: FC<ReimbursementDeductibleStringProps> = ({
  reimbursement
}: ReimbursementDeductibleStringProps) => {
  function hasDeductibleContribution(): boolean {
    return reimbursement.amountAppliedToDeductible > 0
  }

  function hasCostShareContribution(): boolean {
    return reimbursement.membersCostShareContribution > 0
  }

  if (hasDeductibleContribution()) {
    return hasCostShareContribution() ? (
      <>
        <EligibleLabel reimbursement={reimbursement} />
        <AppliedToCostShareLabel reimbursement={reimbursement} />
        <AppliedToDeductibleLabel reimbursement={reimbursement} />
      </>
    ) : (
      <>
        <EligibleLabel reimbursement={reimbursement} />
        <AppliedToDeductibleLabel reimbursement={reimbursement} />
      </>
    )
  } else {
    return hasCostShareContribution() ? (
      <>
        <EligibleLabel reimbursement={reimbursement} />
        <AppliedToCostShareLabel reimbursement={reimbursement} />
      </>
    ) : (
      <></>
    )
  }
}

export { ReimbursementsListDeductibleString }
