import { useUserContext } from "../context/UserContext"
import { useGetMostRecentlyCompletedRouting } from "../../../components/carrot-plans/hooks/useGetMostRecentlyCompletedRouting"
import { CountryCode } from "../../../utils/CountryCode"
import { Journey } from "../../../lib/contentful/utils/journeyOrderMapping"
import useGetBenefit from "./useGetBenefit"
import { dayjs } from "@carrotfertility/carotene-core"
import { useFlags } from "launchdarkly-react-client-sdk"

export type ProviderRecommendationConfig = {
  showProviderRecommendationsBanner: boolean
  showPediatricianRecommendationsBanner: boolean
  showPediatricianRecommendationsForm: boolean
  showObGynRecommendationsBanner: boolean
  showObGynRecommendationsForm: boolean
  showSecondarySteerageLink: boolean
}

type UseShowProviderRecommendationsResult = {
  isLoading: boolean
  isError: boolean
  data: ProviderRecommendationConfig
}

export default function useShowProviderRecommendations(): UseShowProviderRecommendationsResult {
  const { companyCountryCode } = useUserContext()
  const { data: benefit, isLoading: isBenefitLoading, isError: isBenefitError } = useGetBenefit()
  const {
    data: routingData,
    isLoading: isRoutingInfoLoading,
    isError: isRoutingInfoError
  } = useGetMostRecentlyCompletedRouting()

  const { displayGarnerHealth: displayGarnerHealthExpansion } = useFlags()

  const isUnitedStates = companyCountryCode === CountryCode.United_States
  const journey = !isRoutingInfoLoading ? routingData?.journey : null
  const journeyStage = !isRoutingInfoLoading ? routingData?.journeyStage : null
  const dueDate = !isRoutingInfoLoading ? routingData?.dueDate : null
  const deliveryDate = !isRoutingInfoLoading ? routingData?.deliveryDate : null
  const providerFinderConfig = !isBenefitLoading ? benefit?.providerFinder : null

  let isThirdTrimesterOrPostpartum = false
  if ((dueDate || deliveryDate) && dayjs(dueDate || deliveryDate) <= dayjs().add(14, "weeks")) {
    isThirdTrimesterOrPostpartum = true
  } else if (journeyStage === "PREGNANT_THIRD" || journeyStage === "POSTPARTUM") {
    isThirdTrimesterOrPostpartum = true
  }

  const showPediatricianRecommendationsBanner =
    isUnitedStates &&
    providerFinderConfig?.showPediatricianRecommendations &&
    (displayGarnerHealthExpansion
      ? (journey === Journey.PREGNANT && isThirdTrimesterOrPostpartum) ||
        journey === Journey.PARENTING ||
        journey === Journey.ADOPTION ||
        journey === Journey.GESTATIONAL
      : journey === Journey.PARENTING)

  const showPediatricianRecommendationsForm = isUnitedStates && providerFinderConfig?.showPediatricianRecommendations

  const showObGynRecommendationsBanner =
    isUnitedStates &&
    providerFinderConfig?.showObGynRecommendations &&
    (displayGarnerHealthExpansion
      ? (journey === Journey.PREGNANT && !isThirdTrimesterOrPostpartum) ||
        journey === Journey.ASSISTED_REPRODUCTION ||
        journey === Journey.TRY_PREGNANT
      : journey === Journey.PREGNANT)

  const showObGynRecommendationsForm = isUnitedStates && providerFinderConfig?.showObGynRecommendations

  const showSecondarySteerageLink = isUnitedStates && journey === Journey.PREGNANT

  return {
    isLoading: isBenefitLoading || isRoutingInfoLoading,
    isError: isBenefitError || isRoutingInfoError,
    data: {
      showProviderRecommendationsBanner: showPediatricianRecommendationsBanner || showObGynRecommendationsBanner,
      showPediatricianRecommendationsBanner: showPediatricianRecommendationsBanner,
      showPediatricianRecommendationsForm: showPediatricianRecommendationsForm,
      showObGynRecommendationsBanner: showObGynRecommendationsBanner,
      showObGynRecommendationsForm: showObGynRecommendationsForm,
      showSecondarySteerageLink: showSecondarySteerageLink
    }
  }
}
