import ProcessTracker from "components/views/modal/ProgressTracker"
import React, { useContext } from "react"
import { useBenefitEnrollment } from "../context/enrollment/BenefitEnrollmentContext"
import EmployeeOnlyModalContainer from "../employee-only-containers/EmployeeOnlyModalContainer"
import { Box, CloseIcon, Dialog, DialogContent, IconButton } from "@carrotfertility/carotene-core"
import { useIntl } from "react-intl"

export enum EnrollmentType {
  STANDARD,
  QLE_RE_ENROLLMENT,
  INSURANCE_EDIT
}

export type EnrollmentFlowModalContextProps = {
  onExit: () => void
  enrollmentType: EnrollmentType
}

function GetNumberOfSteps(): number {
  const { companyHasCMDEnabled, companyHasExternalDeductible } = useBenefitEnrollment()
  const isCMD = companyHasCMDEnabled
  const isEMD = companyHasExternalDeductible
  const isACA = !isCMD && !isEMD
  return isACA ? 1 : 2
}

const EnrollmentFlowModalContext = React.createContext<EnrollmentFlowModalContextProps>(null)

export const useEnrollmentFlowModal = () => useContext(EnrollmentFlowModalContext)

// @ts-expect-error TS7031
export function EnrollmentModal({ onExit, enrollmentType = EnrollmentType.STANDARD, children }): JSX.Element {
  const context = {
    onExit,
    enrollmentType
  }

  const intl = useIntl()

  return (
    <EmployeeOnlyModalContainer onClose={onExit}>
      <Dialog open={true} onClose={onExit} aria-labelledby="step-heading">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <IconButton aria-label={intl.formatMessage({ defaultMessage: "Close" })} onClick={onExit}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent sx={{ gap: "0" }}>
          <EnrollmentFlowModalContext.Provider value={context}>
            <ProcessTracker numSteps={GetNumberOfSteps()}>{children}</ProcessTracker>
          </EnrollmentFlowModalContext.Provider>
        </DialogContent>
      </Dialog>
    </EmployeeOnlyModalContainer>
  )
}
