import { useContext } from "react"
import { MessageOverrideContext } from "./form"
import { CarrotJsonSchema, SchemaErrorMetadata, SchemaErrorMetadataName } from "./types"

export function useFormErrorMessage({ schema, hasError }: { schema: CarrotJsonSchema; hasError: boolean }) {
  const { overrideErrorMessage } = useContext(MessageOverrideContext)
  if (!hasError) {
    return null
  }
  return overrideErrorMessage
    ? overrideErrorMessage(schema.validationMessage, schema)
    : getHumanReadableErrorMessage(schema)
}

function getHumanReadableErrorMessage(schema: CarrotJsonSchema) {
  const fieldName: string = schema?.shortTitle ?? schema?.title ?? ""
  return `${fieldName} ${schema.validationMessage}`
}

export function mapSchemaToErrorMetadata(
  schema: CarrotJsonSchema,
  overrideTitle?: (originalTitle: string) => string
): SchemaErrorMetadata {
  const originalTitle: string = schema.shortTitle ?? schema.title ?? ""
  return {
    [SchemaErrorMetadataName.fieldName]: overrideTitle ? overrideTitle(originalTitle) : originalTitle,
    // eslint-disable-next-line eqeqeq
    [SchemaErrorMetadataName.length]: schema.minLength == schema.maxLength ? schema.minLength : undefined,
    [SchemaErrorMetadataName.minLength]: schema.minLength,
    [SchemaErrorMetadataName.maxLength]: schema.maxLength
  }
}
