import React, { useRef, useState } from "react"
import { useTrackView } from "../../../utils/heap"
import { FormattedMessage, useIntl } from "react-intl"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { Box, Button, Dialog, BackIcon, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { RegistrationActionBar, ContinueButton, SkipButton } from "./RegistrationButtons"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { RegisteringLoadingIndicator } from "./RegistrationLoadingIndicator"
import { CarrotDocumentType, getSignatureForDocument, SignatureCompleteEvent } from "carrot-api/signatureClient"
import { DocumentAction, useUpdateDocumentStatus } from "components/medical-records-consent/utils/DocumentStatusApi"
import { determineDocumentAction } from "components/medical-records-consent/utils/documentStatusHelper"

interface PregnancyMedicalRecordsCollectionProps {
  onComplete: () => void
}

const windowEventListeners: Array<(event: SignatureCompleteEvent) => void> = []

export function PregnancyMedicalRecordsCollection({ onComplete }: PregnancyMedicalRecordsCollectionProps): JSX.Element {
  useTrackView("PregnancyMedicalRecordsCollectionRegFlow", "Pregnancy medical records collection screen")
  const theme = useTheme()
  const intl = useIntl()
  const { mutateAsync: updateDocumentStatus } = useUpdateDocumentStatus()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [frameSrc, setFrameSrc] = useState("/blank.html")
  const [docuSignIsReady, setDocuSignIsReady] = useState(false)
  const signatureIdRef = useRef<number | null>(null)
  const header = intl.formatMessage({ defaultMessage: "Share some medical information" })

  const handleWindowEvent = async (event: SignatureCompleteEvent) => {
    if (event?.data?.documentType === CarrotDocumentType.PregnancyMedicalRecordsConsentForm) {
      const documentAction = determineDocumentAction(event)
      await onCompleteStep(documentAction)
      onCloseModal()
    }
  }

  const onCloseModal = () => {
    windowEventListeners.forEach((listener) => window.removeEventListener("message", listener))
    windowEventListeners.length = 0
    setModalIsOpen(false)
    setFrameSrc("/blank.html")
    setDocuSignIsReady(false)
  }

  const onCompleteStep = async (documentAction: DocumentAction) => {
    await updateDocumentStatus({
      documentType: CarrotDocumentType.PregnancyMedicalRecordsConsentForm,
      documentStatusRequest: { signatureId: signatureIdRef.current, documentAction }
    })

    if (onComplete) {
      onComplete()
    }
  }

  const showDocument = async () => {
    setModalIsOpen(true)
    window.addEventListener("message", handleWindowEvent)
    const envelopeSignatureInfo = await getSignatureForDocument(CarrotDocumentType.PregnancyMedicalRecordsConsentForm)
    signatureIdRef.current = envelopeSignatureInfo.signatureId
    setFrameSrc(envelopeSignatureInfo.docuSignUrl)
    setDocuSignIsReady(true)
  }

  const handleSkipButton = async () => {
    await onCompleteStep("SKIPPED")
  }
  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={header}
        imageName="illo-doctor-with-clipboard"
        description={
          <Stack spacing={theme.tokens.spacing.md} marginTop={theme.tokens.spacing.xs}>
            <Typography>
              <FormattedMessage defaultMessage="Please review and sign the following authorization to share your pregnancy-related medical records with us. This allows us to better support you and use anonymized health information to improve birth outcomes for all our members." />
            </Typography>
            <Typography>
              <FormattedMessage defaultMessage="Personal information is always kept secure, and we will never share it with your benefit sponsor or any other third party." />
            </Typography>
          </Stack>
        }
        interactiveElements={
          <RegistrationActionBar
            primaryAction={
              <ContinueButton onClick={showDocument}>
                <FormattedMessage defaultMessage="Review authorization" />
              </ContinueButton>
            }
            tertiaryAction={
              <SkipButton onClick={handleSkipButton}>
                <FormattedMessage defaultMessage="Skip" />
              </SkipButton>
            }
          />
        }
      />
      <Dialog
        aria-labelledby={intl.formatMessage({ defaultMessage: "Authorization Form Modal" })}
        onClose={onCloseModal}
        open={modalIsOpen}
        fullScreen
        PaperProps={{
          sx: {
            borderRadius: theme.tokens.borderRadius.none,
            padding: theme.tokens.spacing.xs,
            [theme.breakpoints.only("md")]: {
              padding: theme.tokens.spacing.md
            },
            [theme.breakpoints.up("md")]: {
              padding: theme.tokens.spacing.xl
            }
          }
        }}
      >
        <Button onClick={onCloseModal} variant="text" color="secondary" startIcon={<BackIcon />}>
          <FormattedMessage defaultMessage="Back" />
        </Button>
        <Box marginTop={theme.tokens.spacing.xs} height="95%">
          {docuSignIsReady ? (
            <iframe
              title={intl.formatMessage({ defaultMessage: "Authorization Form" })}
              style={{ width: "100%", height: "100%" }}
              src={frameSrc}
              allowFullScreen
            />
          ) : (
            <RegisteringLoadingIndicator isModal={true} />
          )}
        </Box>
      </Dialog>
    </>
  )
}
