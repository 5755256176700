// @ts-expect-error TS(2307) FIXME: Cannot find module 'css/variables/_colors.scss' or... Remove this comment to see the full error message
import colors from "css/variables/_colors.scss"

export const lightFormTheme = {
  name: "lightFormTheme",
  borderColor: colors.mineralGrey,
  disabledBackgroundColor: colors.ghostWhite,
  checkedCheckboxBackgroundColor: colors.carrotOrange,
  checkboxCheckColor: colors.white
}

export const fleFormTheme = {
  name: "fleFormTheme",
  borderColor: "rgba(0, 0, 0, 0.25)",
  disabledBackgroundColor: colors.chardon,
  checkedCheckboxBackgroundColor: colors.white,
  checkboxCheckColor: "#e48400"
}
