import { useHistory } from "react-router"
import { Workflows } from "../steps/medical-questions/workflows"

export const afabJourneys = ["1", "2", "3", "4", "5"]
export const amabJourneys = ["6", "7", "8"]
export const AmabKeys = {
  "6": "PRESERVATION",
  "7": "LOW_TESTOSTERONE",
  "8": "ASSISTED_REPRODUCTION"
}

export const AfabKeys: { [key: string]: string } = {
  "1": "PREGNANT",
  "2": "TRY_PREGNANT",
  "3": "PAST_FERTILITY_TREATMENT",
  "4": "PAST_FERTILITY_TREATMENT",
  "5": "MENOPAUSE"
}

// @ts-expect-error TS7006
export const useMedicalQuestionMenuItem = (journey) => {
  const history = useHistory()
  if (afabJourneys.includes(journey)) {
    return Workflows.AFAB_MEDICAL_QUESTIONS
  } else if (amabJourneys.includes(journey)) {
    return Workflows.AMAB_MEDICAL_QUESTIONS
  } else {
    history.push("/")
  }
}
