import { defineMessage } from "react-intl"
import Helpers from "utils/Helpers"

const CarrotErrorCodes = {
  EMPLOYEE_INACTIVE: "MEMBER_INACTIVE",
  EMPLOYEE_ALREADY_REGISTERED: "EMPLOYEE_ALREADY_REGISTERED",
  EMPLOYEE_NOT_FOUND: "EMPLOYEE_NOT_FOUND",
  EMPLOYEE_NOT_SAML_ENABLED: "EMPLOYEE_NOT_SAML_ENABLED",
  EMPLOYEE_SAML_ENABLED: "EMPLOYEE_SAML_ENABLED",
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
  NOT_AUTHENTICATED: "NOT_AUTHENTICATED",
  SAML_ACCESS_DENIED: "SAML_ACCESS_DENIED",
  SAML_COULD_NOT_FIND_PRINCIPAL_CLAIM: "SAML_COULD_NOT_FIND_PRINCIPAL_CLAIM",
  SAML_COULD_NOT_FIND_PRINCIPAL_IDENTITY: "SAML_COULD_NOT_FIND_PRINCIPAL_IDENTITY",
  SAML_ENTITY_ID_MISMATCH: "SAML_ENTITY_ID_MISMATCH",
  UNEXPECTED_ERROR: "UNEXPECTED_ERROR",
  EMPLOYEE_ALREADY_HAS_EMAIL: "EMPLOYEE_ALREADY_HAS_EMAIL",
  MULTIPLE_EMPLOYEES_FOUND: "MULTIPLE_EMPLOYEES_FOUND",
  PERSONAL_EMAIL_ALREADY_EXISTS: "PERSONAL_EMAIL_ALREADY_EXISTS",
  PARTNER_EMAIL_ALREADY_EXISTS: "PARTNER_EMAIL_ALREADY_EXISTS",
  EMAIL_VERIFICATION_MISSING_PERSONAL_EMAIL: "EMAIL_VERIFICATION_MISSING_PERSONAL_EMAIL",
  SAML_UNSUCCESSFUL_OPERATION_EXCEPTION: "SAML_UNSUCCESSFUL_OPERATION_EXCEPTION",
  PARTNER_ALREADY_REGISTERED: "PARTNER_ALREADY_REGISTERED",
  PARTNER_INVITE_INACTIVE: "PARTNER_INVITE_INACTIVE",
  PASSWORD_MIN_LENGTH: "PASSWORD_MIN_LENGTH",
  PASSWORD_TOO_COMMON: "PASSWORD_TOO_COMMON",
  TRY_AGAIN_LATER: "TRY_AGAIN_LATER"
}

const CARROT_ERROR_CODES_TO_MESSAGES = {
  [CarrotErrorCodes.EMPLOYEE_NOT_SAML_ENABLED]: defineMessage({ id: "accessDenied", defaultMessage: "Access denied." }),
  [CarrotErrorCodes.EMPLOYEE_ALREADY_REGISTERED]: defineMessage({
    id: "alreadyRegistered",
    defaultMessage: "Looks like you already have a Carrot account."
  }),
  [CarrotErrorCodes.EMPLOYEE_NOT_FOUND]: defineMessage({ defaultMessage: "We couldn't find your Carrot account." }),
  [CarrotErrorCodes.EMPLOYEE_SAML_ENABLED]: defineMessage({
    id: "alreadyRegistered",
    defaultMessage: "Looks like you already have a Carrot account."
  }),
  [CarrotErrorCodes.INTERNAL_SERVER_ERROR]: defineMessage({
    defaultMessage: "Oops, there was a problem with our servers."
  }),
  [CarrotErrorCodes.NOT_AUTHENTICATED]: defineMessage({
    defaultMessage: "You have been signed out for your privacy. Please sign back in to return to where you left off."
  }),
  [CarrotErrorCodes.SAML_ACCESS_DENIED]: defineMessage({ id: "accessDenied", defaultMessage: "Access denied." }),
  [CarrotErrorCodes.SAML_COULD_NOT_FIND_PRINCIPAL_CLAIM]: defineMessage({
    id: "accessDenied",
    defaultMessage: "Access denied."
  }),
  [CarrotErrorCodes.SAML_COULD_NOT_FIND_PRINCIPAL_IDENTITY]: defineMessage({
    id: "accessDenied",
    defaultMessage: "Access denied."
  }),
  [CarrotErrorCodes.SAML_ENTITY_ID_MISMATCH]: defineMessage({ id: "accessDenied", defaultMessage: "Access denied." }),
  [CarrotErrorCodes.UNEXPECTED_ERROR]: defineMessage({ defaultMessage: "Oops, something went wrong." }),
  [CarrotErrorCodes.EMPLOYEE_INACTIVE]: defineMessage({
    defaultMessage:
      "It looks like your account has recently been deactivated. If you need to submit receipts for service prior to the end of your employment, please <link>contact us</link>. If you feel that your account has been deactivated in error, please reach out to your internal benefits team for next steps."
  }),
  [CarrotErrorCodes.SAML_UNSUCCESSFUL_OPERATION_EXCEPTION]: defineMessage({
    defaultMessage:
      "For security, you may need to sign in from your company device and/or network. If that doesn't work, please reach out to your IT team. Questions? Contact us at {link}."
  }),
  [CarrotErrorCodes.PARTNER_ALREADY_REGISTERED]: defineMessage({
    id: "alreadyRegistered",
    defaultMessage: "Looks like you already have a Carrot account."
  }),
  [CarrotErrorCodes.PARTNER_INVITE_INACTIVE]: defineMessage({
    defaultMessage: "This invitation link is no longer active. Please ask your partner to resend the invitation."
  }),
  [CarrotErrorCodes.PASSWORD_MIN_LENGTH]: defineMessage({
    defaultMessage: "Password must be at least 8 characters long"
  }),
  [CarrotErrorCodes.PASSWORD_TOO_COMMON]: defineMessage({
    defaultMessage: "Password is too common"
  }),
  [CarrotErrorCodes.EMPLOYEE_ALREADY_HAS_EMAIL]: defineMessage({
    defaultMessage:
      "This email is already in use. Please enter a different email address or <link>contact us</link> for help."
  }),
  [CarrotErrorCodes.TRY_AGAIN_LATER]: defineMessage({ defaultMessage: "Something went wrong. Try again later." })
}

const getErrorMessageFromCode = (errorCode: any) => {
  return Helpers.getFromMapOrReportAndDefault(
    CARROT_ERROR_CODES_TO_MESSAGES,
    errorCode,
    CarrotErrorCodes.UNEXPECTED_ERROR
  )
}

export { CarrotErrorCodes, getErrorMessageFromCode }
