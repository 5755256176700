type MobileAppControl = {
  // API for wiring up the mobile app bridge. This get utilized by the Carrot React app
  _appVersion: string

  // API that the mobile apps use
  initializeAppVersion: (appVersion: string) => void
}

export const mobileAppControl = globalThis as unknown as MobileAppControl

export function updateMobileAppControl(key: keyof MobileAppControl, val: unknown) {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  globalThis[key] = val
}

// Register some callbacks that the native implementations will dynamically call back with the response
// It's ugly but we don't have another choice at this time.
const initGlobalCallback = () => {
  updateMobileAppControl("initializeAppVersion", (appVersion: string) => {
    updateMobileAppControl("_appVersion", appVersion)
  })
}

export { initGlobalCallback }
