import { Steps } from "../../steps"

// prettier-ignore
export default [
  {forStep: Steps.PROCESS_ENROLLMENT_TYPE,                        memberSelects: '',                                    goto: Steps.FIRST_TIME_OR_RE_ENROLL},
  {forStep: Steps.FIRST_TIME_OR_RE_ENROLL,                        memberSelects: Steps.RE_ENROLL_INTRO_INFORMATIONAL,   goto: Steps.RE_ENROLL_INTRO_INFORMATIONAL},
  {forStep: Steps.FIRST_TIME_OR_RE_ENROLL,                        memberSelects: Steps.INTRO_INFORMATIONAL ,            goto: Steps.INTRO_INFORMATIONAL},
  {forStep: Steps.RE_ENROLL_INTRO_INFORMATIONAL,                  memberSelects: '',                                    goto: Steps.INSURANCE_DISCLAIMER},
  {forStep: Steps.INTRO_INFORMATIONAL,                            memberSelects: '',                                    goto: Steps.INSURANCE_DISCLAIMER},
  {forStep: Steps.INTRO_INFORMATIONAL,                            memberSelects: 'SKIP_THIS_FLOW',                      goto: Steps.CONNECT_INSURANCE_FLOW},
  {forStep: Steps.INSURANCE_DISCLAIMER,                           memberSelects: '',                                    goto: Steps.ARE_YOU_ENROLLED_IN_MEDICARE},
  {forStep: Steps.INSURANCE_DISCLAIMER,                           memberSelects: 'SKIP_MEDICARE',                       goto: Steps.ARE_YOU_LEGALLY_MARRIED},
  {forStep: Steps.ARE_YOU_ENROLLED_IN_MEDICARE,                   memberSelects: 'YES',                                 goto: Steps.MEDICARE_ID},
  {forStep: Steps.ARE_YOU_ENROLLED_IN_MEDICARE,                   memberSelects: 'NO',                                  goto: Steps.ARE_YOU_LEGALLY_MARRIED},
  {forStep: Steps.MEDICARE_ID,                                    memberSelects: '',                                    goto: Steps.ARE_YOU_LEGALLY_MARRIED},
  {forStep: Steps.ARE_YOU_LEGALLY_MARRIED,                        memberSelects: 'true',                                goto: Steps.MEMBER_COVERAGE_TYPE},
  {forStep: Steps.ARE_YOU_LEGALLY_MARRIED,                        memberSelects: 'false',                               goto: Steps.PARTNER_TAX_DEPENDENT},
  {forStep: Steps.PARTNER_TAX_DEPENDENT,                          memberSelects: 'true',                                goto: Steps.MEMBER_COVERAGE_TYPE},
  {forStep: Steps.PARTNER_TAX_DEPENDENT,                          memberSelects: 'false',                               goto: Steps.MEMBER_COVERAGE_TYPE},
  {forStep: Steps.PARTNER_TAX_DEPENDENT,                          memberSelects: 'IM_NOT_SURE',                         goto: Steps.TALK_TO_CARROT},
  {forStep: Steps.MEMBER_COVERAGE_TYPE,                           memberSelects: 'MEMBER_ONLY',                         goto: Steps.PARTNER_INELIGIBLE},
  {forStep: Steps.MEMBER_COVERAGE_TYPE,                           memberSelects: 'MEMBER_DEPENDENTS_NOT_PARTNER',       goto: Steps.PARTNER_INELIGIBLE},
  {forStep: Steps.MEMBER_COVERAGE_TYPE,                           memberSelects: 'MEMBER_AND_PARTNER',                  goto: Steps.MEMBER_DEDUCTIBLE_PERIOD_COVERAGE_DATE},
  {forStep: Steps.MEMBER_COVERAGE_TYPE,                           memberSelects: 'MEMBER_PARTNER_DEPENDENTS',           goto: Steps.MEMBER_DEDUCTIBLE_PERIOD_COVERAGE_DATE},
  {forStep: Steps.MEMBER_COVERAGE_TYPE,                           memberSelects: 'IM_NOT_SURE',                         goto: Steps.TALK_TO_CARROT},
  {forStep: Steps.PARTNER_INELIGIBLE,                             memberSelects: '',                                    goto: Steps.MEMBER_DEDUCTIBLE_PERIOD_COVERAGE_DATE},
  {forStep: Steps.MEMBER_DEDUCTIBLE_PERIOD_COVERAGE_DATE,         memberSelects: '',                                    goto: Steps.UPDATE_PLAN_INFO},
  {forStep: Steps.UPDATE_PLAN_INFO,                               memberSelects: '',                                    goto: Steps.PROCESS_MEMBER_ENROLLMENT},
  {forStep: Steps.PROCESS_MEMBER_ENROLLMENT,                      memberSelects: 'CMD_NON_HDHP',                        goto: Steps.MEMBER_ONLY_OR_COMBINED_DEDUCTIBLE_STATUS},
  {forStep: Steps.PROCESS_MEMBER_ENROLLMENT,                      memberSelects: 'ALREADY_ENROLLED',                    goto: Steps.MEMBER_ONLY_OR_COMBINED_DEDUCTIBLE_STATUS},
  {forStep: Steps.MEMBER_ONLY_OR_COMBINED_DEDUCTIBLE_STATUS,      memberSelects: '',                                    goto: Steps.CONNECT_INSURANCE_FLOW },
]
