import React from "react"
import { Button, DialogActions, DialogContentText } from "@carrotfertility/carotene-core"
import { useReimbursementContext } from "components/context/reimbursement/GetReimbursedContext"
import { FormattedMessage } from "react-intl"
import { DialogTitleWithImage, DialogTitleImage } from "@carrotfertility/carotene-core-x"

export function GetReimbursedFinalStep(): JSX.Element {
  const { onExit } = useReimbursementContext()

  return (
    <>
      <DialogTitleWithImage
        image={
          <DialogTitleImage
            src={`/images/illo-coins-and-bills.png`}
            srcSet={`/images/illo-coins-and-bills-2x.png`}
            alt=""
            maxWidth="228px"
            width="100%"
          />
        }
      >
        <FormattedMessage defaultMessage="You're all set" />
      </DialogTitleWithImage>
      <DialogContentText>
        <FormattedMessage defaultMessage="We've received your reimbursement request and will review it as soon as possible. We'll notify you when it's approved or if we need any additional information." />
      </DialogContentText>
      <DialogActions>
        <Button color="primary" onClick={onExit}>
          <FormattedMessage defaultMessage="Close" />
        </Button>
      </DialogActions>
    </>
  )
}
