import React from "react"
import { Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { FormAutocomplete, FormTextField } from "@carrotfertility/carotene-core-x"
import { useUserRole } from "../../partner-access/hooks/usePartnerAccess"
import { UsaStates } from "../../../utils/UsaStateOptions"
import { CountryCodes } from "../../../utils/CountryCode"
import { useCurrentUser } from "../../context/user/UserContext"
import { useWatch } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useIntlSort } from "utils/hooks/useIntlSort"

function UsAddress(): JSX.Element {
  const { isPartnerAccessingAccount } = useUserRole()
  const formValues = useWatch({ name: ["address1", "address2", "city", "zip"] })
  const intl = useIntl()
  const { localeComparator } = useIntlSort()
  const getValueIsRequired =
    formValues.some((val) => !!val) && !isPartnerAccessingAccount
      ? intl.formatMessage({ defaultMessage: "Required" })
      : false
  const StateOptions: { label: string; value: string }[] = UsaStates.map((state) => ({
    label: intl.formatMessage(state.label),
    value: state.id
  })).sort((a, b) => localeComparator(a.label, b.label))

  return (
    <>
      <FormTextField
        name="address1"
        label={intl.formatMessage({ defaultMessage: "Address Line 1" })}
        registerOptions={{ required: getValueIsRequired }}
        InputProps={{ readOnly: isPartnerAccessingAccount }}
      />
      <FormTextField
        name="address2"
        label={intl.formatMessage({ defaultMessage: "Address Line 2" })}
        InputProps={{ readOnly: isPartnerAccessingAccount }}
      />
      <FormTextField
        name="city"
        label={intl.formatMessage({ defaultMessage: "City" })}
        registerOptions={{ required: getValueIsRequired }}
        InputProps={{ readOnly: isPartnerAccessingAccount }}
      />
      <FormAutocomplete
        name="state"
        label={intl.formatMessage({ defaultMessage: "state" })}
        disableClearable
        disabled={isPartnerAccessingAccount}
        controllerProps={{ rules: { required: getValueIsRequired } }}
        options={StateOptions}
      />
      <FormTextField
        name="zip"
        label={intl.formatMessage({ defaultMessage: "Zip Code" })}
        registerOptions={{ required: getValueIsRequired }}
        InputProps={{ readOnly: isPartnerAccessingAccount }}
      />
    </>
  )
}

function InternationalAddress(): JSX.Element {
  const { isPartnerAccessingAccount } = useUserRole()
  const formValues = useWatch({ name: ["internationalAddressLine", "city", "state", "internationalCountry", "zip"] })
  const intl = useIntl()
  const { localeComparator } = useIntlSort()
  const getValueIsRequired =
    formValues.some((val) => !!val) && !isPartnerAccessingAccount
      ? intl.formatMessage({ defaultMessage: "Required" })
      : false
  const CountryOptions: { label: string; value: string }[] = CountryCodes.map((code) => ({
    label: intl.formatDisplayName(code, { type: "region" }),
    value: code
  })).sort((a, b) => localeComparator(a.label, b.label))

  return (
    <>
      <FormTextField
        multiline
        minRows={4}
        name="internationalAddressLine"
        placeholder={intl.formatMessage({ defaultMessage: "Enter house number and street name" })}
        label={intl.formatMessage({ defaultMessage: "Street Address" })}
        registerOptions={{ required: getValueIsRequired }}
        InputProps={{ readOnly: isPartnerAccessingAccount }}
      />
      <FormTextField
        name="city"
        label={intl.formatMessage({ defaultMessage: "City" })}
        registerOptions={{ required: getValueIsRequired }}
        InputProps={{ readOnly: isPartnerAccessingAccount }}
      />
      <FormTextField
        name="state"
        label={intl.formatMessage({ defaultMessage: "State / Province / County / Region" })}
        registerOptions={{ required: getValueIsRequired }}
        InputProps={{ readOnly: isPartnerAccessingAccount }}
      />
      <FormAutocomplete
        name="internationalCountry"
        label={intl.formatMessage({ defaultMessage: "Country" })}
        disabled={isPartnerAccessingAccount}
        controllerProps={{ rules: { required: getValueIsRequired } }}
        options={CountryOptions}
      />
      <FormTextField
        name="zip"
        label={intl.formatMessage({ defaultMessage: "Postal code" })}
        placeholder={intl.formatMessage({ defaultMessage: "Enter postal code" })}
        InputProps={{ readOnly: isPartnerAccessingAccount }}
        helperText={intl.formatMessage({ defaultMessage: "Postal code is only required if your address has one." })}
      />
    </>
  )
}

export function AddressSettings(): JSX.Element {
  const { isUsa } = useCurrentUser()
  const theme = useTheme()
  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
      <Typography variant="h2">
        <FormattedMessage defaultMessage="Address" />
      </Typography>
      {isUsa ? <UsAddress /> : <InternationalAddress />}
    </Stack>
  )
}
