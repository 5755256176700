import Settings from "./CarrotConfig"

declare global {
  interface Window {
    // @ts-expect-error TS(7031) FIXME: Binding element 'string' implicitly has an 'any' t... Remove this comment to see the full error message
    SmartlingContextTracker: { init: ({ orgId: string }) => void }
  }
}

export function initSmartlingVisualContextCapture(): void {
  const orgId = Settings.SMARTLING_ORG_ID
  try {
    window.SmartlingContextTracker.init({
      orgId: orgId
    })
  } catch (error) {
    error.message = "Error initializing Smartling Visual Context Capture " + error.message
    reportError(error)
    return null
  }
}

export function shouldEnableSmartlingVisualContextCapture(): boolean {
  return Settings.SMARTLING_VISUAL_CONTEXT_CAPTURE_ENABLED
}
