export const createIsLoadedReducer = (actionTypeRoot: any) => {
  return (state = false, action: any) => {
    switch (action.type) {
      case `${actionTypeRoot}_SUCCESS`:
        return true
      case `${actionTypeRoot}_FAILURE`:
        return false
      default:
        return state
    }
  }
}

export function createReducer<TReturn>(initialState: any, handlers: any) {
  return (state = initialState, action: any): TReturn | null => {
    // eslint-disable-next-line no-prototype-builtins
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}
