import React from "react"
import { useSelector } from "react-redux"
import { getCarrotLite } from "redux/reducers/companyInfo"
import { FormattedMessage, useIntl } from "react-intl"
import {
  Box,
  CloseIcon,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from "@carrotfertility/carotene-core"

// @ts-expect-error TS7031
export function PartnerAccessInfoModal({ onExit }): JSX.Element {
  const isCarrotLite = useSelector(getCarrotLite)
  const intl = useIntl()

  return (
    <Dialog
      open={true}
      maxWidth="md"
      aria-labelledby="partner-access-info-modal-title"
      aria-describedby="partner-access-info-modal-description"
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton aria-label={intl.formatMessage({ defaultMessage: "Close" })} onClick={onExit}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle id="partner-access-info-modal-title">
        <FormattedMessage defaultMessage="What does it mean to view their account?" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="partner-access-info-modal-description">
          <FormattedMessage
            defaultMessage="When you sign in to the Carrot member’s account with your own email and password, you can access their
              educational resources, benefit details, and more."
          />
        </DialogContentText>
        <DialogContentText>
          {isCarrotLite ? (
            <FormattedMessage defaultMessage="For access to a member-only space, such as Messages, please ask the member to sign in to their account. If you send a message to our Care Team, they will reply to you via email." />
          ) : (
            <FormattedMessage defaultMessage="For access to a member-only space, such as Financial support or Messages, please ask the member to sign in to their account. If you send a message to our Care Team, they will reply to you via email." />
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
