import styled, { css } from "styled-components"
// @ts-ignore
import colors from "css/variables/_colors.scss"

export type SharedInputCssProps = {
  readOnly?: boolean
  valid: boolean
}

const sharedInputCss = css<SharedInputCssProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box;
  border-radius: 6px;
  color: rgba(0, 0, 0, ${(props) => (props.readOnly ? 0.4 : 0.8)});
  cursor: ${(props) => (props.readOnly ? "not-allowed" : "text")};
  line-height: 1.5;
  padding: 14px 0 14px 14px;
  inline-size: 100%;
  background-color: ${(props) => (props.readOnly ? props.theme.disabledBackgroundColor : "white")};
  border: 2px solid ${(props) => (props.valid ? props.theme.borderColor : colors.invalidRed)};
  &:hover,
  &:focus {
    border: 2px solid ${(props) => (props.readOnly ? props.theme.borderColor : colors.carrotOrange)};
    outline: none;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
`

const StyledInput = styled.input`
  ${sharedInputCss};
`

export { StyledInput }
