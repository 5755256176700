import actionTypes from "../actions/actionTypes"
import { createIsLoadedReducer, createReducer } from "./shared"
import { combineReducers } from "redux"
import { RootState } from "./index"

const reducers = {
  isLoaded: createIsLoadedReducer("FETCH_VIRTUAL_CARD_DETAILS"),
  cardDetails: createReducer(null, {
    [actionTypes.FETCH_VIRTUAL_CARD_DETAILS_SUCCESS]: (state: any, action: any) => action.response
  })
}

const cardDetails = combineReducers(reducers)

export const getCardDetails = (state: RootState) => state.cardDetails

export default cardDetails
