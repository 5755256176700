import React from "react"
import { Select, SelectOptionType } from "@carrotfertility/carotene"
import { useStateMachine } from "../../context/stateMachine/StateMachineV2"
import { useReimbursementContext } from "../../context/reimbursement/GetReimbursedContext"
import { Progress } from "../../views/modal/Progress"
import { ArrowSubmitButton, ReimbursementDialogFraming, BackArrow } from "../shared/layouts"
import { IVFCard } from "./modules/IvfCard"
import { DoulaRequirementsCard } from "./modules/DoulaRequirementsCard"
import CarrotRxMedicationCard from "./modules/CarrotRxMedicationCard"
import WhatKindOfJourneyEnums from "../../../utils/WhatKindOfJourneyEnums"
import useBenefit from "../../dynamic-content/hooks/useGetBenefit"
import { JourneySubTypes, JourneyTypes } from "./ExpenseJourneyTypes"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, DialogContentText, DialogTitle } from "@carrotfertility/carotene-core"
import { DialogActionsBar } from "@carrotfertility/carotene-core-x"

export const AboutThisExpenseWhatKindOfJourney = ({
  // @ts-expect-error TS7031
  headerText,
  // @ts-expect-error TS7031
  bodyText,
  // @ts-expect-error TS7031
  selectOptions,
  hasRequirements = false
}): JSX.Element => {
  const { send, back } = useStateMachine()
  const { updateWhatKindOfJourney, updateJourneySubtype, state } = useReimbursementContext()
  const { kindOfJourney, journeySubtype } = state
  const { data: benefit } = useBenefit()

  const journeyVal = kindOfJourney?.value as string
  const journeySubtypeVal = journeySubtype?.value as string
  const intl = useIntl()

  const shouldUseSubtype = [
    JourneyTypes.PREGNANCY_AND_POSTPARTUM_SERVICES,
    JourneyTypes.MENOPAUSE,
    JourneyTypes.LOW_TESTOSTERONE,
    JourneyTypes.GENDER_AFFIRMING_CARE
    // @ts-expect-error TS7053
  ].includes(JourneyTypes[state.expenseRelatedTo.value])

  const isDoulaService = journeySubtypeVal ? journeySubtypeVal.includes("DOULA") : false

  const canSubmit = shouldUseSubtype ? !!journeySubtype : !!kindOfJourney

  // @ts-expect-error TS7006
  const setDefault = (value) => {
    value = value?.label ? value : ""
    return value as SelectOptionType
  }

  // @ts-expect-error TS7006
  const handleSelectionChange = (selectedTypeOfCare): void => {
    if (shouldUseSubtype) {
      updateJourneySubtype(selectedTypeOfCare)
    } else {
      updateWhatKindOfJourney(selectedTypeOfCare)
    }
  }

  const handleContinueClick = (): void => {
    if (
      [
        JourneySubTypes.MEDICATION,
        JourneySubTypes.IN_PERSON_CARE,
        JourneySubTypes.ONLINE_CARE,
        JourneySubTypes.NUTRITION_COUNSELING,
        JourneySubTypes.TEMPERATURE_REGULATION_PRODUCTS,
        JourneySubTypes.LABS,
        JourneySubTypes.TRAVEL_ELIGIBLE_PROVIDER,
        JourneySubTypes.MEDICAL_APPOINTMENTS
        // @ts-expect-error TS7053
      ].includes(JourneySubTypes[journeySubtype?.value])
    ) {
      send("ATTESTATION_MEDICALLY_NECESSARY_AND_ELIGIBLE")
      // @ts-expect-error TS7053
    } else if ([JourneySubTypes.BINDERS_PACKERS_BODY_SHAPING].includes(JourneySubTypes[journeySubtype?.value])) {
      send("ATTESTATION_MEDICALLY_RELATED")
    } else {
      send("")
    }
  }

  const getModule = (): JSX.Element => {
    if (hasRequirements && journeyVal === WhatKindOfJourneyEnums.IVF) return <IVFCard />

    if (isDoulaService) return <DoulaRequirementsCard />

    if (journeySubtype?.value === JourneySubTypes.MEDICATION && benefit.carrotRx.isCarrotRxRequired) {
      return <CarrotRxMedicationCard />
    }

    return null
  }

  return (
    <>
      <Progress
        numSteps={3}
        step={2}
        label={intl.formatMessage({ defaultMessage: "About This Expense" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={back} />
      <ReimbursementDialogFraming>
        <DialogTitle id="step-heading">{headerText}</DialogTitle>
        <Box sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(theme.tokens.spacing.lg) }}>
          <DialogContentText>{bodyText}</DialogContentText>
          <Select
            aria-labelledby="step-heading"
            isSearchable={false}
            defaultValue={shouldUseSubtype ? setDefault(journeySubtype) : setDefault(kindOfJourney)}
            onChange={handleSelectionChange}
            options={selectOptions}
            placeholder={intl.formatMessage({ defaultMessage: "Select option" })}
          />
          {getModule()}
        </Box>
      </ReimbursementDialogFraming>
      <DialogActionsBar
        primaryAction={
          <ArrowSubmitButton enabled={canSubmit} onClick={handleContinueClick}>
            <FormattedMessage defaultMessage="Continue" />
          </ArrowSubmitButton>
        }
      />
    </>
  )
}
