import actionTypes from "../actions/actionTypes"
import { createIsLoadedReducer, createReducer } from "../reducers/shared"
import { combineReducers } from "redux"

const reducers = {}

// @ts-expect-error TS(2339): Property 'isLoaded' does not exist on type '{}'.
reducers.isLoaded = createIsLoadedReducer("FETCH_AVA")

// @ts-expect-error TS(2339): Property 'landingPageUrl' does not exist on type '... Remove this comment to see the full error message
reducers.landingPageUrl = createReducer(null, {
  [actionTypes.FETCH_AVA_CHECKOUT_SUCCESS]: (state: any, action: any) => action.response
})

// @ts-expect-error TS(2339): Property 'status' does not exist on type '{}'.
reducers.status = createReducer("IDLE", {
  [actionTypes.FETCH_AVA_CHECKOUT_REQUEST]: () => "FETCHING",
  [actionTypes.FETCH_AVA_CHECKOUT_SUCCESS]: () => "COMPLETED",
  [actionTypes.FETCH_AVA_CHECKOUT_FAILURE]: () => "FAILED"
})

const avaCheckout = combineReducers(reducers)

export default avaCheckout
