import React, { useContext, useReducer } from "react"
import { CarrotErrorCodes } from "utils/CarrotErrors"
import { reportError } from "utils/ErrorReporting"
import { isHttpError } from "utils/HttpErrors"
import { useSelector } from "react-redux"
import { loginEmail } from "redux/reducers/loginInfo"
import { useSignUpFlow } from "../../context/signup/SignUpFlowContext"
import { useHistory } from "react-router"
import { useIntl } from "react-intl"
import { trackMatomoPageView } from "utils/matomo"
import { useTrackExperimentEvent } from "../../views/register/hooks/useExperiments"
import { carrotClient } from "utils/CarrotClient"

interface SignUpReducerState {
  username: string
  errorMessage: string
  inputsEnabled: boolean
  showSubmittingLabel: boolean
  success: boolean
  shake: boolean
}

interface SignUpProviderProps extends SignUpReducerState {
  onSubmit: ({ email }: { email: any }) => Promise<void>
}

const SignUpContext = React.createContext<SignUpProviderProps>(null)

export const useSignUp = () => useContext(SignUpContext)

const actionTypes = {
  error: "ERROR",
  start: "REQUEST_START",
  success: "REQUEST_SUCCESS"
}

// @ts-expect-error TS7006
function signUpReducer(state, action): SignUpReducerState {
  switch (action.type) {
    case actionTypes.error: {
      return {
        ...state,
        inputsEnabled: true,
        showSubmittingLabel: false,
        shake: true,
        errorMessage: action.payload.errorCode
      }
    }
    case actionTypes.start: {
      return {
        ...state,
        inputsEnabled: true,
        showSubmittingLabel: true,
        shake: false
      }
    }
    case actionTypes.success: {
      return {
        ...state,
        inputsEnabled: false,
        showSubmittingLabel: false,
        success: true
      }
    }
    default: {
      throw new Error(`Unhandled type: ${action.type}`)
    }
  }
}

// @ts-expect-error TS7031
export function SignUpProvider({ children }) {
  const username = useSelector(loginEmail)
  const [state, dispatch] = useReducer(signUpReducer, {
    username,
    errorMessage: null,
    inputsEnabled: true,
    showSubmittingLabel: false,
    success: false,
    shake: false
  })
  const { handleSaveMemberInfoInMemory, lookupAccountByEmail } = useSignUpFlow()
  const history = useHistory()
  const intl = useIntl()
  const { locale } = intl
  const { trackExperimentEvent } = useTrackExperimentEvent()

  // @ts-expect-error TS7006
  const setFormError = (errorCode) => dispatch({ type: actionTypes.error, payload: { errorCode } })
  const startRequest = () => dispatch({ type: actionTypes.start })
  const endSuccessfulRequest = () => dispatch({ type: actionTypes.success })

  // @ts-expect-error TS7006
  const redirectToNameDobSignUp = (email): void => {
    history.push(`/signup-find-account?email=${encodeURIComponent(email)}`)
  }

  // @ts-expect-error TS7006
  const setErrorState = (errorCode): void => {
    setFormError(errorCode)
  }

  // @ts-expect-error TS7031
  const onSubmit = async ({ email }) => {
    trackExperimentEvent("sign-up-continue")
    startRequest()

    let shouldSignUp = true
    try {
      const lookupResponseStatus = await lookupAccountByEmail(email)
      if (lookupResponseStatus === undefined) {
        shouldSignUp = false
        return
      }

      if (lookupResponseStatus != null) {
        setFormError(lookupResponseStatus)
        shouldSignUp = false
      }

      shouldSignUp && (await carrotClient.signUp(email, locale))
    } catch (error) {
      if (isHttpError(error)) {
        const { code } = await error.response.json()
        if (code === CarrotErrorCodes.EMPLOYEE_NOT_FOUND) {
          handleSaveMemberInfoInMemory({ email })
          redirectToNameDobSignUp(email)
          return
        }

        setErrorState(code)
        return
      }

      reportError(error)
      setErrorState(CarrotErrorCodes.UNEXPECTED_ERROR)
      return
    }
    if (shouldSignUp) {
      endSuccessfulRequest()
      const signupSuccessHref = `/signup-success?email=${encodeURIComponent(email)}`
      history.push(signupSuccessHref)
      trackMatomoPageView(signupSuccessHref)
    }
  }

  return <SignUpContext.Provider value={{ ...state, onSubmit }}>{children}</SignUpContext.Provider>
}
