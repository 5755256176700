import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import React from "react"
import { FormattedMessage } from "react-intl"
import { DbgPaths, TtcPaths } from "../../../utils/Paths"
import { Link, useTheme, InternalIcon, DialogContentText, Box } from "@carrotfertility/carotene-core"
import { ArrowSubmitButton } from "../shared/layouts"
import { DialogActionsBar, DialogTitleImage, DialogTitleWithImage } from "@carrotfertility/carotene-core-x"

export const BeforeYouContinueWarning = (): JSX.Element => {
  const { send } = useStateMachine()
  const theme = useTheme()

  const handleContinueClick = (): void => {
    send("")
  }

  return (
    <>
      <DialogTitleWithImage
        image={<DialogTitleImage src={`/images/round-error-sign.png`} alt="" maxWidth="196px" width="100%" />}
      >
        <FormattedMessage defaultMessage="Before you continue" />
      </DialogTitleWithImage>
      <DialogContentText sx={{ marginBlockEnd: (theme) => theme.tokens.spacing.md }}>
        <FormattedMessage
          defaultMessage={
            "Please check three things to help us process your expense as " +
            "quickly as possible. If you have any questions, <link>contact us</link>."
          }
          values={{
            link: (linkContent) => (
              <Link href={TtcPaths.TTC_CARE_NAV} target={"_blank"} color="inherit">
                {linkContent}
              </Link>
            )
          }}
        />
      </DialogContentText>
      <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(theme.tokens.spacing.sm) }}>
        <Link
          endIcon={<InternalIcon fontSize="small" color="primary" />}
          href={DbgPaths.CARROT_ELIGIBLE}
          target="_blank"
        >
          <FormattedMessage defaultMessage="Make sure your expense is covered" />
        </Link>
        <Link
          endIcon={<InternalIcon fontSize="small" color="primary" />}
          href={DbgPaths.PROVIDER_ELIGIBILITY}
          target="_blank"
        >
          <FormattedMessage defaultMessage="Confirm that your provider is eligible" />
        </Link>
        <Link endIcon={<InternalIcon fontSize="small" color="primary" />} href={DbgPaths.REQUIRED_DOCS} target="_blank">
          <FormattedMessage defaultMessage="Review the required documents you need to upload" />
        </Link>
      </Box>
      <DialogActionsBar
        primaryAction={
          <ArrowSubmitButton onClick={handleContinueClick}>
            <FormattedMessage defaultMessage="Continue" />
          </ArrowSubmitButton>
        }
      />
    </>
  )
}
