import React from "react"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import styled from "styled-components"

export const CenteredLoadingIndicator = ({ id = "centered-loading-indicator", ...otherProps }) => (
  <div {...otherProps} className="flex items-center justify-center flex-auto">
    <div className="lds-ellipsis" id={id} data-testid={"centered-loading-indicator"}>
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
)

export const StylelessButton = styled.button`
  cursor: pointer;
  padding: 0;
  border: 0;
  background-color: transparent;
  &:focus {
    outline: none;
  }
`

export const FadeTransition = ({ children }) => (
  <TransitionGroup>
    <CSSTransition timeout={{ exit: 200, enter: 600, appear: 600 }} classNames="fade" appear>
      {children}
    </CSSTransition>
  </TransitionGroup>
)
