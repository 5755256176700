import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form } from "@carrotfertility/carotene-core-x"
import useAboutYou from "../../hooks/useAboutYou"
import { useGetSexOptions } from "../../../../services/common-forms/SexOptions"
import { useToggle } from "../../../../utils/Hooks"
import { FeatureGate } from "../../../../utils/FeatureGate"
import { ButtonFooterLayout, ContinueButton, SkipButton } from "components/carrot-plans/shared/ButtonFooter"
import { FormattedMessage, useIntl } from "react-intl"
import { RoutingFlowTitle, RoutingFlowDialog, RoutingFlowModalIcon, StepLayout } from "components/carrot-plans/shared"
import { FormRadioButtonGroup } from "services/common-forms"

export default function AboutYourPartnerSexStep(): JSX.Element {
  const intl = useIntl()
  const { data, updateAboutYou, hasQuestionBeenSkipped } = useAboutYou()
  const { send } = useStateMachine(data?.partnerSex || hasQuestionBeenSkipped("partnerSex") ? "" : null)
  async function onSkip(): Promise<void> {
    await updateAboutYou({ partnerSex: "skip" })
    send("")
  }

  async function onClickContinue({ partnerSex }: { partnerSex?: string }): Promise<void> {
    if (partnerSex) {
      await updateAboutYou({ partnerSex })
      send("")
    } else {
      await onSkip()
    }
  }
  const [showPartnerSexModal, togglePartnerSexModal] = useToggle(false)
  const sexOptions = useGetSexOptions()
  const header = intl.formatMessage({
    defaultMessage: "What’s your partner’s sex?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="partnerSex-label">{header}</span>}
        iconButton={
          <FeatureGate filter={(c) => c.isLgtbqEnabled}>
            <RoutingFlowModalIcon
              onClick={togglePartnerSexModal}
              aria-label={intl.formatMessage({ defaultMessage: "Why do you need to know their sex?" })}
            />
          </FeatureGate>
        }
        formElements={
          <Form defaultValues={{ partnerSex: data?.partnerSex }} onSubmit={onClickContinue}>
            <FormRadioButtonGroup name="partnerSex" options={sexOptions} aria-labelledby="partnerSex-label" />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
      <RoutingFlowDialog
        open={showPartnerSexModal}
        onClose={togglePartnerSexModal}
        title={intl.formatMessage({ defaultMessage: "Why do you need to know their sex?" })}
        description={intl.formatMessage({
          defaultMessage:
            "Also known as assigned sex at birth, this is the sex that appeared on their original birth certificate. This information helps us provide guidance and suggest relevant resources, and is never shared with your benefit sponsor or theirs."
        })}
      />
    </>
  )
}
