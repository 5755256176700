import React, { useContext, useState } from "react"
import { reportError } from "utils/ErrorReporting"
import { getHeap } from "../../../utils/heap"

type StepValue = null | string | boolean | number
type Step = {
  name: string
  view: string | React.FunctionComponent<any> | React.ComponentClass<any, any>
  decisionStep?: boolean
}

export interface StateMachineContextValues {
  stateMachineName: string
  hasTraversedBack: boolean
  setHasTraversedBack: (hasTraversedBack: boolean) => void
  setNextStep: (nextStep: string, value: StepValue) => void
  goBackToSpecificStep: (previousStep: string) => void
  gotoPreviousStep: () => void
  goBackTwoSteps: () => void
  previousStep: Step
  step: Step
}

interface StateMachineProviderProps {
  workflows: Array<WorkflowDefinition>
  steps: Array<Step>
  initialStep: string
  stateMachineName: string
  workflowName: string
  handleWorkflowComplete: () => void
  children: React.ReactNode
}

type Workflow = Array<WorkflowStep>

interface WorkflowStep {
  forStep: string
  memberSelects: string
  goto: string
}

interface WorkflowDefinition {
  name: string
  workflow: Workflow
}

const StateMachineContext = React.createContext<StateMachineContextValues>(null)

export const useStateMachine = (currentStepName?: string, initialValue?: StepValue) => {
  const { stateMachineName, setNextStep, hasTraversedBack, ...otherContext } = useContext(StateMachineContext)

  const initializeStep = (currentStepName: string, currentValue: StepValue) => {
    if (currentValue != null && !hasTraversedBack) {
      setNextStep(currentStepName, currentValue)
      return
    }

    getHeap().track(currentStepName, { EventName: stateMachineName, CurrentStep: currentStepName })
  }

  useState(() => {
    if (currentStepName) {
      initializeStep(currentStepName, initialValue)
    }
  })

  return {
    ...otherContext,
    setNextStep,
    hasTraversedBack
  }
}

export function StateMachineProvider({
  workflows,
  workflowName,
  steps,
  initialStep,
  stateMachineName,
  handleWorkflowComplete,
  children
}: StateMachineProviderProps) {
  // Holds the current step in the flow. Not to be used directly. Use the setNextStep and gotoPreviousStep callbacks
  // for this purpose

  function getViewForStep(step: string) {
    return steps.find((view) => view.name === step).view
  }

  function getIfDecisionStep(step: string) {
    return steps.find((view) => view.name === step).decisionStep
  }

  function InitStep(stepName: string): Step {
    return { view: getViewForStep(stepName), name: stepName, decisionStep: getIfDecisionStep(stepName) }
  }

  const [step, setStep] = useState(InitStep(initialStep))

  // Stack essentially representing breadcrumbs for where we've been, which drives our back traversal functionality
  const [prevStepStack, setPrevStepStack] = useState([])

  // Each step in the wizard checks to see if it already has a value for itself, and if it does it moves to the next
  // step in the wizard. This allows us to trivially send the member to step that they left off on. However, when a
  // member hits the back button, we no longer want this functionality. We'd like them to be able to traverse through
  // the steps even when there is a value set. This state is set once when they go back, and will retain true from
  // that point forward.
  const [hasTraversedBack, setHasTraversedBack] = useState(false)

  function NextStep(workflowName: string, currentStep: string, formValue: StepValue): Step {
    const workflow = workflows.find((wf) => wf.name === workflowName)
    const step = workflow.workflow.find(
      (step) => step.forStep === currentStep && step.memberSelects === formValue?.toString()
    )
    if (typeof step === "undefined") {
      reportError(new Error("State Machine sent to unexpected step"), {
        workflow: workflowName,
        currentStep,
        currentStepValue: formValue?.toString()
      })
      return { view: "", name: "", decisionStep: null }
    }
    const view = getViewForStep(step.goto)
    return { view, name: step.goto, decisionStep: getIfDecisionStep(step.goto) }
  }

  function setNextStep(name: string, value: StepValue) {
    setPrevStepStack([step, ...prevStepStack])
    const nextStep = NextStep(workflowName, name, value)

    if (nextStep.view === null) {
      handleWorkflowComplete()
      return
    }
    setStep(nextStep)
  }
  const goBackTwoSteps = () => {
    setHasTraversedBack(true)
    let prevStep = prevStepStack.shift()
    prevStep = prevStepStack.shift()
    setPrevStepStack([...prevStepStack])
    setStep(prevStep)
  }

  const goBackToSpecificStep = (stepToStopAt: string) => {
    setHasTraversedBack(true)
    let prevStep: Step = { view: () => null, name: "" }
    while (stepToStopAt !== prevStep.name) {
      prevStep = prevStepStack.shift()
    }
    setPrevStepStack([...prevStepStack])
    setStep(prevStep)
  }

  const gotoPreviousStep = () => {
    setHasTraversedBack(true)
    const noDecisionStepsStack = prevStepStack.filter((step) => !step.decisionStep)
    const prevStep = noDecisionStepsStack.shift()
    setPrevStepStack([...noDecisionStepsStack])
    setStep(prevStep)
  }

  const previousStep = prevStepStack[0]

  return (
    <StateMachineContext.Provider
      value={{
        stateMachineName,
        hasTraversedBack,
        setHasTraversedBack,
        setNextStep,
        goBackToSpecificStep,
        gotoPreviousStep,
        goBackTwoSteps,
        previousStep,
        step
      }}
    >
      {children}
    </StateMachineContext.Provider>
  )
}
