export enum Steps {
  INTRO_INFORMATIONAL = "Intro Informational",
  ARE_YOU_ENROLLED_IN_MEDICARE = "AreYouEnrolledInMedicare",
  MEDICARE_ID = "MedicareId",
  MEMBER_PLAN_SOURCE = "MemberPlanSource",
  MEMBER_COVERAGE_TYPE = "MemberCoverageType",
  MEMBER_HDHP_INDIVIDUAL = "MemberHdhp_INDIVIDUAL",
  MEMBER_HDHP_FAMILY = "MemberHdhp_FAMILY",
  MEMBER_INDIVIDUAL_HSA_CONTRIBUTION = "MemberIndividualHsaContributionStatus",
  MEMBER_FAMILY_HSA_CONTRIBUTION = "MemberFamilyHsaContributionStatus",
  MEMBER_HAS_LEGAL_PARTNER = "MemberHasLegalPartner",
  PARTNER_PLAN_SOURCE = "PartnerPlanSource",
  PARTNER_PLAN_SOURCE_ACA = "PartnerPlanSourceACA",
  PARTNER_COVERAGE_TYPE = "PartnerCoverageType",
  PARTNER_HDHP = "PartnerHdhp",
  PARTNER_HSA_CONTRIBUTION = "PartnerHsaContributionStatus",
  PEEK_BENEFIT_ENROLLMENT = "PeekBenefitEnrollment",
  PROCESS_MEMBER_ENROLLMENT = "ProcessMemberEnrollment",
  TALK_TO_CARROT = "TalkToCarrot",
  MEMBER_INFO_CONNECT_INSURANCE = "MemberInformationalConnectInsurance",
  PARTNER_INFO_CONNECT_INSURANCE = "PartnerInformationalConnectInsurance",
  SKIP_MEMBER_CONNECT_INSURANCE = "SkipMemberConnectInsurance",
  SKIP_PARTNER_CONNECT_INSURANCE = "SkipPartnerConnectInsurance",
  MEMBER_HEALTH_PLAN_CONNECT = "MemberHealthPlanConnect",
  MEMBER_DOB_SEX_CONNECT = "MemberDobSexConnect",
  PARTNER_HEALTH_PLAN_CONNECT = "PartnerHealthPlanConnect",
  PARTNER_HEALTH_PLAN_NOT_LISTED = "PartnerHealthPlanNotListed",
  MEMBER_HEALTH_PLAN_NOT_LISTED = "MemberHealthPlanNotListed",
  PARTNER_SHOULD_SHOW_COLLECT_PLAN_COVERAGE = "PartnerShouldShowCollectPlanCoverage",
  PARTNER_FIRST_LAST_NAME_CONNECT = "PartnerFirstLastNameConnect",
  PARTNER_DOB_SEX_CONNECT = "PartnerDobSexConnect",
  PARTNER_PROCESS_CONNECT_INSURANCE = "PartnerProcessConnectInsurance",
  PARTNER_PROCESS_CONNECT_INSURANCE_SUCCESS = "PartnerProcessConnectInsuranceSuccess",
  PARTNER_PROCESS_CONNECT_INSURANCE_FAIL = "PartnerProcessConnectInsuranceFail",
  PARTNER_SHOULD_SHOW_INSURANCE = "PartnerShouldShowInsurance",
  MEMBER_SHOULD_SHOW_INSURANCE = "MemberShouldShowInsurance",
  MEMBER_PROCESS_CONNECT_INSURANCE = "MemberProcessConnectInsurance",
  MEMBER_PROCESS_CONNECT_INSURANCE_SUCCESS = "MemberProcessConnectInsuranceSuccess",
  MEMBER_PROCESS_CONNECT_INSURANCE_FAIL = "MemberProcessConnectInsuranceFailure",
  MEMBER_PROCESS_CONNECT_INSURANCE_KNOWN_OUTAGE = "MemberProcessConnectInsuranceKnownOutage",
  CONNECT_INSURANCE_NOT_REQUIRED = "ConnectInsuranceNotRequired",
  FINAL = "FinalConnectInsurance",
  FINAL_NO_DEDUCTIBLE = "FinalNoDeductible",
  MEMBER_DEDUCTIBLE_PERIOD_COVERAGE_DATE = "MemberDeductiblePeriodCoverageDate",
  PARTNER_DEDUCTIBLE_PERIOD_COVERAGE_DATE = "PartnerDeductiblePeriodCoverageDate",
  ARE_YOU_LEGALLY_MARRIED = "AreYouLegallyMarried",
  PARTNER_TAX_DEPENDENT = "PartnerTaxDependent",
  MEMBER_ONLY_OR_COMBINED_DEDUCTIBLE_STATUS = "MemberOnlyOrCombinedDeductibleStatus",
  MEMBER_PARTNER_DEDUCTIBLE_STATUS = "MemberPartnerDeductibleStatus",
  PARTNER_ONLY_DEDUCTIBLE_STATUS = "PartnerOnlyDeductibleStatus",
  UNKNOWN_ERROR = "UnknownError",
  CONNECT_INSURANCE_FLOW = "ConnectInsuranceFlow",
  RE_ENROLL_INTRO_INFORMATIONAL = "ReEnrollIntroInsuranceFlow",
  INSURANCE_DISCLAIMER = "InsuranceDisclaimer",
  PROCESS_ENROLLMENT_TYPE = "ProcessEnrollmentType",
  FIRST_TIME_OR_RE_ENROLL = "FirstTimeOrReEnroll",
  HRA_INELIGIBLE = "HraIneligible",
  MEMBER_INELIGIBLE = "MemberIneligible",
  PARTNER_INELIGIBLE = "PartnerIneligible",
  UPDATE_PLAN_INFO = "UpdatePlanInfo"
}
