enum Paths {
  YOUR_BENEFIT_GUIDE = "/your-benefit-guide",
  TALK_TO_CARROT = "/talk-to-carrot",
  REFRESH_CARROT_PLAN = "/talk-to-carrot/refresh-carrot-plan",
  PROVIDER_FINDER = "/providerfinder",
  READ = "/read",
  HOME = "/"
}

enum TtcPaths {
  TTC_CARE_NAV = "/talk-to-carrot/care-navigation",
  TTC_LOCATION = "/talk-to-carrot/location"
}

enum DbgPaths {
  CARROT_ELIGIBLE = "/your-benefit-guide/coverage",
  PAYING_FOR_CARE = "/your-benefit-guide/paying-for-care",
  CARROT_FUNDS = "/your-benefit-guide/carrot-funds",
  FERTILITY_COVERAGE = "/your-benefit-guide/coverage/fertility-care",
  ADOPTION_COVERAGE = "/your-benefit-guide/coverage/adoption",
  PRESERVATION_COVERAGE = "/your-benefit-guide/coverage/fertility-preservation",
  GESTATIONAL_SURROGACY_COVERAGE = "/your-benefit-guide/coverage/gestational-surrogacy",
  PREGNANCY_COVERAGE = "/your-benefit-guide/coverage/pregnancy-and-postpartum",
  CARROT_RX = "/your-benefit-guide/coverage/carrot-rx",
  PARENTING_COVERAGE = "/your-benefit-guide/coverage/parenting",
  EMPLOYER_SPECIFIC_COVERAGE = "/your-benefit-guide/coverage/employer-specific-information",
  HEALTH_PLAN_COVERAGE = "/your-benefit-guide/coverage/health-plan-information",
  ADDITIONAL_BENEFITS_COVERAGE = "/your-benefit-guide/coverage/additional-employer-benefits",
  PHARMACY = "/your-benefit-guide/pharmacy",
  AVA_BRACELET = "/your-benefit-guide/coverage/ava-bracelet",
  OVULATION_TRACKING = "/your-benefit-guide/additional-at-home-care/ovulation-tracking",
  LEGACY = "/your-benefit-guide/coverage/legacy",
  SPERM_HEALTH = "/your-benefit-guide/additional-at-home-care/sperm-health",
  IMAWARE_FEMALE_FERTILITY_WELLNESS_TEST = "/your-benefit-guide/coverage/imaware-female-fertility-and-wellness-test",
  OVARIAN_RESERVE_WELLNESS_TESTING = "/your-benefit-guide/additional-at-home-care/ovarian-reserve-and-wellness-testing",
  MENOPAUSE = "/your-benefit-guide/coverage/menopause",
  LOW_TESTOSTERONE = "/your-benefit-guide/coverage/low-testosterone",
  GENDER_AFFIRMING_CARE = "/your-benefit-guide/coverage/gender-affirming-care",
  PROVIDER_ELIGIBILITY = "/your-benefit-guide/provider-eligibility",
  REQUIRED_DOCS = "/your-benefit-guide/carrot-funds/required-documents-for-expenses",
  TAXES = "/your-benefit-guide/carrot-funds/taxes",
  QUALIFIED_MEDICAL_EXPENSES = "/your-benefit-guide/carrot-funds/qualified-medical-expenses",
  CARROT_CARD = "/your-benefit-guide/carrot-funds/carrot-card"
}

enum CarrotPlansPaths {
  ENROLLMENT = "/carrot-plans/enrollment",
  JOURNEY_CHANGE = "/carrot-plans/journey-change/routing",
  CASE_RATE_RENEWAL = "/carrot-plans/case-rate-renewal/routing"
}

enum QueryStrings {
  JOURNEY_CHANGE_EMAIL = "journey-change-email-link"
}

/**
 * These redirects are to handle URLs updated during the 2022Q4 DBG revamp
 * see https://carrotfertility.atlassian.net/browse/HS-465
 */
export const DBGRevamp2022Q4Redirects = [
  { from: DbgPaths.PAYING_FOR_CARE, to: DbgPaths.CARROT_FUNDS },
  { from: DbgPaths.CARROT_RX, to: DbgPaths.PHARMACY },
  { from: DbgPaths.AVA_BRACELET, to: DbgPaths.OVULATION_TRACKING },
  { from: DbgPaths.LEGACY, to: DbgPaths.SPERM_HEALTH },
  { from: DbgPaths.IMAWARE_FEMALE_FERTILITY_WELLNESS_TEST, to: DbgPaths.OVARIAN_RESERVE_WELLNESS_TESTING }
] as const

/**
 * These redirects handles the renaming of the Pregnancy Carrot Academy category
 * see https://carrotfertility.atlassian.net/browse/HS-465
 */
export const ReadAndLearnPregnancyRenameRedirects = [
  {
    from: "/read/Pregnancy/guide/:path",
    to: "/read/Pregnancy-and-postpartum/guide/:path"
  },
  {
    from: "/read/Pregnancy/group/:path",
    to: "/read/Pregnancy-and-postpartum/group/:path"
  },
  {
    from: "/read/Pregnancy/:path",
    to: "/read/Pregnancy-and-postpartum/:path"
  },
  {
    from: "/read/Pregnancy/",
    to: "/read/Pregnancy-and-postpartum/"
  }
] as const

export const ReadAndLearnGestationalSurrogacyRenameRedirects = [
  {
    from: "/read/Gestational-carriers-and-donor-assistance/:path",
    to: "/read/Gestational-surrogacy-and-donor-assistance/:path"
  },
  {
    from: "/read/Gestational-carriers-and-donor-assistance/",
    to: "/read/Gestational-surrogacy-and-donor-assistance/"
  }
] as const

/**
 * These redirects handle the addition of donor assistance to fertility
 * care and gestational surrogacy content pages as part of journey modularity
 * phase 3
 * see https://carrotfertility.atlassian.net/browse/HS-902
 */
export const DBGJourneyModularityPhase3Redirects = [
  {
    from: "/your-benefit-guide/coverage/donor-assistance",
    to: "/your-benefit-guide/coverage/fertility-care"
  },
  {
    from: "/your-benefit-guide/support/donor-assistance",
    to: "/your-benefit-guide/support/fertility-care"
  }
] as const

export const SplitParentingFromPregnancyArticle = [
  {
    from: "/read/Pregnancy-and-postpartum/caring-for-a-newborn",
    to: "/read/Newborn-care-and-parent-support/caring-for-a-newborn"
  }
] as const

export const SplitParentingFromPregnancyGroup = [
  {
    from: "/read/Pregnancy-and-postpartum/group/understanding-infant-feeding",
    to: "/read/Newborn-care-and-parent-support/group/understanding-infant-feeding"
  }
] as const

export const NewbornToParentingRedirects = [
  {
    from: "/read/Newborn-care-and-parent-support/guide/:guide",
    to: "/read/Parenting/guide/:guide"
  },
  {
    from: "/read/Newborn-care-and-parent-support/group/:group",
    to: "/read/Parenting/group/:group"
  },
  {
    from: "/read/Newborn-care-and-parent-support/:category",
    to: "/read/Parenting/:category"
  }
] as const

/**
 * These redirects are to handle URLs updated to consolidate PRO and CORE DBG links to all use "coverage"
 * see https://carrotfertility.atlassian.net/browse/WQ-2403
 */
export const SupportToCoverageRedirects = [
  { from: "/your-benefit-guide/support/fertility-preservation", to: DbgPaths.PRESERVATION_COVERAGE },
  { from: "/your-benefit-guide/support/fertility-care", to: DbgPaths.FERTILITY_COVERAGE },
  { from: "/your-benefit-guide/support/gestational-surrogacy", to: DbgPaths.GESTATIONAL_SURROGACY_COVERAGE },
  { from: "/your-benefit-guide/support/adoption", to: DbgPaths.ADOPTION_COVERAGE },
  { from: "/your-benefit-guide/support/pregnancy-and-postpartum", to: DbgPaths.PREGNANCY_COVERAGE },
  { from: "/your-benefit-guide/support/parenting", to: DbgPaths.PARENTING_COVERAGE },
  { from: "/your-benefit-guide/support/menopause", to: DbgPaths.MENOPAUSE },
  { from: "/your-benefit-guide/support/low-testosterone", to: DbgPaths.LOW_TESTOSTERONE },
  { from: "/your-benefit-guide/support/gender-affirming-care", to: DbgPaths.GENDER_AFFIRMING_CARE },
  { from: "/your-benefit-guide/support/employer-specific-information", to: DbgPaths.EMPLOYER_SPECIFIC_COVERAGE },
  { from: "/your-benefit-guide/support/health-plan-information", to: DbgPaths.HEALTH_PLAN_COVERAGE },
  { from: "/your-benefit-guide/support/additional-employer-benefits", to: DbgPaths.ADDITIONAL_BENEFITS_COVERAGE },
  { from: "/your-benefit-guide/support", to: DbgPaths.CARROT_ELIGIBLE }
] as const

export { Paths, DbgPaths, TtcPaths, CarrotPlansPaths, QueryStrings }
