import { useSelector } from "react-redux"
import { getBenefitConfiguration } from "redux/reducers/benefitConfiguration"
import React, { FC } from "react"
import { FeatureConfiguration } from "types/benefitConfiguration"

type FeatureGateProps = {
  filter: (config: FeatureConfiguration) => boolean
  children?: React.ReactNode
}

export const FeatureGate: FC<FeatureGateProps> = ({ children, filter }) => {
  const { featureConfig } = useSelector(getBenefitConfiguration)

  return featureConfig && filter(featureConfig) ? <>{children}</> : null
}
