import { useQuery, UseQueryResult } from "@tanstack/react-query"
import ResponseError from "../../../types/responseError"
import { ReimbursementDetails } from "../types/ReimbursementDetails"
import { HttpErrors } from "utils/HttpErrors"
import { useAuthentication } from "components/context/authentication/AuthenticationProvider"
import { carrotClient } from "utils/CarrotClient"

const ENDPOINT = "GetReimbursementDetails"

const loadDetails = async (handleSessionExpiration: () => void): Promise<ReimbursementDetails> => {
  //Because the client returns a new class object react-query sees every request to the BE as a data change
  //to avoid infinite re-rendering and reloading we need to strip the data out of the class wrapper
  try {
    const details = await carrotClient.getReimbursementDetails()
    return { ...details }
  } catch (error) {
    if (error.name === HttpErrors.UNAUTHORIZED) {
      handleSessionExpiration()
    }
    return null
  }
}

const getReimbursementDetailsCacheKey = (): (string | number)[] => [ENDPOINT]

export default function useGetReimbursementDetails(
  shouldRefetchOnWindowFocus = true
): UseQueryResult<ReimbursementDetails, ResponseError> {
  const { handleSessionExpiration } = useAuthentication()
  return useQuery<ReimbursementDetails, ResponseError>(
    getReimbursementDetailsCacheKey(),
    () => loadDetails(handleSessionExpiration),
    {
      refetchOnWindowFocus: shouldRefetchOnWindowFocus
    }
  )
}
