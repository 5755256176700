import { combineReducers } from "redux"
import insuranceSyncInfo, { reimbursementDetails } from "./reimbursementDetails"
import benefitConfiguration from "./benefitConfiguration"
import application from "./application"
import subsidies from "./subsidies"
import companyInfo from "./companyInfo"
import userInfo from "./userInfo"
import deductibleStatus from "./deductibleStatus"
import reimbursements from "./reimbursements"
import avaCheckout from "./ava"
import cardDetails from "./cardDetails"
import loginInfo from "./loginInfo"
import actionTypes from "redux/actions/actionTypes"

const rootReducer = combineReducers({
  application,
  companyInfo,
  subsidies,
  userInfo,
  reimbursements,
  deductibleStatus,
  avaCheckout,
  cardDetails,
  loginInfo,
  insuranceSyncInfo,
  reimbursementDetails,
  benefitConfiguration
})

export type RootState = ReturnType<typeof rootReducer>

// this is used to reset the redux state on signOut
// more info here: https://netbasal.com/how-to-secure-your-users-data-after-logout-in-redux-30468c6848e8
const rootReducerWithSignOut = (state: any, action: any): RootState => {
  if (action.type === actionTypes.FETCH_SIGN_OUT_SUCCESS) {
    state = undefined
  }

  return rootReducer(state, action)
}

export default rootReducerWithSignOut
