import { StatusCodes } from "http-status-codes"
// @ts-expect-error TS7016
import { v4 as uuid } from "uuid"
import { GeocodingResponse } from "../types/Location"
import { Provider, ProviderCount, TypeaheadProvider } from "../types/Providers"
import { HasCarrotPartnerResponse } from "../types/HasCarrotPartnerResponse"
import ResponseError from "../types/ResponseError"
import { reportError } from "../utils/ProviderFinderErrorReporting"
import Settings from "./Settings"
import { HasCustomNetworksResponse } from "../types/HasCustomNetworksResponse"
import { HasVirtualProvidersResponse } from "../types/HasVirtualProvidersResponse"

class ProviderFinderClientError extends Error {
  readonly correlationId?: string

  constructor(message: string, { cause, correlationId }: { cause?: Error; correlationId?: string }) {
    super(message)
    this.name = this.constructor.name
    this.cause = cause
    this.correlationId = correlationId

    Object.setPrototypeOf(this, ProviderFinderClientError.prototype)
  }
}

export class ProviderFinderClient {
  baseUrl: string
  handleSessionExpiration: () => void

  constructor(baseUrl: string, handleSessionExpiration: () => void) {
    this.baseUrl = baseUrl
    this.handleSessionExpiration = handleSessionExpiration
  }

  async processJsonResponse<ResponseType>(response: Response, correlationId: string): Promise<ResponseType> {
    if (response.status === StatusCodes.OK) {
      return response?.json()
    }
    throw new ResponseError(response, correlationId)
  }

  createJsonHeaders(correlationId: string): Headers {
    const headers = new Headers()
    headers.append("X-Request-ID", correlationId)
    headers.append("X-React-App-Version", process.env.REACT_APP_VERSION || "local")
    headers.append("Content-Type", "application/json")
    headers.append("X-Authorizer-Domain", Settings.AUTHORIZER_DOMAIN)
    return headers
  }

  async get<ResponseType>(url: RequestInfo | URL): Promise<ResponseType> {
    const correlationId = uuid()

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: this.createJsonHeaders(correlationId),
        credentials: "include"
      })

      return await this.processJsonResponse(response, correlationId)
    } catch (error) {
      error.correlationId = correlationId

      if (error instanceof ResponseError && error.statusCode === StatusCodes.UNAUTHORIZED) {
        this.handleSessionExpiration()
        return null
      }

      reportError(
        new ProviderFinderClientError(`Failed to fetch ${url} `, {
          cause: error, // TODO Sentry groups this based on a custom grouping rule (src/utils/ErrorReporting.ts) with a wide net of anything with "Failed to fetch" in the stack trace.
          correlationId
        })
      )

      throw error
    }
  }

  async getLocationDetailsForAddress(locationString: string): Promise<GeocodingResponse> {
    const url = new URL(`${this.baseUrl}/geocoding`)
    url.searchParams.set("locationString", locationString)

    return this.get(url)
  }

  async getProviders(params: URLSearchParams): Promise<Provider[]> {
    const url = new URL(`${this.baseUrl}/providers`)
    url.search = new URLSearchParams(params).toString()

    return this.get(url)
  }

  async getHasProviderTypeInCountry(companyCountryCode: string): Promise<ProviderCount> {
    const url = new URL(`${this.baseUrl}/has-provider-type-in-country`)
    url.searchParams.set("companyCountryCode", companyCountryCode)

    return this.get(url)
  }

  async getTypeaheadProviders(providerNameSubstring: string, companyCountryCode: string): Promise<TypeaheadProvider[]> {
    const url = new URL(`${this.baseUrl}/providers-member`)
    url.searchParams.set("companyCountryCode", companyCountryCode)
    url.searchParams.set("providerNameSubstring", providerNameSubstring)

    return this.get(url)
  }

  async getHasCarrotPartner(companyCountryCode: string): Promise<HasCarrotPartnerResponse> {
    const url = new URL(`${this.baseUrl}/carrot-partner`)
    url.searchParams.set("companyCountryCode", companyCountryCode)

    return this.get(url)
  }

  async getHasVirtualProviders(companyCountryCode: string): Promise<HasVirtualProvidersResponse> {
    const url = new URL(`${this.baseUrl}/virtual-provider`)
    url.searchParams.set("companyCountryCode", companyCountryCode)

    return this.get(url)
  }

  async getHasCustomNetworks(): Promise<HasCustomNetworksResponse> {
    const url = new URL(`${this.baseUrl}/has-custom-network`)
    return this.get(url)
  }
}
