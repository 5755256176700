import React, { FC } from "react"
import { useSelector } from "react-redux"
import { getCurrencySymbol } from "redux/reducers/companyInfo"
import { getDeductibleStatus } from "redux/reducers/deductibleStatus"
import { ReimbursementStatusProvider } from "components/context/reimbursement/ReimbursementStatusContext"
import {
  HowMuchMemberPartnerReimbursedModal,
  HowMuchMemberPartnerReimbursedModalProps
} from "../../../views/reimbursements/educationmodal/HowMuchReimbursedMemberPartnerModal"

const HowMuchReimbursedModalContainer: FC<HowMuchMemberPartnerReimbursedModalProps> = ({ onExit, subsidy }) => {
  const deductibleStatus = useSelector(getDeductibleStatus)
  deductibleStatus.currencySymbol = useSelector(getCurrencySymbol)

  return (
    <ReimbursementStatusProvider>
      <HowMuchMemberPartnerReimbursedModal onExit={onExit} subsidy={subsidy} />
    </ReimbursementStatusProvider>
  )
}

export { HowMuchReimbursedModalContainer }
