import React, { ReactElement } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import {
  hasQuestionBeenSkipped,
  useCheckRoutingInfoQueryCache,
  useUpdateRoutingInfo
} from "components/carrot-plans/shared/useQueryRoutingInfo"
import {
  ButtonFooterLayout,
  ConfirmButton,
  RoutingFlowCheckboxGroup,
  RoutingFlowTitle,
  StepLayout,
  useConvertTranslatedLabel
} from "components/carrot-plans/shared"
import { FormattedMessage, useIntl } from "react-intl"
import { Form, FormTextField, useWatch, useFormContext } from "@carrotfertility/carotene-core-x"
import { useTrackExperimentEvent } from "components/views/register/hooks/useExperiments"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useAnythingElseOptions } from "../../hooks/useAnythingElseOptions"
import {
  Box,
  Button,
  Checkbox,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  IconButton
} from "@carrotfertility/carotene-core"
import { useToggle } from "../../../../utils/Hooks"
import useGetMemberBenefit from "../../hooks/useGetMemberBenefit"
import { CarrotPlansPaths } from "../../../../utils/Paths"

export default function IsThereAnythingElseStep(): ReactElement {
  const { ficCpRoutingUpdate } = useFlags()

  return ficCpRoutingUpdate ? <AnythingElseMultiSelectForm /> : <AnythingElseTextInputForm />
}

function AnythingElseTextInputForm(): ReactElement {
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const defaultValue = routingData?.memberJourneyAdditionalInfo
  const { send } = useStateMachine(
    defaultValue || hasQuestionBeenSkipped("memberJourneyAdditionalInfo", routingData) ? "" : null
  )
  const isRefreshRoutingFlow = window.location.href.includes("/carrot-plans/case-rate-renewal/routing")
  const intl = useIntl()
  const { trackExperimentEvent } = useTrackExperimentEvent()

  // @ts-expect-error TS7006
  function sanitizeInputs(inputValue) {
    // @ts-expect-error TS7006
    return inputValue === null || inputValue.split("").every((char) => char === " ") || inputValue === ""
      ? null
      : inputValue
  }
  async function onSkip(): Promise<void> {
    await mutateAsync({ skipFields: ["memberJourneyAdditionalInfo"] }, { onSuccess: () => send("") })
  }
  async function onSubmit(formValues: { memberJourneyAdditionalInfo?: string }): Promise<void> {
    const sanitizedInput = sanitizeInputs(formValues?.memberJourneyAdditionalInfo)
    if (!isRefreshRoutingFlow) {
      // track for journey selection in registration experiment
      trackExperimentEvent("carrot-plan-created")
    }
    if (sanitizedInput === null) {
      await onSkip()
    } else {
      await mutateAsync({ memberJourneyAdditionalInfo: sanitizedInput }, { onSuccess: () => send("") })
    }
  }

  const header = intl.formatMessage({
    defaultMessage: "Is there anything else you'd like to share?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="memberJourneyAdditionalInfo-label">{header}</span>}
        description={intl.formatMessage({
          defaultMessage:
            "This could include information about your journey to this point, any other journeys you are on or considering, or any personal or cultural considerations you'd like us to be aware of."
        })}
        formElements={
          <Form onSubmit={onSubmit}>
            <FormTextField
              name="memberJourneyAdditionalInfo"
              defaultValue={defaultValue}
              rows={4}
              multiline
              inputProps={{ maxLength: 3000, "aria-labelledby": "memberJourneyAdditionalInfo-label" }}
            />
            <ButtonFooterLayout
              continueButton={
                <ConfirmButton>
                  {isRefreshRoutingFlow ? (
                    <FormattedMessage defaultMessage="Refresh Carrot Plan" />
                  ) : (
                    <FormattedMessage defaultMessage="Create Carrot Plan" />
                  )}
                </ConfirmButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}

function AnythingElseMultiSelectForm() {
  const intl = useIntl()
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const defaultAdditionalInfoValue = routingData?.memberJourneyAdditionalInfo
  const defaultAdditionalTopicsValue = routingData?.additionalTopicsShared
  const { data: memberBenefit } = useGetMemberBenefit()
  const anythingElseOptions = useAnythingElseOptions(routingData?.journey, memberBenefit)
  const options = useConvertTranslatedLabel(anythingElseOptions)

  const { send } = useStateMachine(
    defaultAdditionalInfoValue || hasQuestionBeenSkipped("memberJourneyAdditionalInfo", routingData) ? "" : null
  )
  const isRefreshRoutingFlow = window.location.href.includes(CarrotPlansPaths.CASE_RATE_RENEWAL)
  const { trackExperimentEvent } = useTrackExperimentEvent()
  const [isConsentModalOpen, toggleConsentModal] = useToggle(false)
  const [consentToShare, setConsentToShare] = React.useState<boolean>(false)

  async function onSubmit(formValues: {
    memberJourneyAdditionalInfo?: string
    additionalTopicsShared?: string[]
  }): Promise<void> {
    if (!isRefreshRoutingFlow) {
      // track for journey selection in registration experiment
      trackExperimentEvent("carrot-plan-created")
    }

    if (!formValues?.additionalTopicsShared?.length) {
      await mutateAsync(
        { skipFields: ["additionalTopicsShared", "memberJourneyAdditionalInfo"] },
        { onSuccess: () => send("") }
      )
    } else {
      await mutateAsync(
        {
          memberJourneyAdditionalInfo: sanitizeTextInput(formValues?.memberJourneyAdditionalInfo),
          additionalTopicsShared: formValues?.additionalTopicsShared,
          isFaithSharingConsentGranted: consentToShare
        },
        { onSuccess: () => send("") }
      )
    }
  }

  const header = intl.formatMessage({
    defaultMessage: "To help us personalize your Carrot Plan, are there any other important details we should know?"
  })

  const description = intl.formatMessage({
    defaultMessage: "Select all topics that you'd like to tell us more about."
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="memberJourneyAdditionalInfo-label">{header}</span>}
        description={description}
        formElements={
          <Form
            onSubmit={onSubmit}
            defaultValues={{
              additionalTopicsShared: defaultAdditionalTopicsValue,
              memberJourneyAdditionalInfo: defaultAdditionalInfoValue
            }}
          >
            <AnythingElseMultiSelect
              multiSelectOptions={options}
              consentToShare={consentToShare}
              setConsentToShare={setConsentToShare}
              isConsentModalOpen={isConsentModalOpen}
              setOpenModal={toggleConsentModal}
            />
            <ButtonFooterLayout
              continueButton={
                <ConfirmButton>
                  {isRefreshRoutingFlow ? (
                    <FormattedMessage defaultMessage="Refresh Carrot Plan" />
                  ) : (
                    <FormattedMessage defaultMessage="Create Carrot Plan" />
                  )}
                </ConfirmButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}

function AnythingElseMultiSelect({
  multiSelectOptions,
  consentToShare,
  setConsentToShare,
  isConsentModalOpen,
  setOpenModal
}: {
  multiSelectOptions: { label: string; value: any }[]
  consentToShare: boolean
  setConsentToShare: any
  isConsentModalOpen: boolean
  setOpenModal: any
}) {
  const intl = useIntl()
  const additionalTopicsLabel = "additionalTopicsShared"
  const additionalInfoLabel = "memberJourneyAdditionalInfo"
  const faithOption = "RELIGIOUS_CONSIDERATIONS"
  const selectedOptions: string[] = useWatch({ name: additionalTopicsLabel })
  const { setValue, clearErrors } = useFormContext()
  const isTextInputDisabled = !(selectedOptions?.length > 0)

  const onChangeConsentToShare = (consented: boolean) => {
    // remove faithOption as selected if user denies consent to share
    if (!consented) {
      const updatedSelections = selectedOptions?.filter((option: string) => option !== faithOption)
      setValue(additionalTopicsLabel, updatedSelections)
      setConsentToShare(false)
      setOpenModal(false)
    } else if (consented) {
      setConsentToShare(true)
      setOpenModal(false)
    }
  }

  const ensureSelectedFaithOptionPromptsConsentToShare = () => {
    if (selectedOptions?.includes(faithOption) && !consentToShare) {
      setOpenModal(true)
    } else if (!selectedOptions?.includes(faithOption) && consentToShare) {
      setConsentToShare(false)
    }
  }
  ensureSelectedFaithOptionPromptsConsentToShare()

  return (
    <>
      <RoutingFlowCheckboxGroup
        aria-labelledby={additionalTopicsLabel + "-label"}
        name={additionalTopicsLabel}
        options={multiSelectOptions}
        onChange={(_) => clearErrors(additionalInfoLabel)}
      />
      <FormTextField
        name={additionalInfoLabel}
        label={intl.formatMessage({ defaultMessage: "More Detail" })}
        helperText={<FormattedMessage defaultMessage="More information about your journey" />}
        rows={2}
        multiline
        inputProps={{ maxLength: 3000, "aria-labelledby": "memberJourneyAdditionalInfo-label" }}
        disabled={isTextInputDisabled}
        registerOptions={{
          validate: (inputValue) =>
            !isTextInputDisabled && sanitizeTextInput(inputValue) === null
              ? intl.formatMessage({ defaultMessage: "Please add more detail." })
              : true,
          required: isTextInputDisabled ? false : intl.formatMessage({ defaultMessage: "Please add more detail." })
        }}
      />
      <ConsentToShareModal openModal={isConsentModalOpen} onChangeConsentToShare={onChangeConsentToShare} />
    </>
  )
}

function ConsentToShareModal({
  openModal,
  onChangeConsentToShare
}: {
  openModal: boolean
  onChangeConsentToShare: (consented: boolean) => void
}): ReactElement {
  const intl = useIntl()
  const [isChecked, toggleCheckbox] = useToggle(false)

  function onSave(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault()
    onChangeConsentToShare(isChecked)
    toggleCheckbox(false)
  }

  function onClose() {
    onChangeConsentToShare(false)
    toggleCheckbox(false)
  }

  return (
    <Dialog aria-labelledby="" open={openModal} onClose={onClose} data-testid="faith-consent-modal">
      <Box display="flex" justifyContent="flex-end">
        <IconButton aria-label={intl.formatMessage({ defaultMessage: "Close" })} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <DialogContentText>
          {intl.formatMessage({
            defaultMessage:
              "You are not required to share information about your religious beliefs. If you choose to share, we will use it to personalize our support. We will not share it with your benefit sponsor or any third parties."
          })}
        </DialogContentText>
        <Box marginTop={(theme) => theme.spacing(theme.tokens.spacing.md)}>
          <FormControlLabel
            aria-labelledby={"consentToShare-label"}
            id={"consentToShare"}
            label={intl.formatMessage({
              defaultMessage: "I consent to Carrot using the information I choose to share to personalize my support."
            })}
            control={
              <Checkbox
                data-testid="faith-consent-modal-checkbox"
                onChange={(e) => {
                  toggleCheckbox(e.target.checked)
                }}
                checked={isChecked}
              />
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={onSave}>
          <FormattedMessage defaultMessage="Save and continue" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function sanitizeTextInput(inputValue: string | null): string | null {
  return inputValue === null || inputValue.split("").every((char) => char === " ") || inputValue === ""
    ? null
    : inputValue
}
