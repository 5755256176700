import actionTypes from "redux/actions/actionTypes"
import { reportError } from "utils/ErrorReporting"
import { createRequestAction } from "./shared"
import { carrotClient } from "utils/CarrotClient"

export const activateCard = (employeeId: any) => async (dispatch: any) => {
  dispatch({
    type: actionTypes.ACTIVATE_CARD_REQUEST
  })

  try {
    await carrotClient.activateCard()
  } catch (error) {
    error.message =
      `Card activation failed, error while activating card for employeeId: ${employeeId}: ` + error.message
    reportError(error)

    dispatch({
      type: actionTypes.ACTIVATE_CARD_FAILURE
    })
    return
  }

  dispatch({
    type: actionTypes.ACTIVATE_CARD_SUCCESS
  })
}

export const fetchCardDetails = createRequestAction(
  "FETCH_VIRTUAL_CARD_DETAILS",
  carrotClient.getCardDetails.bind(carrotClient)
)
