import { Form } from "@carrotfertility/carotene-core-x"
import useAboutYou from "components/carrot-plans/hooks/useAboutYou"
import { useGetSexOptions } from "services/common-forms/SexOptions"
import React from "react"
import { useToggle } from "utils/Hooks"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { FeatureGate } from "utils/FeatureGate"
import { FormattedMessage, useIntl } from "react-intl"
import {
  ButtonFooterLayout,
  ContinueButton,
  RoutingFlowDialog,
  RoutingFlowModalIcon,
  RoutingFlowTitle,
  StepLayout
} from "components/carrot-plans/shared"
import { FormRadioButtonGroup } from "services/common-forms"

export default function AboutYouSexStep(): JSX.Element {
  const intl = useIntl()
  const { data, updateAboutYou } = useAboutYou()
  const { send } = useStateMachine(data?.sex ? "" : null)

  async function onClickContinue({ sex }: { sex?: string }): Promise<void> {
    if (sex) {
      await updateAboutYou({ sex })
      send("")
    }
  }
  const [showMemberSexModal, toggleMemberSexModal] = useToggle(false)
  const sexOptions = useGetSexOptions()
  const header = intl.formatMessage({
    defaultMessage: "What's your sex?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="sex-label">{header}</span>}
        iconButton={
          <FeatureGate filter={(c) => c.isLgtbqEnabled}>
            <RoutingFlowModalIcon
              onClick={toggleMemberSexModal}
              aria-label={intl.formatMessage({ defaultMessage: "Why do you need to know what sex I am?" })}
            />
          </FeatureGate>
        }
        formElements={
          <Form defaultValues={{ sex: data?.sex }} onSubmit={onClickContinue}>
            <FormRadioButtonGroup
              name="sex"
              options={sexOptions}
              aria-labelledby="sex-label"
              controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
            />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </Form>
        }
      />
      <RoutingFlowDialog
        open={showMemberSexModal}
        onClose={toggleMemberSexModal}
        title={intl.formatMessage({ defaultMessage: "Why do you need to know what sex I am?" })}
        description={intl.formatMessage({
          defaultMessage:
            "Also known as assigned sex at birth, this is the sex that appeared on your original birth certificate. This information helps us provide guidance and suggest relevant resources, and is never shared with your benefit sponsor."
        })}
      />
    </>
  )
}
