import { combineReducers } from "redux"
import actionTypes from "redux/actions/actionTypes"

// @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any[]' type.
const list = (state = [], action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.reimbursements
    default:
      return state
  }
}

const reimbursements = combineReducers({
  list
})

export const getReimbursementsList = (state: any) => state.reimbursements.list

export default reimbursements
