import { updateAboutYouInfo } from "redux/actions/userActions"
import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { useDispatch } from "react-redux"
import { AboutYou } from "types/carrotPlanTypes"
import ResponseError from "types/responseError"
import { HttpStatusCodes } from "utils/HttpStatusCodes"
import { useState } from "react"
import { carrotClient } from "utils/CarrotClient"

export const ENDPOINT = "GetAboutYou"

export type AboutYouPartial = Partial<AboutYou>

type UseCarrotPlanReturn = UseQueryResult<AboutYou, ResponseError> & {
  hasQuestionBeenSkipped: (questionKey: string) => boolean
  updateAboutYou: (data: AboutYouPartial) => Promise<void>
}

export default function useAboutYou(): UseCarrotPlanReturn {
  const dispatch = useDispatch()
  const [continueLoading, setContinueLoading] = useState(false)
  const aboutYouQuery = useQuery<AboutYou, ResponseError>([ENDPOINT], () => carrotClient.getAboutYou(), {
    retry: (failureCount, error) => {
      switch (error.statusCode) {
        case HttpStatusCodes.UNAUTHORIZED:
        case HttpStatusCodes.BAD_REQUEST:
        case HttpStatusCodes.NOT_FOUND:
          return false
        default:
          return failureCount <= 3
      }
    }
  })
  function hasQuestionBeenSkipped(questionKey: string): boolean {
    return aboutYouQuery.data?.skipFields.includes(questionKey)
  }
  async function updateAboutYou(data: AboutYouPartial): Promise<void> {
    if (!continueLoading) {
      try {
        await dispatch(updateAboutYouInfo(data))
      } catch (error) {
        setContinueLoading(false)
        throw error
      }
      setContinueLoading(false)
    }
  }
  return {
    ...aboutYouQuery,
    updateAboutYou,
    hasQuestionBeenSkipped
  }
}
