import React from "react"
import { connect } from "react-redux"
import Root from "components/views/Root"
import { getIsLoggedIn, getLoadingIsLoggedIn } from "redux/reducers/application"
import { fetchReduxData } from "redux/actions/loginActions"
import { ScrollToTop } from "../views/ScrollToTop"

class InternalRootContainer extends React.Component {
  componentDidMount() {
    this.props.fetchReduxData()
  }

  render() {
    return (
      <ScrollToTop>
        <Root loggedIn={this.props.isLoggedIn} loadingIsLoggedIn={this.props.loadingIsLoggedIn} />
      </ScrollToTop>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: getIsLoggedIn(state),
  loadingIsLoggedIn: getLoadingIsLoggedIn(state)
})

const RootContainer = connect(mapStateToProps, {
  fetchReduxData
})(InternalRootContainer)

export default RootContainer
