import { useDispatch, useSelector } from "react-redux"
import { getIsLoggedIn } from "redux/reducers/application"
import React, { useState } from "react"
import actionTypes from "redux/actions/actionTypes"
import { CarrotErrorCodes } from "../../../utils/CarrotErrors"
import { useQueryParam } from "use-query-params"
import { signOut } from "redux/actions/loginActions"
import { useHistory } from "react-router"

const DELAY_INDEX_PAGE_RETURN = 200

type AuthenticationContextReturn = {
  isLoggedIn: boolean
  handleSessionExpiration: () => void
  isExplicitLogout: boolean
  handleSignOut: () => void
  resetExplicitLogout: () => void
  handleSignOutNoRedirect: () => void
}

interface AuthenticationProviderProps {
  children: React.ReactNode | React.ReactNode[]
}

const AuthenticationContext = React.createContext<AuthenticationContextReturn>(null)

export const useAuthentication = (): AuthenticationContextReturn => React.useContext(AuthenticationContext)

export function AuthenticationProvider({ children }: AuthenticationProviderProps): JSX.Element {
  const [_errorCode, setErrorCode] = useQueryParam("errorCode")
  const history = useHistory()
  const [isExplicitLogout, setIsExplicitLogout] = useState(false)
  const isLoggedIn = useSelector(getIsLoggedIn)
  const dispatch = useDispatch()

  const handleSessionExpiration = React.useCallback(() => {
    dispatch({
      type: actionTypes.SESSION_EXPIRED
    })
    setErrorCode(CarrotErrorCodes.NOT_AUTHENTICATED)
  }, [dispatch, setErrorCode])

  const resetExplicitLogout = React.useCallback(() => {
    setIsExplicitLogout(false)
  }, [])

  const handleSignOut = React.useCallback(() => {
    setIsExplicitLogout(true)
    window.zE("messenger", "logoutUser")
    // @ts-expect-error TS7006
    dispatch(async (internalDispatch) => {
      await signOut()(internalDispatch)
      // To prevent flashing of logged in Home Screen
      setTimeout(() => history.push("/"), DELAY_INDEX_PAGE_RETURN)
    })
  }, [dispatch, history])

  const handleSignOutNoRedirect = React.useCallback(() => {
    setIsExplicitLogout(true)
    window.zE("messenger", "logoutUser")
    // @ts-expect-error TS7006
    dispatch(async (internalDispatch) => {
      await signOut()(internalDispatch)
    })
  }, [dispatch])

  return (
    <AuthenticationContext.Provider
      value={{
        isLoggedIn,
        isExplicitLogout,
        handleSessionExpiration,
        handleSignOut,
        resetExplicitLogout,
        handleSignOutNoRedirect
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}
