import useGetLegacyBenefit, {
  LegacyBenefit,
  useCreateOverride
} from "services/member-benefit/hooks/useGetLegacyBenefit"

export const useLegacyBenefitMapper = () => {
  const { data } = useGetLegacyBenefit()

  const toggleBenefitData = useCreateOverride()

  const deepClone = (oldBenefit: LegacyBenefit): LegacyBenefit => JSON.parse(JSON.stringify(oldBenefit))

  const mapper = {
    bank_info: {
      value: data?.features.isBankInfoEnabled,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.features.isBankInfoEnabled = !data.features.isBankInfoEnabled
        toggleBenefitData(overrideData)
      }
    },
    custom_coverage_content: {
      value: data?.features.isCustomCoverageContentEnabled,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.features.isCustomCoverageContentEnabled = !data.features.isCustomCoverageContentEnabled
        toggleBenefitData(overrideData)
      }
    },
    custom_reimbursement_guidance: {
      value: data?.features.isCustomCoverageContentEnabled,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.features.isCustomCoverageContentEnabled = !data.features.isCustomCoverageContentEnabled
        toggleBenefitData(overrideData)
      }
    },
    partner_access: {
      value: data?.features.isPartnerCredentialAccessEnabled,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.features.isPartnerCredentialAccessEnabled = !data.features.isPartnerCredentialAccessEnabled
        toggleBenefitData(overrideData)
      }
    },
    gross_up: {
      value: data?.features.isGrossUpEnabled,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.features.isGrossUpEnabled = !data.features.isGrossUpEnabled
        toggleBenefitData(overrideData)
      }
    },
    copay: {
      value: data?.coverages.isCopayEnabled,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.coverages.isCopayEnabled = !data.coverages.isCopayEnabled
        toggleBenefitData(overrideData)
      }
    },
    legal_partners_eligible: {
      value: data?.coverages.areLegalPartnersEligible,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.coverages.areLegalPartnersEligible = !data.coverages.areLegalPartnersEligible
        toggleBenefitData(overrideData)
      }
    },
    member_and_partner_eligible: {
      value: data?.coverages.isMemberAndPartnerEligible,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.coverages.isMemberAndPartnerEligible = !data.coverages.isMemberAndPartnerEligible
        toggleBenefitData(overrideData)
      }
    },
    partner_eligible_if_on_customer_medical_plan: {
      value: data?.coverages.isPartnerEligibleIfOnCustomerMedicalPlan,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.coverages.isPartnerEligibleIfOnCustomerMedicalPlan =
          !data.coverages.isPartnerEligibleIfOnCustomerMedicalPlan
        toggleBenefitData(overrideData)
      }
    }
  }
  return mapper
}
