import React from "react"
import { ArrowSubmitButton, ReimbursementDialogFraming, BackArrow } from "../shared/layouts"
import { Progress } from "components/views/modal/Progress"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import { useReimbursementContext } from "components/context/reimbursement/GetReimbursedContext"
import { useCurrentUser } from "../../context/user/UserContext"
import { FormattedMessage, useIntl } from "react-intl"
import { FormRadioButtonGroup } from "../../../services/common-forms"
import { DialogActionsBar, Form } from "@carrotfertility/carotene-core-x"
import { Box, DialogContentText, DialogTitle } from "@carrotfertility/carotene-core"

export const AboutThisExpenseCareRelatedToInfertilityDiagnosis = (): JSX.Element => {
  const { send, back } = useStateMachine()
  const { state, updateRelatedToInfertilityDiagnosis } = useReimbursementContext()
  const { isUsa } = useCurrentUser()
  const intl = useIntl()

  // @ts-expect-error TS7006
  const onSubmit = (value): void => {
    updateRelatedToInfertilityDiagnosis(value.infertilitySelection)
    send("")
  }

  return (
    <>
      <Progress
        numSteps={3}
        step={2}
        label={intl.formatMessage({ defaultMessage: "About This Expense" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={back} />
      <Form defaultValues={{ infertilitySelection: state.isInfertilityDiagnosis }} onSubmit={onSubmit}>
        <ReimbursementDialogFraming>
          <DialogTitle id="step-heading">
            <FormattedMessage defaultMessage="Was the care related to an infertility diagnosis issued by a doctor?" />
          </DialogTitle>
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(theme.tokens.spacing.lg) }}
          >
            {isUsa && (
              <DialogContentText>
                <FormattedMessage defaultMessage="If you’re not sure, ask your doctor. This helps us determine whether an expense is taxable so we can process reimbursements in a legally compliant way" />
              </DialogContentText>
            )}
            <FormRadioButtonGroup
              aria-labelledby="step-heading"
              name="infertilitySelection"
              controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
              options={[
                { label: intl.formatMessage({ defaultMessage: "Yes" }), value: "true" },
                { label: intl.formatMessage({ defaultMessage: "No" }), value: "false" }
              ]}
            />
          </Box>
        </ReimbursementDialogFraming>
        <DialogActionsBar
          primaryAction={
            <ArrowSubmitButton>
              <FormattedMessage defaultMessage="Continue" />
            </ArrowSubmitButton>
          }
        />
      </Form>
    </>
  )
}
