import React, { useEffect } from "react"
import { ReadAndLearnBreadcrumbs } from "../../components/read/shared/ReadAndLearnBreadcrumbs"
import { GroupComponentProps } from "./GroupParser"
import { Group } from "./utils"
import { LegalFooter } from "./components/LegalFooter"
import { ContentfulConditionalGate } from "lib/contentful/components/ContentfulConditionGate"
import { FindASessionButton } from "./components/FindSessionButton"
import { CarrotPlanRequiredModal } from "components/views/app/CarrotPlanRequiredModal"
import { useCurrentUser } from "components/context/user/UserContext"
import { getHeap } from "./utils/heap"
import { Moderators } from "./components/Moderators"
import { ContentfulRawMarkdownRenderer } from "services/contentful"
import { Box, Stack, useTheme } from "@carrotfertility/carotene-core"
import { Container, FlexContainer } from "@carrotfertility/carotene"
import { defaultMarkdownElements } from "services/markdown/elements"
import { Title } from "services/page-title/Title"
import { PageLayout } from "features/global-ui/page-layout"
import { PageBreadcrumbHeader } from "features/global-ui/page-header"

const Topics = ({ topicsMarkdown }: { topicsMarkdown: string }): JSX.Element => (
  <Box
    bgcolor={(theme) => theme.palette.background.default}
    borderRadius={(theme) => theme.spacing(theme.tokens.spacing.md)}
    padding={(theme) => theme.spacing(theme.tokens.spacing.lg)}
    maxWidth={"20rem"}
  >
    <ContentfulRawMarkdownRenderer>{topicsMarkdown}</ContentfulRawMarkdownRenderer>
  </Box>
)

const GroupHeading = ({ overview, title }: { overview: string; title: string }): JSX.Element => (
  <Box display="flex" flexDirection="column" gap={(theme) => theme.spacing(theme.tokens.spacing.xs)}>
    <defaultMarkdownElements.h1.component {...defaultMarkdownElements.h1.props} variant="body2">
      {overview}
    </defaultMarkdownElements.h1.component>
    <defaultMarkdownElements.h1.component {...defaultMarkdownElements.h1.props}>
      {title}
    </defaultMarkdownElements.h1.component>
  </Box>
)

const GroupDetails = ({ group, groupSlugId }: { group: Group; groupSlugId: string }): JSX.Element => {
  const { overview, title, description, topics, calendlyUrl, reasonId, locationId, timeTapEnvironment } = group
  return (
    <FlexContainer direction={["column", null, null, "row"]} gap="giant">
      <FlexContainer width={[null, null, null, "70%"]} gap="large" direction="column">
        <FlexContainer gap="medium" direction="column">
          <GroupHeading overview={overview} title={title} />
          <ContentfulRawMarkdownRenderer>{description}</ContentfulRawMarkdownRenderer>
        </FlexContainer>
        <Container>
          <FindASessionButton
            sessionPickerUrl={calendlyUrl}
            groupSlugId={groupSlugId}
            reasonId={reasonId}
            locationId={locationId}
            timeTapEnvironment={timeTapEnvironment}
          />
        </Container>
      </FlexContainer>
      <Topics topicsMarkdown={topics} />
    </FlexContainer>
  )
}

const GroupLandingPage = ({ group, subcategory, category }: GroupComponentProps): JSX.Element => {
  const { isInAppLockdown } = useCurrentUser()
  const groupSlugId = `${group.slug}-${group.id}`
  useEffect(() => {
    if (isInAppLockdown) {
      getHeap().track("Carrot Plan Required Modal Rendered", {
        "Group Slug ID": groupSlugId
      })
    }
  }, [isInAppLockdown, groupSlugId])

  const theme = useTheme()

  return (
    <>
      <Title title={group.title} productTitle="Carrot Academy" />
      <PageLayout
        header={
          <PageBreadcrumbHeader
            breadcrumbs={<ReadAndLearnBreadcrumbs category={category} subcategory={subcategory} />}
          />
        }
      >
        <ContentfulConditionalGate conditions={group.conditions} allowConditionsOverride={true}>
          <Box
            gap={theme.spacing(theme.tokens.spacing.md)}
            data-group-id={groupSlugId}
            sx={{ marginBlockEnd: theme.spacing(theme.tokens.spacing.xxl) }}
          >
            <Stack
              spacing={theme.spacing(theme.tokens.spacing.xl)}
              marginTop={theme.spacing(theme.tokens.spacing.xxxl)}
            >
              <Box
                component="img"
                src={group.thumbnail?.file.url}
                alt=""
                height="80px"
                width="80px"
                sx={{
                  marginBlockEnd: theme.spacing(theme.tokens.spacing.lg),
                  borderRadius: theme.tokens.borderRadius.md
                }}
              />
              <GroupDetails group={group} groupSlugId={groupSlugId} />
              <Moderators moderators={group?.moderators} />
              <LegalFooter />
            </Stack>
          </Box>
        </ContentfulConditionalGate>
        <CarrotPlanRequiredModal />
      </PageLayout>
    </>
  )
}

export default GroupLandingPage
