import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query"
import ResponseError from "../../../types/responseError"
import { carrotClient } from "../../../utils/CarrotClient"
import buildCarrotApiRetryPolicy from "../../../utils/CarrotApiRetryPolicy"
import { reportErrorMessage } from "../../../utils/ErrorReporting"
import { getCarrotPlanQueryKey } from "../hooks/useCarrotPlan"
import { useCurrentUser } from "../../context/user/UserContext"
import { AdvancedCheckInEmailLinkQueryString } from "./useCaseRateRenewals"
import { carrotPlanEmployeeSettingsQueryKey } from "../hooks/useCarrotPlanEmployeeSettings"

export enum CaseRateRenewalsStatus {
  NONE = "NONE",
  GATED = "GATED",
  NOT_GATED = "NOT_GATED",
  ADVANCED_CHECK_IN_GATED = "ADVANCED_CHECK_IN_GATED",
  ADVANCED_CHECK_IN_DISMISSED = "ADVANCED_CHECK_IN_DISMISSED"
}

const caseRateRenewalStatusQueryKey = "caseRateRenewalStatus"

const daysSinceLastUnlockQueryKey = "getDaysSinceLastUnlock"

export function useGetCaseRateRenewalStatus(queryStringStep?: string | string[]) {
  const { employeeId } = useCurrentUser()
  const caseRateRenewalStatusQuery = useQuery<CaseRateRenewalsStatus, ResponseError>(
    [caseRateRenewalStatusQueryKey],
    () => carrotClient.getCaseRateRenewalStatus(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: buildCarrotApiRetryPolicy(3),
      onError: (error) =>
        reportErrorMessage(`Could not load case rate renewal status for member ${employeeId} error: ${error}`)
    }
  )

  const showAdvancedCheckInFeatures =
    caseRateRenewalStatusQuery.data === CaseRateRenewalsStatus.ADVANCED_CHECK_IN_GATED ||
    caseRateRenewalStatusQuery.data === CaseRateRenewalsStatus.ADVANCED_CHECK_IN_DISMISSED

  const showAdvancedCheckFromLinkInEmail =
    caseRateRenewalStatusQuery.data === CaseRateRenewalsStatus.ADVANCED_CHECK_IN_DISMISSED &&
    (queryStringStep === AdvancedCheckInEmailLinkQueryString.KEEP_PLAN ||
      queryStringStep === AdvancedCheckInEmailLinkQueryString.REFRESH_PLAN)

  const shouldCrrModalRender =
    caseRateRenewalStatusQuery.data === CaseRateRenewalsStatus.GATED ||
    caseRateRenewalStatusQuery.data === CaseRateRenewalsStatus.ADVANCED_CHECK_IN_GATED ||
    showAdvancedCheckFromLinkInEmail

  const isCrrAdvancedCheckIn =
    caseRateRenewalStatusQuery.data === CaseRateRenewalsStatus.ADVANCED_CHECK_IN_GATED ||
    showAdvancedCheckFromLinkInEmail

  return {
    ...caseRateRenewalStatusQuery,
    showAdvancedCheckInFeatures: showAdvancedCheckInFeatures,
    shouldCrrModalRender: shouldCrrModalRender,
    isCrrAdvancedCheckIn: isCrrAdvancedCheckIn
  }
}

export function useRepublishCarrotPlan(): UseMutationResult {
  const { employeeId, refreshUserInfo } = useCurrentUser()
  const queryClient = useQueryClient()
  return useMutation(() => carrotClient.republishCarrotPlan(employeeId), {
    retry: buildCarrotApiRetryPolicy(1),
    onSuccess: async () => {
      await queryClient.refetchQueries(getCarrotPlanQueryKey())
      await queryClient.refetchQueries([carrotPlanEmployeeSettingsQueryKey])
      await queryClient.refetchQueries([caseRateRenewalStatusQueryKey])
      await refreshUserInfo()
    }
  })
}

export function useSetDoneWithCarrot(nonStaleCheckIn?: boolean): UseMutationResult {
  const { refreshUserInfo } = useCurrentUser()
  const queryClient = useQueryClient()
  return useMutation(() => carrotClient.setDoneWithCarrot(), {
    retry: buildCarrotApiRetryPolicy(1),
    onSuccess: async () => {
      await queryClient.refetchQueries(getCarrotPlanQueryKey())
      await queryClient.refetchQueries([carrotPlanEmployeeSettingsQueryKey])
      await queryClient.refetchQueries([caseRateRenewalStatusQueryKey])
      if (!nonStaleCheckIn) {
        await refreshUserInfo()
      }
    }
  })
}

export function useRequestNewCaseRateRenewalCarrotPlan(): UseMutationResult {
  const { refreshUserInfo } = useCurrentUser()
  const queryClient = useQueryClient()
  return useMutation(() => carrotClient.requestNewCaseRateRenewalCarrotPlan(), {
    retry: buildCarrotApiRetryPolicy(1),
    onSuccess: async () => {
      await queryClient.refetchQueries([carrotPlanEmployeeSettingsQueryKey])
      await queryClient.refetchQueries(getCarrotPlanQueryKey())
      await queryClient.refetchQueries([caseRateRenewalStatusQueryKey])
      await refreshUserInfo()
    }
  })
}

export function useSetNonStaleCarrotPlanOnAnniversaryCheck(): UseMutationResult {
  const queryClient = useQueryClient()
  return useMutation(() => carrotClient.setNonStaleCarrotPlanOnAnniversaryCheck(), {
    retry: buildCarrotApiRetryPolicy(1),
    onSuccess: async () => {
      await queryClient.refetchQueries([caseRateRenewalStatusQueryKey])
    }
  })
}

export function useGetDaysSinceLastUnlock(): UseQueryResult<number, ResponseError> {
  return useQuery<number, ResponseError>([daysSinceLastUnlockQueryKey], () => carrotClient.getDaysSinceLastUnlock(), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: buildCarrotApiRetryPolicy(3)
  })
}

export function useSetCarrotPlanRenewalDismissed(): UseMutationResult {
  const queryClient = useQueryClient()
  return useMutation(() => carrotClient.setCarrotPlanRenewalDismissed(), {
    retry: buildCarrotApiRetryPolicy(1),
    onSuccess: async () => {
      await queryClient.refetchQueries([caseRateRenewalStatusQueryKey])
    }
  })
}
