import React, { HTMLAttributes, InputHTMLAttributes } from "react"
import { completeFormElementFactory, CompleteFormElementProps } from "./completeFormElementFactory"
import { SharedInputCssProps, StyledInput } from "components/views/atoms/forms/StyledInputs"

type FormInputProps = HTMLAttributes<HTMLInputElement> &
  Partial<SharedInputCssProps> &
  InputHTMLAttributes<HTMLInputElement>

const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  ({ valid = true, readOnly = false, type = "text", ...props }, ref) => {
    return (
      // @ts-ignore
      <StyledInput valid={valid} readOnly={readOnly} type={type} ref={ref} {...props} />
    )
  }
)

const CompleteFormInput = completeFormElementFactory<
  HTMLInputElement,
  CompleteFormElementProps<HTMLInputElement> & FormInputProps
>(FormInput)

FormInput.displayName = "FormInput"

export { CompleteFormInput }
