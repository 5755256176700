import moment from "moment"
import { reportErrorMessage } from "utils/ErrorReporting"

export const sleep = async (ms: any) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export default {
  handleInputChange(event: any) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.type === "file" ? target.files : target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  },
  handleInputChangeFieldsDeprecated(event: any) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.type === "file" ? target.files : target.value
    const name = target.name

    const fields = this.state.fields
    fields[name].value = value
    this.setState({ fields })
  },
  handleInputChangeFields(event: any) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.type === "file" ? target.files : target.value
    const name = target.name
    this.setState({ [name]: value })
  },
  convertToCurrencyString(number: any, currencySymbol: any, numDigits = 2) {
    return (
      currencySymbol +
      (number / 100).toLocaleString("en-US", {
        minimumFractionDigits: numDigits,
        maximumFractionDigits: numDigits
      })
    )
  },
  formatDate(momentArgs: any, formatString = "MMM DD, YYYY") {
    const date = moment(momentArgs)

    // The default format string creates: Mar 26, 2018
    return date.format(formatString)
  },
  getFromMapOrReportAndDefault(object: any, key: any, defaultKey: any) {
    let value
    // eslint-disable-next-line no-prototype-builtins
    if (!object.hasOwnProperty(key)) {
      reportErrorMessage(`Object does not contain key: '${key}'. Using default key: '${defaultKey}'`)
      value = object[defaultKey]
    } else {
      value = object[key]
    }
    return value
  },
  browserRedirect(path: any) {
    window.location.href = path
  },
  chunk(array: any, chunkSize: any) {
    const result = []

    for (let i = 0, j = array.length; i < j; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize)
      result.push(chunk)
    }

    return result
  },
  formatCreditCardNumber(number: any) {
    const NumbersPerGroup = 4
    const chunks = this.chunk(number.split(""), NumbersPerGroup)
    const stringChunks = chunks.map((c: any) => c.join(""))
    return stringChunks.join(" ")
  },
  makeAbsoluteUrl(url: any) {
    if (/^https?:\/\//.test(url)) {
      return url
    } else {
      return "https://" + url
    }
  }
}

export const returnApplicableYear = (hdhpIrsLimits: any) => {
  const yearsAvailable = Object.keys(hdhpIrsLimits).map((s) => parseInt(s, 10))
  const year = new Date().getFullYear()
  const filteredYearsAvailable = yearsAvailable.filter((limitYear) => limitYear <= year)
  return filteredYearsAvailable.length > 0 ? Math.max(...filteredYearsAvailable) : Math.min(...yearsAvailable)
}
