import { HttpErrors } from "utils/HttpErrors"
import { reportError } from "utils/ErrorReporting"
import React, { ReactElement } from "react"
import { useTrackView } from "../../../utils/heap"
import { getErrorMessageFromCode } from "../../../utils/CarrotErrors"
import { FormattedMessage, useIntl } from "react-intl"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { Box, TextField } from "@carrotfertility/carotene-core"
import { RegistrationButtonFooter, ContinueButton } from "./RegistrationButtons"
import { Form, FormTextField, useFormContext } from "@carrotfertility/carotene-core-x"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { carrotClient } from "utils/CarrotClient"

interface SetPasswordProps {
  email?: string
  onBack: () => void
  onComplete: (password: string) => void
}

function SetPasswordContinueButton(): ReactElement {
  const {
    formState: { isValid, isDirty }
  } = useFormContext()

  return (
    <ContinueButton disabled={!isValid || !isDirty}>
      <FormattedMessage defaultMessage="Continue" />
    </ContinueButton>
  )
}

export function SetPassword({ email, onBack, onComplete }: SetPasswordProps): ReactElement {
  useTrackView("SetPasswordRegFlow", "Set password screen")
  const intl = useIntl()

  const onSubmit = (formData: { password: string }) => {
    onComplete(formData.password)
  }

  const validatePassword = async (password: string) => {
    if (!password) return true
    try {
      await carrotClient.validatePassword(password)
    } catch (error) {
      if (error.name === HttpErrors.BAD_REQUEST) {
        const { code } = await error.response.json()
        return intl.formatMessage(getErrorMessageFromCode(code))
      }
      reportError(error)
    }
    return true
  }

  const header = intl.formatMessage({ defaultMessage: "Create a password" })

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={header}
        description={
          <Box
            marginTop={(theme) => theme.spacing(theme.tokens.spacing.md)}
            marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}
          >
            <TextField label={<FormattedMessage defaultMessage="account email" />} defaultValue={email} disabled />
          </Box>
        }
        interactiveElements={
          <Form onSubmit={onSubmit} mode="onChange">
            <FormTextField
              name="password"
              registerOptions={{ validate: validatePassword, required: true }}
              label={intl.formatMessage({ defaultMessage: "Password" })}
              type="password"
            />
            <RegistrationButtonFooter onBack={onBack} continueButton={<SetPasswordContinueButton />} />
          </Form>
        }
      />
    </>
  )
}
