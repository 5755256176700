import React, { useState } from "react"
import { useCurrentUser } from "components/context/user/UserContext"
import { SettingsPage } from "./page"
import { Form, FormOnSubmitHandler } from "@carrotfertility/carotene-core-x"
import {
  disableBiometrics,
  isBiometricLoginAlreadyConfigured,
  isMobileNativeAndBiometricsCapable
} from "lib/carrot-api/MobileNativeClient"
import { UsaStates } from "../../utils/UsaStateOptions"
import { MessageDescriptor, useIntl } from "react-intl"
import { dayjs, type Dayjs } from "@carrotfertility/carotene-core"
import { AccountSettingsEnablePushNotificationsSettingDialog } from "components/views/account/AccountSettingsEnablePushNotificationsSettingDialog"
import {
  useIsMobileAndSupportsPushNotifications,
  useIsPushPermissionsEnabled
} from "components/views/modal/hooks/useMobileAppStatus"
import { useUpdateAccountSettings } from "components/views/account/hooks/useUpdateAccountSettings"

type AccountSettingFormValues = {
  [s: string]: unknown
  dateOfBirth: Dayjs
  partnerDateOfBirth: Dayjs
}

export const SettingsContext = React.createContext<{
  isModalForm: boolean
  setIsModalForm: (arg: boolean) => void
  isBioauthEnabled: boolean
  setBioauthEnabled: (arg: boolean) => void
  fieldErrorFromServer: any
}>({
  setIsModalForm: null,
  isModalForm: false,
  isBioauthEnabled: null,
  setBioauthEnabled: null,
  fieldErrorFromServer: null
})

export function SettingsContainer(): JSX.Element {
  const {
    email,
    firstName,
    lastName,
    preferredName,
    sex,
    genderIdentity,
    genderIdentityOther,
    dateOfBirth,
    phoneNumber,
    partnerFirstName,
    partnerLastName,
    partnerPreferredName,
    partnerDateOfBirth,
    partnerSex,
    partnerGenderIdentity,
    partnerGenderIdentityOther,
    partnerEmail,
    address1,
    address2,
    city,
    state,
    zip,
    internationalCountry,
    internationalAddressLine,
    isUsa,
    emailOptIn,
    pushNotificationOptIn,
    locale
  } = useCurrentUser()
  const [isBioauthEnabled, setBioauthEnabled] = useState(false)
  const [isPushNotificationOptInEnabled, setIsPushNotificationOptInEnabled] = useState(pushNotificationOptIn)
  const { mutateAsync, error, isSuccess } = useUpdateAccountSettings()
  const [isModalForm, setIsModalForm] = useState(false)
  const intl = useIntl()
  const stateDefaultValue = state && isUsa ? formatStateValue(state) : state
  const internationalCountryDefaultValue =
    internationalCountry && !isUsa ? formatInternationalCountryValue(internationalCountry) : internationalCountry

  const { data: isPushPermissionsEnabled, isLoading: isPushPermissionsEnabledLoading } = useIsPushPermissionsEnabled()
  const { data: isMobileAndSupportsPushNotifications, isLoading: isMobileAndSupportsPushNotificationsLoading } =
    useIsMobileAndSupportsPushNotifications()

  const onSubmit: FormOnSubmitHandler<AccountSettingFormValues> = async (formFields, formMethods) => {
    if ((await isMobileNativeAndBiometricsCapable()) && (await isBiometricLoginAlreadyConfigured())) {
      // We only allow disabling biometric login at this time. To re-enable they have to go to the login screen
      if (!formFields.bioauthEnabled) {
        disableBiometrics()
        setBioauthEnabled(false)
      }
    }

    if ("pushNotificationOptIn" in formFields) {
      setIsPushNotificationOptInEnabled(formFields.pushNotificationOptIn as boolean)
    }

    // Our backend has logic that expects an empty string in order to clear this field
    const partnerDateOfBirthValue =
      formFields.partnerDateOfBirth === null ? "" : formatDateAsString(formFields.partnerDateOfBirth)

    const payload = {
      ...formFields,
      dateOfBirth: formatDateAsString(formFields.dateOfBirth),
      partnerDateOfBirth: partnerDateOfBirthValue
    }
    await mutateAsync(payload)
    formMethods.reset({}, { keepValues: true })
  }

  function formatDateAsString(date: Dayjs): string {
    const formattedDate = date.format("YYYY-MM-DD")
    return dayjs(formattedDate, "YYYY-MM-DD", true).isValid() ? formattedDate : ""
  }

  function formatStateValue(state: string): { value: string; label: string | MessageDescriptor } {
    return {
      value: state,
      label: getStateLabel(state)
    }
  }

  // when users are migrated from non-US to US geos, it's possible for string values to end up here that don't match with US states
  // without this check in place, user is blocked from accessing account settings in that scenario
  function getStateLabel(state: string | MessageDescriptor): string | MessageDescriptor {
    const usaState = UsaStates.find((stateOption) => stateOption.id === state)
    if (usaState) {
      return intl.formatMessage(usaState.label)
    }
    return state
  }

  function formatInternationalCountryValue(internationalCountry: string): { value: string; label: string } {
    return {
      value: internationalCountry,
      label: intl.formatDisplayName(internationalCountry, { type: "region" })
    }
  }

  const context = {
    setIsModalForm,
    isModalForm,
    isBioauthEnabled,
    setBioauthEnabled,
    fieldErrorFromServer: error
  }

  const showPushNotificationDialog =
    !isMobileAndSupportsPushNotificationsLoading &&
    !isPushPermissionsEnabledLoading &&
    isMobileAndSupportsPushNotifications &&
    isSuccess &&
    isPushNotificationOptInEnabled &&
    !isPushPermissionsEnabled

  return (
    <SettingsContext.Provider value={context}>
      <Form
        defaultValues={
          {
            email,
            firstName,
            lastName,
            preferredName,
            sex,
            genderIdentity,
            genderIdentityOther,
            dateOfBirth: dayjs(dateOfBirth),
            phoneNumber,
            partnerFirstName,
            partnerLastName,
            partnerPreferredName,
            partnerDateOfBirth: dayjs(partnerDateOfBirth),
            partnerSex,
            partnerGenderIdentity,
            partnerGenderIdentityOther,
            partnerEmail,
            address1,
            address2,
            city,
            state: stateDefaultValue,
            zip,
            internationalCountry: internationalCountryDefaultValue,
            internationalAddressLine,
            emailOptIn,
            pushNotificationOptIn: pushNotificationOptIn,
            // eslint-disable-next-line no-restricted-syntax -- The user selects their preferredLocale in this form, and it is initialized to the current resolved locale
            preferredLocale: locale
          } as AccountSettingFormValues
        }
        onSubmit={(...args) => (isModalForm ? null : onSubmit(...args))}
      >
        <SettingsPage />
        {showPushNotificationDialog && <AccountSettingsEnablePushNotificationsSettingDialog initialOpen={true} />}
      </Form>
    </SettingsContext.Provider>
  )
}
