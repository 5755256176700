import { SelectOptionType } from "@carrotfertility/carotene"
import React, { useContext, useReducer } from "react"
import { FileTypes } from "../../get-reimbursed/steps/UploadFile"

interface InitialState {
  statementFiles: FileTypes
  eligibleReimbursementAmountString: string
  eligibleReimbursementAmount: number
  eligibleReimbursementAmountCurrencyCode: string
  whoReceivedCare: SelectOptionType
  expenseRelatedTo: SelectOptionType
  isStepchildAdoption: string
  kindOfJourney: SelectOptionType
  journeySubtype: SelectOptionType
  relatedToStorageFees: SelectOptionType
  relatedToMedication: SelectOptionType
  isInfertilityDiagnosis: string
  isMedicalNecessity: string
  memberNotes: SelectOptionType
  attestationIsMedicallyNecessary: boolean
  attestationIsEligibilityRequirementMet: boolean
  attestationIsMedicallyRelated: boolean
}
export const INITIAL_STATE: InitialState = {
  statementFiles: [],
  eligibleReimbursementAmount: null,
  eligibleReimbursementAmountString: null,
  eligibleReimbursementAmountCurrencyCode: null,
  whoReceivedCare: {} as SelectOptionType,
  expenseRelatedTo: {} as SelectOptionType,
  isStepchildAdoption: null,
  kindOfJourney: {} as SelectOptionType,
  journeySubtype: {} as SelectOptionType,
  relatedToStorageFees: {} as SelectOptionType,
  relatedToMedication: {} as SelectOptionType,
  isInfertilityDiagnosis: null,
  isMedicalNecessity: null,
  memberNotes: {} as SelectOptionType,
  attestationIsMedicallyNecessary: false,
  attestationIsEligibilityRequirementMet: false,
  attestationIsMedicallyRelated: false
}

export type GetReimbursedProps = {
  onExit: () => void
  state: InitialState
  expenseId: string
  transDate: Date
  transAmount: string
  updateStatementFiles: (files: FileTypes) => void
  // @ts-expect-error TS7051
  updateEligibleReimbursementAmountString: (string) => void
  // @ts-expect-error TS7051
  updateEligibleReimbursementAmount: (number) => void
  // @ts-expect-error TS7051
  updateEligibleReimbursementAmountCurrencyCode: (string) => void
  updateWhoReceivedCare: UpdateType
  updateExpenseRelatedTo: UpdateType
  updateStepchildAdoption: UpdateType
  updateWhatKindOfJourney: UpdateType
  updateJourneySubtype: UpdateType
  updateRelatedToStorageFees: UpdateType
  updateRelatedToMedication: UpdateType
  updateRelatedToInfertilityDiagnosis: UpdateType
  updateMedicallyNecessary: UpdateType
  updateMemberNotes: UpdateType
  updateAttestationIsMedicallyNecessary: UpdateType
  updateAttestationIsEligibilityRequirementMet: UpdateType
  updateAttestationIsMedicallyRelated: UpdateType
}

const GetReimbursedContext = React.createContext<GetReimbursedProps>(null)
export const useReimbursementContext = () => useContext(GetReimbursedContext)

type UpdateType = (value: SelectOptionType | boolean) => void

// @ts-expect-error TS7031
export const ReimbursementProvider = ({ children, expenseId, transDate, transAmount, onExit }) => {
  // @ts-expect-error TS7006
  function reducer(state: InitialState, action): any {
    switch (action.type) {
      case "SET_STATEMENT_FILES":
        return { ...state, statementFiles: action.payload }
      case "SET_ELIGIBLE_REIMBURSEMENT_AMOUNT":
        return { ...state, eligibleReimbursementAmount: action.payload }
      case "SET_ELIGIBLE_REIMBURSEMENT_AMOUNT_STRING":
        return { ...state, eligibleReimbursementAmountString: action.payload }
      case "SET_ELIGIBLE_REIMBURSEMENT_AMOUNT_CURRENCY_CODE":
        return { ...state, eligibleReimbursementAmountCurrencyCode: action.payload }
      case "SET_WHO_RECEIVED_CARE":
        return { ...state, whoReceivedCare: action.payload }
      case "SET_EXPENSE_RELATED_TO":
        return { ...state, expenseRelatedTo: action.payload }
      case "SET_STEPCHILD_ADOPTION":
        return { ...state, isStepchildAdoption: action.payload }
      case "SET_KIND_OF_JOURNEY":
        return { ...state, kindOfJourney: action.payload }
      case "SET_JOURNEY_SUBTYPE":
        return { ...state, journeySubtype: action.payload }
      case "SET_RELATED_TO_STORAGE_FEES":
        return { ...state, relatedToStorageFees: action.payload }
      case "SET_RELATED_TO_MEDICATION":
        return { ...state, relatedToMedication: action.payload }
      case "SET_RELATED_TO_INFERTILITY_DIAGNOSIS":
        return { ...state, isInfertilityDiagnosis: action.payload }
      case "SET_MEDICAL_NECESSITY":
        return { ...state, isMedicalNecessity: action.payload }
      case "SET_MEMBER_NOTES":
        return { ...state, memberNotes: action.payload }
      case "SET_ATTESTATION_IS_MEDICALLY_NECESSARY":
        return { ...state, attestationIsMedicallyNecessary: action.payload }
      case "SET_ATTESTATION_IS_ELIGIBILITY_REQUIREMENT_MET":
        return { ...state, attestationIsEligibilityRequirementMet: action.payload }
      case "SET_ATTESTATION_IS_MEDICALLY_RELATED":
        return { ...state, attestationIsMedicallyRelated: action.payload }
      default:
        throw new Error()
    }
  }

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  // context updaters
  const updateStatementFiles = (statementFiles: FileTypes): void =>
    dispatch({ type: "SET_STATEMENT_FILES", payload: statementFiles })
  const updateEligibleReimbursementAmountString = (value: string): void => {
    return dispatch({ type: "SET_ELIGIBLE_REIMBURSEMENT_AMOUNT_STRING", payload: value })
  }
  const updateEligibleReimbursementAmount = (value: number): void => {
    return dispatch({ type: "SET_ELIGIBLE_REIMBURSEMENT_AMOUNT", payload: value })
  }

  const updateEligibleReimbursementAmountCurrencyCode = (value: string): void =>
    dispatch({ type: "SET_ELIGIBLE_REIMBURSEMENT_AMOUNT_CURRENCY_CODE", payload: value })
  const updateWhoReceivedCare: UpdateType = (value) => dispatch({ type: "SET_WHO_RECEIVED_CARE", payload: value })
  const updateExpenseRelatedTo: UpdateType = (value) => dispatch({ type: "SET_EXPENSE_RELATED_TO", payload: value })
  const updateStepchildAdoption: UpdateType = (value) => dispatch({ type: "SET_STEPCHILD_ADOPTION", payload: value })
  const updateWhatKindOfJourney: UpdateType = (value) => dispatch({ type: "SET_KIND_OF_JOURNEY", payload: value })
  const updateJourneySubtype: UpdateType = (value) => dispatch({ type: "SET_JOURNEY_SUBTYPE", payload: value })
  const updateRelatedToStorageFees: UpdateType = (value) =>
    dispatch({ type: "SET_RELATED_TO_STORAGE_FEES", payload: value })
  const updateRelatedToMedication: UpdateType = (value) =>
    dispatch({ type: "SET_RELATED_TO_MEDICATION", payload: value })
  const updateRelatedToInfertilityDiagnosis: UpdateType = (value) =>
    dispatch({ type: "SET_RELATED_TO_INFERTILITY_DIAGNOSIS", payload: value })
  const updateMedicallyNecessary: UpdateType = (value) => dispatch({ type: "SET_MEDICAL_NECESSITY", payload: value })
  const updateMemberNotes: UpdateType = (value) => dispatch({ type: "SET_MEMBER_NOTES", payload: value })
  const updateAttestationIsMedicallyNecessary: UpdateType = (value) =>
    dispatch({ type: "SET_ATTESTATION_IS_MEDICALLY_NECESSARY", payload: value })
  const updateAttestationIsEligibilityRequirementMet: UpdateType = (value) =>
    dispatch({ type: "SET_ATTESTATION_IS_ELIGIBILITY_REQUIREMENT_MET", payload: value })
  const updateAttestationIsMedicallyRelated: UpdateType = (value) =>
    dispatch({ type: "SET_ATTESTATION_IS_MEDICALLY_RELATED", payload: value })
  return (
    <GetReimbursedContext.Provider
      value={{
        state,
        expenseId,
        transDate,
        transAmount,
        updateStatementFiles,
        updateEligibleReimbursementAmount,
        updateEligibleReimbursementAmountString,
        updateEligibleReimbursementAmountCurrencyCode,
        updateWhoReceivedCare,
        updateExpenseRelatedTo,
        updateStepchildAdoption,
        updateWhatKindOfJourney,
        updateJourneySubtype,
        updateRelatedToStorageFees,
        updateRelatedToMedication,
        updateRelatedToInfertilityDiagnosis,
        updateMedicallyNecessary,
        updateMemberNotes,
        updateAttestationIsMedicallyNecessary,
        updateAttestationIsEligibilityRequirementMet,
        updateAttestationIsMedicallyRelated,
        onExit
      }}
    >
      {children}
    </GetReimbursedContext.Provider>
  )
}
