export async function waitForMinimumDelay(promise: any, duration: any) {
  function delay() {
    return new Promise((resolve) => setTimeout(resolve, duration))
  }
  let error
  const delayPromise = delay()
  const promiseResult = await promise.catch((e: any) => {
    error = e
  })
  await delayPromise
  if (error) {
    throw error
  }
  return promiseResult
}
