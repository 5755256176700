import React, { useEffect, useState } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineContext"
import { useUserDeductible } from "../../../context/user/DeductibleContext"
import { Steps } from "../../workflow/steps"
import { CenteredLoadingIndicator } from "../../../views/molecules/Molecules"
import { Box, dayjs } from "@carrotfertility/carotene-core"
import { useCurrentUser } from "components/context/user/UserContext"

const enum CoverageTypeSelect {
  MEMBER_ONLY = "MEMBER_ONLY",
  MEMBER_DEPENDENTS_NOT_PARTNER = "MEMBER_DEPENDENTS_NOT_PARTNER",
  MEMBER_AND_PARTNER = "MEMBER_AND_PARTNER",
  MEMBER_PARTNER_DEPENDENTS = "MEMBER_PARTNER_DEPENDENTS",
  FAMILY = "FAMILY"
}

const viewName = Steps.UPDATE_PLAN_INFO

const determinePartnerCoverage = (memberCoverageTypeSelectValue: CoverageTypeSelect) => {
  switch (memberCoverageTypeSelectValue) {
    case CoverageTypeSelect.MEMBER_ONLY:
    case CoverageTypeSelect.MEMBER_DEPENDENTS_NOT_PARTNER:
      return {
        determinedPartnerCoverageTypeSelectValue: null,
        determinedPartnerCoverageType: null
      }
    case CoverageTypeSelect.MEMBER_AND_PARTNER:
      return {
        determinedPartnerCoverageTypeSelectValue: CoverageTypeSelect.MEMBER_AND_PARTNER,
        determinedPartnerCoverageType: CoverageTypeSelect.FAMILY
      }
    case CoverageTypeSelect.MEMBER_PARTNER_DEPENDENTS:
      return {
        determinedPartnerCoverageTypeSelectValue: CoverageTypeSelect.MEMBER_PARTNER_DEPENDENTS,
        determinedPartnerCoverageType: CoverageTypeSelect.FAMILY
      }
  }
}

function getJanuaryFirstOfCurrentYear(): Date {
  const currentYear = new Date().getFullYear()
  return new Date(currentYear, 0, 1)
}

function getDecemberThirtyFirstOfCurrentYear(): Date {
  const currentYear = new Date().getFullYear()
  return new Date(currentYear, 11, 31)
}

function UpdatePlanInfoStep(): JSX.Element {
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()
  const { setNextStep } = useStateMachine(viewName, null)
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(true)
  const { memberCoverageTypeSelectValue } = useCurrentUser()
  const { determinedPartnerCoverageTypeSelectValue, determinedPartnerCoverageType } =
    determinePartnerCoverage(memberCoverageTypeSelectValue)
  const defaultStartDate = dayjs(getJanuaryFirstOfCurrentYear())
  const defaultEndDate = dayjs(getDecemberThirtyFirstOfCurrentYear())

  useEffect(() => {
    setShowLoadingIndicator(false)
    const updateAnswers = async () => {
      await updateBenefitEnrollmentAnswers({
        memberPlanSourceSelectValue: "EMPLOYER",
        memberHasHSA: false,
        memberHasHDHP: false,
        memberCoverageStartDate: defaultStartDate,
        memberCoverageEndDate: defaultEndDate,
        partnerCoverageType: determinedPartnerCoverageType,
        partnerCoverageTypeSelectValue: determinedPartnerCoverageTypeSelectValue,
        partnerHasHSA: false,
        partnerHasHDHP: false,
        partnerCoverageStartDate: defaultStartDate,
        partnerCoverageEndDate: defaultEndDate
      })
      setNextStep(viewName, "")
    }

    updateAnswers()
  }, [
    updateBenefitEnrollmentAnswers,
    setNextStep,
    determinedPartnerCoverageType,
    determinedPartnerCoverageTypeSelectValue,
    defaultStartDate,
    defaultEndDate
  ])

  return <Box padding={(theme) => theme.tokens.spacing.xs}>{showLoadingIndicator && <CenteredLoadingIndicator />}</Box>
}

export { UpdatePlanInfoStep }
