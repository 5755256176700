import { useCurrentUser } from "components/context/user/UserContext"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  Box,
  Button,
  CloseIcon,
  Dialog,
  DialogContentText,
  IconButton,
  Link,
  Typography,
  useTheme
} from "@carrotfertility/carotene-core"
import { getHeap, HeapPropertyValue } from "utils/heap"
import { ConfirmationDialog } from "@carrotfertility/carotene-core-x"
import { Link as RouterLink, useHistory } from "react-router-dom"
import { useSendMessagePath } from "components/talk-to-carrot/hooks/useSendMessagePath"
import { SupportedLocale } from "types/Locale"

export type TimeTapEnvironment = "Production" | "Sandbox"

const timeTapBaseUrlByEnvironment: Record<TimeTapEnvironment, string> = {
  Production: "https://www.timetap.com/emb/394425",
  Sandbox: "https://www.timetap.com/emb/397462"
}

type TimeTapSchedulerProps = {
  reasonId: number
  locationId: number
  timeTapEnvironment?: TimeTapEnvironment
}

type DialogTimeTapSchedulerProps = TimeTapSchedulerProps & {
  open: boolean
  setOpen: (value: boolean) => void
  showChatConfirmation?: boolean
  setShowChatConfirmation?: (value: boolean) => void
}

export function useReportToHeapTimeTapChatScheduled(
  setShowChatConfirmation: (arg: boolean) => void,
  eventDetails: { eventName: string; properties: Record<string, HeapPropertyValue> },
  propertiesDoneLoading: boolean
) {
  const handleWindowEvent = React.useCallback(
    (event: any) => {
      if (event?.data === "TimeTapChatScheduled" && propertiesDoneLoading) {
        getHeap().track(eventDetails.eventName, eventDetails.properties)
        setShowChatConfirmation(true)
      }
    },
    [eventDetails.eventName, eventDetails.properties, propertiesDoneLoading, setShowChatConfirmation]
  )

  React.useEffect(() => {
    window.addEventListener("message", handleWindowEvent)
    return () => window.removeEventListener("message", handleWindowEvent)
  }, [handleWindowEvent])
}

export function DialogTimeTapScheduler({
  reasonId,
  locationId,
  timeTapEnvironment,
  open,
  setOpen
}: DialogTimeTapSchedulerProps): JSX.Element {
  const intl = useIntl()
  const theme = useTheme()
  const onClose = () => setOpen(false)

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "90%",
          height: "90%",
          maxHeight: "80vh",
          [theme.breakpoints.down("lg")]: {
            maxWidth: "800px"
          },
          [theme.breakpoints.up("lg")]: {
            maxWidth: "1000px"
          }
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <IconButton aria-label={intl.formatMessage({ defaultMessage: "close" })} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box height="90%">
        <TimeTapScheduler reasonId={reasonId} locationId={locationId} timeTapEnvironment={timeTapEnvironment} />
      </Box>
    </Dialog>
  )
}

// This only exists because there's a bug in TimeTap that does not show time selections in hi-IN
// Creating this function incase we need to add more to this override
// TimeTap should fix this bug by then end of Novemeber 2024
function localeOverride(locale: string) {
  if (locale === SupportedLocale.Hindi_India.languageTag) {
    return SupportedLocale.English_UnitedStates.languageTag
  }
  return locale
}

export function TimeTapScheduler({ reasonId, locationId, timeTapEnvironment }: TimeTapSchedulerProps): JSX.Element {
  const { employeeId, firstName, lastName, preferredEmail } = useCurrentUser()
  const intl = useIntl()
  const baseUrl = timeTapEnvironment
    ? timeTapBaseUrlByEnvironment[timeTapEnvironment]
    : timeTapBaseUrlByEnvironment["Production"]

  const timeTapUrl = new URL(baseUrl)
  timeTapUrl.searchParams.set("rid", String(reasonId))
  if (locationId) {
    timeTapUrl.searchParams.set("lid", String(locationId))
  }
  timeTapUrl.searchParams.set("clientFirstName", firstName)
  timeTapUrl.searchParams.set("clientLastName", lastName)
  timeTapUrl.searchParams.set("clientEmail", preferredEmail)
  timeTapUrl.searchParams.set("externalUserName", preferredEmail)
  timeTapUrl.searchParams.set("CF_CARROT_ID", employeeId.toString())

  timeTapUrl.hash = `/?${new URLSearchParams([["locale", localeOverride(intl.locale)]]).toString()}`

  return (
    <iframe
      src={timeTapUrl.toString()}
      width="100%"
      height="100%"
      title={intl.formatMessage({ defaultMessage: "Schedule a chat" })}
    />
  )
}

export function TimeTapConfirmationDialog({
  chatType,
  open,
  onClose
}: {
  chatType: "GROUPS" | "OTHER"
  open: boolean
  onClose: () => void
}): JSX.Element {
  const intl = useIntl()
  return (
    <ConfirmationDialog
      title={
        chatType === "GROUPS"
          ? intl.formatMessage({ defaultMessage: "Group session scheduled" })
          : intl.formatMessage({ defaultMessage: "Your chat is scheduled" })
      }
      open={open}
      primaryAction={
        <Button onClick={onClose}>
          <FormattedMessage defaultMessage="Close" />
        </Button>
      }
    >
      <DialogContentText>
        <FormattedMessage defaultMessage="We've emailed you a confirmation with the details." />
      </DialogContentText>
    </ConfirmationDialog>
  )
}

export function TimeTapSpecialistConfirmation(): JSX.Element {
  const history = useHistory()
  const sendMessagePath = useSendMessagePath()
  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box
        component="img"
        src={`/images/yellow-circle-check.svg`}
        alt=""
        sx={(theme) => ({ marginBlockEnd: theme.spacing(theme.tokens.spacing.md) })}
      />
      <Typography variant="h4">
        <FormattedMessage defaultMessage="Your chat is scheduled" />
      </Typography>
      <Typography sx={(theme) => ({ marginBlockStart: theme.spacing(theme.tokens.spacing.xs) })}>
        <FormattedMessage defaultMessage="We've emailed you a confirmation with the details." />
      </Typography>
      <Box sx={(theme) => ({ margin: theme.spacing(theme.tokens.spacing.md) })}>
        <Button variant="outlined" color="secondary" onClick={() => history.push("/")}>
          <FormattedMessage defaultMessage="Return home" />
        </Button>
      </Box>
      <Typography>
        <FormattedMessage
          defaultMessage="Need help? <link>Contact us</link>."
          values={{
            link: (linkContent) => (
              <Link component={RouterLink} color="inherit" to={sendMessagePath}>
                {linkContent}
              </Link>
            )
          }}
        />
      </Typography>
    </Box>
  )
}
