import { Box, Typography, Button } from "@carrotfertility/carotene-core"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import React, { ReactElement } from "react"
import { useIntl, FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { getCompanyDisplayName } from "redux/reducers/companyInfo"
import { getShortJourneyLabel } from "../hooks/useJourneyOptions"
import { CaseRateRenewalModalButtonGroup } from "./CaseRateRenewalModalButtonGroup"
import { CaseRateRenewalModalTopSection } from "./CaseRateRenewalModalTopSection"
import { AdvancedCheckInEmailLinkQueryString } from "./useCaseRateRenewals"
import { useGetCaseRateRenewalStatus } from "./useQueryCaseRateRenewals"
import queryString from "query-string"
import { Steps } from "./workflow"
import { OPTIONS } from "./steps/CarrotPlanOptionsStep"

type JourneyNotSupportedDialogProps = {
  memberJourney: string
}

export function JourneyNotSupportedDialog({ memberJourney }: JourneyNotSupportedDialogProps): ReactElement {
  const intl = useIntl()
  const { step } = queryString.parse(window.location.search)
  const { send, back } = useStateMachine(
    step === Steps.UPDATE_CARROT_PLAN_DETAILS || step === AdvancedCheckInEmailLinkQueryString.REFRESH_PLAN
      ? OPTIONS.REFRESH_MY_PLAN
      : null
  )
  const { showAdvancedCheckInFeatures } = useGetCaseRateRenewalStatus()
  const journeyLabel = getShortJourneyLabel(memberJourney)
  const companyName = useSelector(getCompanyDisplayName) ?? intl.formatMessage({ defaultMessage: "Your company" })
  const formattedMemberJourney = journeyLabel
    ? intl.formatMessage(journeyLabel)
    : intl.formatMessage({ defaultMessage: "your current journey" })

  return (
    <>
      <CaseRateRenewalModalTopSection header={intl.formatMessage({ defaultMessage: "Get a new Carrot Plan" })} />
      <Box>
        <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage
            defaultMessage="{companyName} no longer offers support for {formattedMemberJourney}. Get a new Carrot Plan or let us know you no longer need support."
            values={{ companyName, formattedMemberJourney }}
          />
        </Typography>
        <Box display="flex" justifyContent="flex-end"></Box>
        <CaseRateRenewalModalButtonGroup
          back={!showAdvancedCheckInFeatures && back}
          buttonOne={
            <Button variant="text" color="secondary" onClick={() => send(OPTIONS.DONE_FOR_NOW)}>
              <FormattedMessage defaultMessage="I'm done using Carrot" />
            </Button>
          }
          buttonTwo={
            <Button variant="contained" onClick={() => send(OPTIONS.REFRESH_MY_PLAN)}>
              <FormattedMessage defaultMessage="Get new Carrot Plan" />
            </Button>
          }
        />
      </Box>
    </>
  )
}
