import React from "react"
import { BackIcon, Box, Button, ForwardIcon, IconButton } from "@carrotfertility/carotene-core"
import { useHistory } from "react-router-dom"
import { useIntl } from "react-intl"

interface ArrowSubmitButtonProps {
  children: React.ReactNodeArray | React.ReactNode
  onClick?: () => unknown
  enabled?: boolean
}

export function ArrowSubmitButton({ children, onClick, enabled = true }: ArrowSubmitButtonProps): JSX.Element {
  return (
    <Button
      endIcon={<ForwardIcon />}
      variant="outlined"
      color="secondary"
      name="continue"
      disabled={!enabled}
      type="submit"
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export function ReimbursementDialogFraming({
  children
}: {
  children: React.ReactNode | React.ReactNode[]
}): JSX.Element {
  return <Box sx={{ minBlockSize: "500px", paddingBlock: (theme) => theme.tokens.spacing.xxxl }}>{children}</Box>
}

export function BackArrow({ onClick }: { onClick?: () => void }) {
  const history = useHistory()
  const intl = useIntl()
  return (
    <IconButton
      sx={{ inlineSize: "fit-content" }}
      size={"small"}
      aria-label={intl.formatMessage({ defaultMessage: "Back" })}
      color={"secondary"}
      onClick={() => {
        onClick ? onClick() : history.goBack()
      }}
    >
      <BackIcon />
    </IconButton>
  )
}
