import React, { useState } from "react"
import { useReimbursementContext } from "../../context/reimbursement/GetReimbursedContext"
import { Progress } from "../../views/modal/Progress"
import { Select } from "@carrotfertility/carotene"
import { ArrowSubmitButton, ReimbursementDialogFraming, BackArrow } from "../shared/layouts"
import { useStateMachine } from "../../context/stateMachine/StateMachineV2"
import { defineMessage, FormattedMessage, MessageDescriptor, useIntl } from "react-intl"
import { JourneyTypes } from "./ExpenseJourneyTypes"
import CarrotRxMedicationCard from "./modules/CarrotRxMedicationCard"
import { Box, DialogTitle } from "@carrotfertility/carotene-core"
import { DialogActionsBar } from "@carrotfertility/carotene-core-x"

export const AboutThisExpenseRelatedToMedication = (): JSX.Element => {
  const { updateRelatedToMedication, state } = useReimbursementContext()
  const { send, back } = useStateMachine()
  const [relatedToMedication, setRelatedToMedication] = useState(state.relatedToMedication || null)
  const intl = useIntl()

  type MedicationOption = {
    label: MessageDescriptor
    id: string
  }
  const medicationOptions: MedicationOption[] = [
    {
      id: "YES",
      label: defineMessage({ defaultMessage: "Yes" })
    },
    {
      id: "NO",
      label: defineMessage({ defaultMessage: "No" })
    }
  ]

  const handleContinueClick = (): void => {
    updateRelatedToMedication(relatedToMedication)
    state.expenseRelatedTo?.value === JourneyTypes.GC ? send("ADDITIONAL_INFO_ANYTHING_ELSE") : send("")
  }

  // @ts-expect-error TS7006
  const handleSelectionChange = (selectedValue): void => {
    setRelatedToMedication(selectedValue)
    updateRelatedToMedication(selectedValue)
  }

  const formattedRelatedToMedicationOptions = medicationOptions.map((option) => ({
    ...option,
    label: intl.formatMessage(option.label)
  }))

  return (
    <>
      <Progress
        numSteps={3}
        step={2}
        label={intl.formatMessage({ defaultMessage: "About This Expense" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={back} />
      <ReimbursementDialogFraming>
        <DialogTitle id="step-heading">
          <FormattedMessage defaultMessage="Is this related to medication expenses?" />
        </DialogTitle>
        <Box sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(theme.tokens.spacing.lg) }}>
          <Select
            aria-labelledby="step-heading"
            isSearchable={false}
            defaultValue={state.relatedToMedication?.label ? (state.relatedToMedication as any) : ""}
            onChange={handleSelectionChange}
            options={formattedRelatedToMedicationOptions}
            placeholder={intl.formatMessage({ defaultMessage: "Select option" })}
          />
        </Box>
        {state.relatedToMedication?.value === "YES" ? <CarrotRxMedicationCard /> : null}
      </ReimbursementDialogFraming>
      <DialogActionsBar
        primaryAction={
          <ArrowSubmitButton enabled={!!relatedToMedication.value} onClick={handleContinueClick}>
            <FormattedMessage defaultMessage="Continue" />
          </ArrowSubmitButton>
        }
      />
    </>
  )
}
