import { defineMessage } from "react-intl"
import { Benefit } from "carrot-api/types/Benefit"

const anythingElseOptions = [
  { label: defineMessage({ defaultMessage: "My journey to this point" }), value: "CURRENT_JOURNEY_DETAILS" },
  { label: defineMessage({ defaultMessage: "Other journeys I'm on" }), value: "OTHER_JOURNEY_DETAILS" },
  {
    label: defineMessage({ defaultMessage: "Cultural considerations that influence my journey" }),
    value: "CULTURAL_CONSIDERATIONS"
  },
  {
    label: defineMessage({ defaultMessage: "Religious considerations that influence my journey" }),
    value: "RELIGIOUS_CONSIDERATIONS"
  },
  { label: defineMessage({ defaultMessage: "Something else" }), value: "SOMETHING_ELSE" }
]

export const supportedFaithInformedJourneys = ["ASSISTED_REPRODUCTION", "TRY_PREGNANT", "PRESERVATION", "GESTATIONAL"]

export function useAnythingElseOptions(memberJourney: string, memberBenefit: Benefit) {
  const { faithInformedCareEnabled } = memberBenefit?.generalSupport || {}

  return anythingElseOptions.filter((option) => {
    if (option.value === "RELIGIOUS_CONSIDERATIONS") {
      return faithInformedCareEnabled && supportedFaithInformedJourneys.includes(memberJourney)
    }
    return true
  })
}
