import React, { FC } from "react"
import { SendMessage } from "../../talk-to-carrot/send-message/SendMessage"
import { MemberIneligible } from "./IneligibleStep"

type MemberIneligibleProps = {
  onExit: () => void
}

export const MemberIneligibleContainer: FC<MemberIneligibleProps> = () => (
  <SendMessage modalTopic={"MemberIneligible"} BenefitEnrollmentView={MemberIneligible} />
)
