import React from "react"
import useInitialSetup from "components/cmd-enrollment/hooks/useInitialSetup"
import { useStateMachine } from "components/context/stateMachine/StateMachineContext"
import { Steps } from "components/cmd-enrollment/workflow/steps"
import { useUserDeductible } from "components/context/user/DeductibleContext"
import { Container } from "@carrotfertility/carotene"
import { useSelector } from "react-redux"
import { getMemberHasSuccessfullySyncedInsurance } from "redux/reducers/userInfo"

function EmptySpace(): JSX.Element {
  // This is to prevent extra flashing of the modal.
  // We might want to look to make a more elegant solution for this in the future.
  return (
    <Container paddingTopBottom="giant">
      <Container paddingTopBottom="giant">
        <Container paddingTopBottom="giant">
          <Container paddingTopBottom="giant">
            <Container paddingTopBottom="medium">{}</Container>
          </Container>
        </Container>
      </Container>
    </Container>
  )
}

export function MemberShouldShowInsuranceStep(): JSX.Element {
  const viewName = Steps.MEMBER_SHOULD_SHOW_INSURANCE
  const { setNextStep } = useStateMachine(viewName, null)
  const memberHasSuccessfullySyncedInsurance = useSelector(getMemberHasSuccessfullySyncedInsurance)
  const { memberPlanRequiresInsuranceProviderInfo } = useUserDeductible()
  useInitialSetup(() => {
    const shouldCollectMemberInsurance =
      memberPlanRequiresInsuranceProviderInfo && !memberHasSuccessfullySyncedInsurance
    setNextStep(viewName, shouldCollectMemberInsurance)
  })
  return <EmptySpace />
}

export function PartnerShouldShowInsuranceStep(): JSX.Element {
  const { partnerPlanRequiresInsuranceProviderInfo } = useUserDeductible()
  const viewName = Steps.PARTNER_SHOULD_SHOW_INSURANCE
  const { setNextStep } = useStateMachine(viewName, null)
  useInitialSetup(() => {
    const shouldCollectPartnerInsurance = partnerPlanRequiresInsuranceProviderInfo
    setNextStep(viewName, shouldCollectPartnerInsurance)
  })
  return <EmptySpace />
}
