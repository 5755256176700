import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query"
import { carrotClient } from "../../utils/CarrotClient"
import ResponseError from "../../types/responseError"
import { SocialDeterminantsOfHealth } from "../../types/socialDeterminantsOfHealth"
import buildCarrotApiRetryPolicy from "../../utils/CarrotApiRetryPolicy"

const SDOH_QUERY_KEY = "sdohQueryKey"

export function useSocialDeterminantsOfHealthQuery(
  routingInfoId: number
): UseQueryResult<SocialDeterminantsOfHealth, ResponseError> {
  return useQuery([SDOH_QUERY_KEY], () => carrotClient.getSocialDeterminantsOfHealthInRouting(routingInfoId), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: buildCarrotApiRetryPolicy(1),
    staleTime: 30000
  })
}

export function useSocialDeterminantsOfHealthMutation(): UseMutationResult {
  const queryClient = useQueryClient()
  return useMutation(
    async (payload: SocialDeterminantsOfHealth) => {
      await carrotClient.setSocialDeterminantsOfHealth(payload)
    },
    {
      onSuccess: () => queryClient.refetchQueries([SDOH_QUERY_KEY]),
      retry: buildCarrotApiRetryPolicy(1)
    }
  )
}
