import { Link, Typography } from "@carrotfertility/carotene-core"
import { CircleImage } from "../../../views/atoms/images/CircleImage"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { RequirementCard } from "./requirement-card"

export const DoulaRequirementsCard = (): JSX.Element => {
  const intl = useIntl()
  return (
    <RequirementCard
      icon={<CircleImage iconName="icn-documents" backgroundColor={"black-10"} />}
      title={<FormattedMessage defaultMessage="Doula requirements" />}
      body={[
        <Typography key="attestation-form" variant="body2">
          <FormattedMessage
            defaultMessage="If you haven't already, send your doula the <link>required attestation form</link> so we can make sure they're eligible."
            values={{
              link: (linkContent) => (
                <Link
                  fontSize="inherit"
                  target="_blank"
                  href={"/doula-attestation"}
                  color="inherit"
                  aria-label={intl.formatMessage({ defaultMessage: "Send attestation form to doula" })}
                >
                  {linkContent}
                </Link>
              )
            }}
          />
        </Typography>,
        <Typography key="attestation-form-reimbursement" variant="body2">
          <FormattedMessage defaultMessage="You can still submit this expense, but we won't be able to reimburse you until we've received the attestation form." />
        </Typography>
      ]}
    />
  )
}
