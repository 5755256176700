import React from "react"
import { useUserContext } from "../../context/UserContext"
import { useGetHasCarrotPartner } from "../../hooks/useGetCarrotPartner"
import { Redirect } from "react-router"
import { providerFinderRootPath } from "pages/provider-finder/utils/providerFinderSearchUrl"
import { useCompanyContext } from "../../context/CompanyContextProvider"
import Settings from "../../lib/Settings"
import { Benefit } from "lib/carrot-api/types/Benefit"
import { getAvailableParentIdsByBenefitConfig } from "pages/provider-finder/utils/parentIdMapping"
import { Box, Typography, useTheme, useMediaQuery, Divider } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { SidebarLocationPicker } from "./SidebarLocationPicker"
import { RadiusSelect } from "./RadiusSelect"
import { TierInfoWhenCustomNetworksForSidebar } from "./TierInfoMessage"
import { CarrotPartnerFilter } from "./CarrotPartnerFilter"
import { VirtualServicesFilter } from "./VirtualServicesFilter"
import { ProviderTypeFilter } from "./ProviderTypeFilter"
import {
  FertilityWraparoundMessageForSidebar,
  FertilityWraparoundMessageWhenAdditionalEmployerBenefitsForSidebar
} from "./FertilityWraparoundMessage"
import { useMapViewContext } from "../../context/MapViewContext"
import { SidebarKeywordSearch } from "./SidebarKeywordSearch"
import useGetCustomNetworks from "pages/provider-finder/hooks/useGetCustomNetworks"
import { Loading } from "../Loading"
import { useGetHasVirtualProviders } from "../../hooks/useGetHasVirtualProviders"
import { useProviderNetworkForCurrentUser } from "components/views/app/navigation/hooks/useHasProviderFinder"
import { ProviderNetworksFilter } from "./ProviderNetworkFilter"

export function SidebarFilters({ searchLocation, benefit }: { searchLocation: string; benefit: Benefit }): JSX.Element {
  const theme = useTheme()
  const { companyCountryCode } = useUserContext()
  const { companyId } = useCompanyContext()
  const {
    data: hasCarrotPartnerData,
    isLoading: hasCarrotPartnerIsLoading,
    isError: hasCarrotPartnerIsError
  } = useGetHasCarrotPartner(companyCountryCode)
  const hasMultipleProviderTypes = getAvailableParentIdsByBenefitConfig(benefit).length > 1
  const hasFertilityWraparoundEnabled = benefit.providerFinder.showFertilityWrapAroundMessage
  const hasAdditionalEmployerBenefits = !!benefit.customizations.additionalEmployerBenefitMarkdown
  const hasCustomCoverageText = !!benefit.customizations.memberCustomCoverageExplanationMarkdown
  const networkConfigForEnv = Settings.COMPANY_IDS_WITH_TIERED_PROVIDER_NETWORK
  const { showMapView } = useMapViewContext()
  const showFilterDivider = useMediaQuery(theme.breakpoints.down("lg"))
  const {
    data: customNetworksData,
    isLoading: isCustomNetworksIsLoading,
    isError: isCustomNetworksIsError
  } = useGetCustomNetworks()
  const {
    data: hasVirtualProvidersData,
    isLoading: hasVirtualProvidersIsLoading,
    isError: hasVirtualProvidersIsError
  } = useGetHasVirtualProviders(companyCountryCode)
  const {
    data: providerNetworkData,
    isError: hasProviderNetworkDataError,
    isLoading: hasProviderNetworkDataLoading
  } = useProviderNetworkForCurrentUser()

  const providerNetworkIdentifier = providerNetworkData?.providerNetworkIdentifier
  const isLoading =
    hasCarrotPartnerIsLoading ||
    isCustomNetworksIsLoading ||
    hasVirtualProvidersIsLoading ||
    hasProviderNetworkDataLoading
  const isError =
    hasCarrotPartnerIsError || isCustomNetworksIsError || hasVirtualProvidersIsError || hasProviderNetworkDataError

  if (isLoading) {
    return <Loading />
  }
  if (isError) {
    return <Redirect to={`${providerFinderRootPath}/error`} />
  }

  const hasCarrotPartner = hasCarrotPartnerData?.hasCarrotPartner
  const isCustomNetworks = customNetworksData?.hasCustomNetworks
  const hasVirtualProviders = hasVirtualProvidersData?.isVirtual
  const showTierInfoMessage = networkConfigForEnv?.includes(companyId)
  const isCarrotCore = benefit.program.isCarrotLite
  const hasProviderNetworks = providerNetworkIdentifier

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: theme.palette.background.default
        }}
        borderRadius={(theme) => theme.tokens.borderRadius.md}
        paddingTop={(theme) => theme.spacing(theme.tokens.spacing.lg)}
        paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.sm)}
        paddingLeft={(theme) => theme.spacing(theme.tokens.spacing.sm)}
        paddingRight={(theme) => theme.spacing(theme.tokens.spacing.sm)}
        gap={(theme) => theme.spacing(theme.tokens.spacing.xl)}
      >
        <Box display="flex" flexDirection="column" gap={(theme) => theme.spacing(theme.tokens.spacing.sm)}>
          {!isCarrotCore || isCustomNetworks ? (
            <Box display="flex" flexDirection="column" gap={(theme) => theme.spacing(theme.tokens.spacing.xxs)}>
              <Typography variant="h6" paddingLeft={(theme) => theme.spacing(theme.tokens.spacing.md)}>
                <FormattedMessage defaultMessage="Search" />
              </Typography>
              <SidebarKeywordSearch />
            </Box>
          ) : null}

          <Box display="flex" flexDirection="column" gap={(theme) => theme.spacing(theme.tokens.spacing.xxs)}>
            <Typography variant="h6" paddingLeft={(theme) => theme.spacing(theme.tokens.spacing.md)}>
              <FormattedMessage defaultMessage="Location" />
            </Typography>
            <SidebarLocationPicker searchLocation={searchLocation} />
          </Box>
          {!showMapView && (
            <Box display="flex" flexDirection="column" gap={(theme) => theme.spacing(theme.tokens.spacing.xxs)}>
              <Typography variant="h6" paddingLeft={(theme) => theme.spacing(theme.tokens.spacing.md)}>
                <FormattedMessage defaultMessage="Distance" />
              </Typography>
              <RadiusSelect />
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        {showTierInfoMessage ? <TierInfoWhenCustomNetworksForSidebar showLink={hasCustomCoverageText} /> : null}
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: theme.palette.background.default
        }}
        borderRadius={(theme) => theme.tokens.borderRadius.md}
        paddingTop={(theme) => theme.spacing(theme.tokens.spacing.lg)}
        paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.sm)}
        paddingLeft={(theme) => theme.spacing(theme.tokens.spacing.sm)}
        paddingRight={(theme) => theme.spacing(theme.tokens.spacing.sm)}
        gap={(theme) => theme.spacing(theme.tokens.spacing.xl)}
      >
        {showFilterDivider && <Divider />}
        {(hasCarrotPartner || hasVirtualProviders || hasMultipleProviderTypes || hasFertilityWraparoundEnabled) && (
          <Box display="flex" flexDirection="column" gap={(theme) => theme.spacing(theme.tokens.spacing.xs)}>
            <Typography variant="h6" paddingLeft={(theme) => theme.spacing(theme.tokens.spacing.md)}>
              <FormattedMessage defaultMessage="Filter by" />
            </Typography>
            {hasCarrotPartner && <CarrotPartnerFilter />}
            {hasProviderNetworks && <ProviderNetworksFilter providerNetworkIdentifier={providerNetworkIdentifier} />}
            {hasVirtualProviders && <VirtualServicesFilter />}
            {hasMultipleProviderTypes && <ProviderTypeFilter benefit={benefit} />}
            {hasFertilityWraparoundEnabled &&
              (hasAdditionalEmployerBenefits ? (
                <FertilityWraparoundMessageWhenAdditionalEmployerBenefitsForSidebar />
              ) : (
                <FertilityWraparoundMessageForSidebar />
              ))}
          </Box>
        )}
      </Box>
    </>
  )
}
