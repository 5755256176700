import React from "react"
import PropTypes from "prop-types"
import { H4 } from "@carrotfertility/carotene"
import styled from "styled-components"

const HelperText = ({ children, color = "black" }) => <StyledHelperText className={color}>{children}</StyledHelperText>

const StyledHelperText = styled.span`
  font-family: Gotham A, Gotham B, Noto Sans, Noto Sans Thai Looped, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
`

const FlexRowCenter = ({ children }) => <div className="flex justify-center">{children}</div>

const Spacer = ({ height, width, expand }) => {
  const flex = expand ? "flex-auto " : ""
  const heightValue = height * 16 + "px"
  const widthValue = width * 16 + "px"

  return <div style={{ height: heightValue, width: widthValue, flexShrink: 0 }} className={flex} />
}

Spacer.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  expand: PropTypes.bool
}

const Label = ({ valid, htmlFor, children }) => {
  // This eventually should be replaced by the carotene label
  return (
    <label htmlFor={htmlFor}>
      <H4
        variant="primary"
        style={{
          color: valid ? "inherit" : "#d26556"
        }}
      >
        {children}
      </H4>
    </label>
  )
}

// eslint-disable-next-line formatjs/no-literal-string-in-jsx
const Asterisk = ({ color = "invalid-red" }) => <span className={color}>*</span>

const HR = ({ color, width = 1 }) => <div style={{ borderWidth: width }} className={`bb b--${color}`} />

HR.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string.isRequired
}

export {
  HelperText,
  // Layout elements
  Spacer,
  HR,
  FlexRowCenter,
  //Icons

  //Inputs
  Label,
  Asterisk
}
