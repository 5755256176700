import { IChangeEvent, withTheme } from "@rjsf/core"
import React from "react"
import { BaseInputTemplate } from "./templates/base-input"
import { SelectWidget } from "./widgets/select-widget"
import { mapSchemaToErrorMetadata } from "./errors"
import { CarrotJsonSchema, SchemaErrorMetadata } from "./types"
import { Box } from "@carrotfertility/carotene-core"
import { FieldTemplateProps, UiSchema, RJSFSchema } from "@rjsf/utils"
import validator from "@rjsf/validator-ajv6"

type MessageOverrides = {
  overrideTitle?: (originalTitle: string) => string
  overrideSelectLabel?: (fieldName: string, labelValue: string | number) => string | number
  overrideErrorMessage?: (originalMessage: string, carrotSchema: CarrotJsonSchema) => string
  usePlaceholders?: boolean
}

interface JsonSchemaFormProps extends Omit<MessageOverrides, "overrideErrorMessage"> {
  uiSchema?: UiSchema
  schema: CarrotJsonSchema
  formData?: unknown
  onSubmit?: (data: IChangeEvent<any, RJSFSchema, any>, event: React.FormEvent<any>) => void
  onChange?: (e: IChangeEvent<unknown>) => void
  children?: React.ReactNode
  overrideErrorMessage?: (originalErrorMessage: string, errorMetadata: SchemaErrorMetadata) => string
}

export const MessageOverrideContext = React.createContext<MessageOverrides>({})

const ThemedForm = withTheme({
  templates: {
    FieldTemplate: CustomFieldTemplate,
    BaseInputTemplate: BaseInputTemplate
  },
  widgets: {
    SelectWidget
  }
})

export function JsonSchemaForm(props: JsonSchemaFormProps) {
  return (
    <Box
      sx={{
        inlineSize: "100%",
        form: {
          inlineSize: "100%",
          fieldset: {
            display: "flex",
            columnGap: "10px",
            flexDirection: { xs: "column", md: "row" },
            flexWrap: "wrap"
          }
        }
      }}
    >
      <MessageOverrideContext.Provider
        value={{
          usePlaceholders: props.usePlaceholders,
          overrideTitle: props.overrideTitle,
          overrideSelectLabel: props.overrideSelectLabel,
          overrideErrorMessage: props.overrideErrorMessage
            ? (originalMessage: string, carrotSchema: CarrotJsonSchema) => {
                return props.overrideErrorMessage(
                  originalMessage,
                  mapSchemaToErrorMetadata(carrotSchema, props.overrideTitle)
                )
              }
            : undefined
        }}
      >
        <ThemedForm
          transformErrors={(errors) => {
            for (const error of errors) {
              error.message = error.name
            }
            return errors
          }}
          // eslint-disable-next-line no-console
          onError={(error) => console.log(error)} // TODO: This should probably be handled another way
          onSubmit={(data, event) => props.onSubmit && props.onSubmit(data, event)}
          onChange={(event) => props.onChange && props.onChange(event)}
          uiSchema={props.uiSchema}
          action="#"
          schema={props.schema}
          omitExtraData
          noHtml5Validate
          formData={removeNullsFromFormData(props.formData)}
          showErrorList={false}
          validator={validator}
        >
          {props.children}
        </ThemedForm>
      </MessageOverrideContext.Provider>
    </Box>
  )
}

function CustomFieldTemplate(props: FieldTemplateProps) {
  const { children, uiSchema } = props
  const styles = uiSchema?.props ?? { inlineSize: "100%" }

  return (
    <Box
      sx={{
        marginBlockEnd: "1rem",
        flexGrow: 1,
        maxInlineSize: "calc(100vw - 2rem)",
        ...styles
      }}
    >
      {children}
    </Box>
  )
}

function removeNullsFromFormData(formData: any) {
  if (!formData) {
    return undefined
  }

  return Object.keys(formData).reduce((prev, key: string) => {
    prev[key] = formData[key] === null ? undefined : formData[key]

    return prev
  }, {} as any)
}
