import React from "react"
import { ArrowSubmitButton, ReimbursementDialogFraming, BackArrow } from "../shared/layouts"
import { Progress } from "components/views/modal/Progress"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import { useReimbursementContext } from "components/context/reimbursement/GetReimbursedContext"
import { getCurrencyCode } from "redux/reducers/companyInfo"
import currencyCodesToAllowedDecimalPlacesMap from "../../../utils/CurrencyCode"
import { useSelector } from "react-redux"
import {
  InputLabel,
  Link,
  TextField,
  Autocomplete,
  DialogContentText,
  DialogTitle,
  Box
} from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { NumericFormat } from "react-number-format"
import { resolveCurrencyFormat } from "../../../utils/intl-currency"
import { DialogActionsBar } from "@carrotfertility/carotene-core-x"

export const AboutThisExpenseEligibleReimbursementAmount = (): JSX.Element => {
  const intl = useIntl()
  const { locale } = intl
  const { send, back } = useStateMachine()
  const {
    state,
    updateEligibleReimbursementAmount,
    updateEligibleReimbursementAmountString,
    updateEligibleReimbursementAmountCurrencyCode
  } = useReimbursementContext()
  const companyCurrencyCode = useSelector(getCurrencyCode)
  if (state.eligibleReimbursementAmountCurrencyCode == null) {
    updateEligibleReimbursementAmountCurrencyCode(companyCurrencyCode ?? "USD")
  }

  const canSubmit = state.eligibleReimbursementAmount && state.eligibleReimbursementAmountString.length !== 0

  // @ts-expect-error TS7006
  const handleCurrencySelectChange = (event, newValue): void => {
    if (newValue === null) return
    updateEligibleReimbursementAmountCurrencyCode(newValue?.value)
  }

  // @ts-expect-error TS7006
  const handleCurrencyInputChange = (event): void => {
    updateEligibleReimbursementAmount(event.value)
    updateEligibleReimbursementAmountString(event.formattedValue)
  }

  const handleContinueClick = (): void => {
    send("")
  }

  const format = resolveCurrencyFormat(locale, state.eligibleReimbursementAmountCurrencyCode)
  const suffix = format?.currencyPosition === "suffix" ? ` ${format?.currencySymbol}` : ""
  const prefix = format?.currencyPosition === "prefix" ? `${format?.currencySymbol} ` : ""

  const currencyOptions = currencySymbolsSelectValue(locale)
  const initialCurrencyValue = currencyOptions.find(
    ({ value }) => value === state.eligibleReimbursementAmountCurrencyCode
  ) ?? { label: "", value: "" }

  return (
    <>
      <Progress
        numSteps={3}
        step={2}
        label={intl.formatMessage({ defaultMessage: "About This Expense" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={back} />
      <ReimbursementDialogFraming>
        <DialogTitle>
          <FormattedMessage defaultMessage="How much did you pay for eligible care or services?" />
        </DialogTitle>
        <Box sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.tokens.spacing.lg }}>
          <DialogContentText>
            <FormattedMessage defaultMessage="Please select the currency you paid in." />
          </DialogContentText>
          <Link target="_blank" href={"/your-benefit-guide/coverage"} color="inherit">
            <FormattedMessage defaultMessage="Learn what's eligible." />
          </Link>
          <Autocomplete
            id="currencySelect"
            defaultValue={initialCurrencyValue}
            onChange={handleCurrencySelectChange}
            options={currencyOptions}
            value={initialCurrencyValue}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                placeholder={intl.formatMessage({ defaultMessage: "Select currency" })}
                name="currencySelect"
                label={<FormattedMessage defaultMessage="Currency Code" />}
              />
            )}
          />
          <div>
            <InputLabel required={true} htmlFor="currencySelect">
              <FormattedMessage defaultMessage="Amount" />
            </InputLabel>
            <NumericFormat
              id="currencyAmountInput"
              name="currencyAmountInput"
              customInput={TextField}
              placeholder={intl.formatMessage({ defaultMessage: "Total amount" })}
              value={state.eligibleReimbursementAmountString}
              onValueChange={(event) => handleCurrencyInputChange(event)}
              prefix={prefix}
              suffix={suffix}
              thousandSeparator={format?.groupDelimiter}
              decimalSeparator={format?.decimalDelimiter}
              decimalScale={format?.maximumFractionDigits}
              allowNegative={false}
            />
          </div>
        </Box>
      </ReimbursementDialogFraming>
      <DialogActionsBar
        primaryAction={
          <ArrowSubmitButton enabled={canSubmit} onClick={handleContinueClick}>
            <FormattedMessage defaultMessage="Continue" />
          </ArrowSubmitButton>
        }
      />
    </>
  )
}

function currencySymbolsSelectValue(locale: string) {
  const getCurrencySymbolFromCode = (currencyCode: string): string => {
    const formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currencyCode
    })

    let symbol
    formatter.formatToParts(0).forEach(({ type, value }) => {
      if (type === "currency") {
        symbol = value
      }
    })

    return symbol
  }

  const currencySymbols = []
  for (const currencyCode of Object.keys(currencyCodesToAllowedDecimalPlacesMap)) {
    const symbol = getCurrencySymbolFromCode(currencyCode)
    const label = symbol !== currencyCode ? `${symbol} ${currencyCode}` : `${symbol}`
    const option = {
      label: label,
      value: currencyCode
    }
    currencySymbols.push(option)
  }
  return currencySymbols
}
