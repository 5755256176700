import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { Survey, surveyQuestions } from "./lib/SurveyConstants"
import { NavFooter } from "./components/NavFooter"
import { SurveyFrame } from "./components/SurveyFrame"
import { BackgroundWrapper } from "./components/BackgroundWrapper"
import { useIntl } from "react-intl"
import { Title } from "services/page-title/Title"

const SurveyRouter = ({
  menu,
  onLastPageNext,
  onFirstPageBack,
  guideSlugId,
  guideRootPath,
  guideTitle
}: {
  menu: JSX.Element
  onLastPageNext: () => void
  onFirstPageBack: () => void
  guideSlugId: string
  guideRootPath: string
  guideTitle: string
}): JSX.Element => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const intl = useIntl()

  useEffect(() => {
    if (currentQuestionIndex > surveyQuestions?.questions.length - 1) {
      onLastPageNext()
    }
  }, [currentQuestionIndex, onLastPageNext])

  const history = useHistory()

  if (!surveyQuestions) {
    history.push(guideRootPath)
    return null
  }
  const renderSurveyNextText = (questionIndex: number, surveyQuestions: Survey): string => {
    if (questionIndex === 0) {
      return intl.formatMessage({ defaultMessage: "Survey questions" })
    } else if (questionIndex === surveyQuestions.questions.length - 1) {
      return intl.formatMessage({ defaultMessage: "Finish" })
    } else {
      return intl.formatMessage({ defaultMessage: "Next" })
    }
  }

  const onNext = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault()
    setCurrentQuestionIndex(currentQuestionIndex + 1)
  }
  const onBack = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault()
    setCurrentQuestionIndex(currentQuestionIndex - 1)
    if (currentQuestionIndex <= 0) {
      onFirstPageBack()
    }
  }

  const isFirstPage = false

  const nextText = renderSurveyNextText(currentQuestionIndex, surveyQuestions)
  return (
    <BackgroundWrapper guideSlugId={guideSlugId}>
      <Title title={guideTitle} productTitle="Carrot Academy" />

      <SurveyFrame
        menu={menu}
        survey={surveyQuestions}
        questionIndex={currentQuestionIndex}
        guideSlugId={guideSlugId}
      />
      <NavFooter
        onBack={onBack}
        onNext={onNext}
        isFirstPage={isFirstPage}
        nextText={nextText}
        navDataAttributes={{
          slug: "survey",
          pageIndex: currentQuestionIndex,
          sectionTotalPages: surveyQuestions.questions.length - 1
        }}
      />
    </BackgroundWrapper>
  )
}

export { SurveyRouter }
