import React from "react"
import { ButtonPrimary } from "components/views/atoms/buttons/ButtonPrimary"
import { useSelector } from "react-redux"
import { FlexContainer, H2, Container, TextLink, Body } from "@carrotfertility/carotene"
import { Modal } from "./Modal"
import { IlloUnderwaterDesktop } from "../atoms/Illustrations"
import { getFirstName } from "redux/reducers/userInfo"
import { getBenefitStarted, getBenefitStartDate } from "redux/derivedSelectors"
import { useCurrentUser } from "../../context/user/UserContext"
import { useAuthentication } from "components/context/authentication/AuthenticationProvider"
import { PiiText } from "services/tracking"
import { FormattedMessage, useIntl } from "react-intl"

const getApplicationNode = () => document.getElementById("body")

const BenefitNotStartedContainer = () => {
  const benefitStarted = useSelector(getBenefitStarted)
  const benefitStartDate = useSelector(getBenefitStartDate)
  const firstName = useSelector(getFirstName)
  const { preferredName } = useCurrentUser()

  return benefitStarted ? null : (
    <BenefitNotStarted benefitStartDate={benefitStartDate} preferredName={preferredName} firstName={firstName} />
  )
}

const BenefitNotStarted = ({ preferredName, firstName }) => {
  const { handleSignOut } = useAuthentication()
  const intl = useIntl()

  const handleSignOutClick = () => {
    handleSignOut()
  }

  return (
    <Modal
      getApplicationNode={getApplicationNode}
      showCloseButton={false}
      titleText={intl.formatMessage({
        defaultMessage: "Benefit not started"
      })}
      underlayClickExits={false}
      includeDefaultStyles={false}
      dialogClass="fixed top-0 left-0 w-100 h-100 z-9999 overflow-x-hidden overflow-y-auto flex justify-center items-center bg-benefit-started-modal"
      scrollDisabled
      focusDialog
    >
      <Container width="100%" paddingLeftRight="huge" paddingTopBottom="medium">
        <FlexContainer justifyContent="center" padding="large">
          <IlloUnderwaterDesktop className={null} />
        </FlexContainer>
        <H2 stack="tiny">
          <FormattedMessage
            defaultMessage="You're all set for now, <pii>{memberName}</pii>"
            values={{ memberName: preferredName ?? firstName, pii: (piiContent) => <PiiText>{piiContent}</PiiText> }}
          />
        </H2>
        <Container stack="large">
          <Body color={"black80"}>
            <FormattedMessage
              defaultMessage="We'll email you when your benefit is available. In the meantime, <link>reach out to your Care Team</link> if you have any questions. We're here to help."
              values={{
                link: (linkContent) => (
                  <TextLink size="medium" to="https://www.get-carrot.com/employee-support">
                    {linkContent}
                  </TextLink>
                )
              }}
            />
          </Body>
        </Container>

        <FlexContainer justifyContent={"flex-end"}>
          <ButtonPrimary onClick={handleSignOutClick}>
            <FormattedMessage defaultMessage="Sign out" />
          </ButtonPrimary>
        </FlexContainer>
      </Container>
    </Modal>
  )
}

export { BenefitNotStartedContainer }
