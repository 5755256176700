import React, { useState } from "react"
import { Select, SelectOptionType } from "@carrotfertility/carotene"
import { ArrowSubmitButton, ReimbursementDialogFraming, BackArrow } from "../shared/layouts"
import { Progress } from "components/views/modal/Progress"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import { useReimbursementContext } from "components/context/reimbursement/GetReimbursedContext"
import { useCurrentUser } from "components/context/user/UserContext"
import useBenefit from "../../dynamic-content/hooks/useGetBenefit"
import { JourneyTypes } from "./ExpenseJourneyTypes"
import { defineMessage, FormattedMessage, MessageDescriptor, useIntl } from "react-intl"
import { DialogTitle, DialogContentText, Box } from "@carrotfertility/carotene-core"
import { DialogActionsBar } from "@carrotfertility/carotene-core-x"

type ExpenseRelatedToChoice = {
  label: MessageDescriptor
  id: JourneyTypes
  filter?: (j: any) => boolean
}
const reimbursementJourneys: ExpenseRelatedToChoice[] = [
  {
    id: JourneyTypes.ADOPTION,
    label: defineMessage({ defaultMessage: "Adoption" }),
    filter: (j) => j.adoption.hasEligibleExpenses
  },
  {
    id: JourneyTypes.GC,
    label: defineMessage({ defaultMessage: "Gestational surrogacy" }),
    filter: (j) => j.gestationalCarrier.hasEligibleExpenses
  },
  {
    id: JourneyTypes.PRESERVATION,
    label: defineMessage({ defaultMessage: "Fertility preservation" }),
    filter: (j) => j.fertilityPreservation.hasEligibleExpenses
  },
  {
    id: JourneyTypes.FERTILITY_CARE,
    label: defineMessage({ defaultMessage: "Fertility care" }),
    filter: (j) => j.fertilityCare.hasEligibleExpenses
  },
  {
    id: JourneyTypes.PREGNANCY_AND_POSTPARTUM_SERVICES,
    label: defineMessage({ defaultMessage: "Pregnancy and postpartum services" }),
    filter: (j) => j.pregnancy.hasEligibleExpenses
  },
  {
    id: JourneyTypes.MENOPAUSE,
    label: defineMessage({ defaultMessage: "Menopause" }),
    filter: (j) => j.menopause.hasEligibleExpenses
  },
  {
    id: JourneyTypes.LOW_TESTOSTERONE,
    label: defineMessage({ defaultMessage: "Low testosterone" }),
    filter: (j) => j.lowTestosterone.hasEligibleExpenses
  },
  {
    id: JourneyTypes.GENDER_AFFIRMING_CARE,
    label: defineMessage({ defaultMessage: "Gender-affirming care" }),
    filter: (j) => j.genderAffirmingCare.hasEligibleExpenses
  },
  {
    id: JourneyTypes.PATHFINDING,
    label: defineMessage({ defaultMessage: "Something else" })
  }
]

export const AboutThisExpenseExpenseRelatedTo = (): JSX.Element => {
  const { send, back } = useStateMachine()
  const { updateExpenseRelatedTo, updateJourneySubtype, updateWhatKindOfJourney, state } = useReimbursementContext()
  const [answer, setAnswer] = useState(state.expenseRelatedTo || null)
  const { isUsa } = useCurrentUser()
  const { data: benefit } = useBenefit()

  // @ts-expect-error TS7006
  const handleChange = (value): void => {
    setAnswer(value)
  }

  // @ts-expect-error TS7006
  const isQME = (answer): boolean => {
    return answer.value === "FERTILITY_CARE" || answer.value === "PRESERVATION" || answer.value === "PATHFINDING"
  }

  const updateJourneyTypeAndSubtype = (): void => {
    // if expenseRelatedTo changes clear out whatKindOfJourney/JourneySubType
    if (state.expenseRelatedTo !== answer) {
      const emptySelectOptionType = {} as SelectOptionType
      updateJourneySubtype(emptySelectOptionType)
      updateWhatKindOfJourney(emptySelectOptionType)
    }
  }

  const handleContinueClick = (): void => {
    updateJourneyTypeAndSubtype()
    updateExpenseRelatedTo(answer)
    if (answer.value === "ADOPTION" && isUsa) send("ADOPTION")
    else if (
      [
        JourneyTypes.PREGNANCY_AND_POSTPARTUM_SERVICES,
        JourneyTypes.MENOPAUSE,
        JourneyTypes.LOW_TESTOSTERONE,
        JourneyTypes.GENDER_AFFIRMING_CARE
        // @ts-expect-error TS7053
      ].includes(JourneyTypes[answer.value])
    )
      send("WHAT_SERVICES")
    else if (answer.value === JourneyTypes.PRESERVATION || answer.value === "GC") send("STORAGE_FEES")
    else if (!isQME(answer)) send("ADDITIONAL_INFO_ANYTHING_ELSE")
    else if (answer.value === JourneyTypes.FERTILITY_CARE) send("FERTILITY_CARE")
    else send("")
  }
  const intl = useIntl()

  const filteredAndFormattedReimbursementJourneyOptions = reimbursementJourneys
    .filter((c) => !c.filter || c.filter(benefit.journeys))
    .map((journeyOption) => ({
      ...journeyOption,
      label: intl.formatMessage(journeyOption.label)
    }))

  return (
    <>
      <Progress
        numSteps={3}
        step={2}
        label={intl.formatMessage({ defaultMessage: "About This Expense" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={back} />
      <ReimbursementDialogFraming>
        <DialogTitle id="step-heading">
          <FormattedMessage defaultMessage="What is this expense related to?" />
        </DialogTitle>
        <Box sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(theme.tokens.spacing.lg) }}>
          <DialogContentText>
            <FormattedMessage defaultMessage="If you're not sure, choose the option that seems like the best fit." />
          </DialogContentText>
          <Select
            aria-labelledby="step-heading"
            defaultValue={state.expenseRelatedTo?.label ? (state.expenseRelatedTo as any) : ""}
            isSearchable={false}
            placeholder={intl.formatMessage({ defaultMessage: "Select" })}
            onChange={handleChange}
            options={filteredAndFormattedReimbursementJourneyOptions}
          />
        </Box>
      </ReimbursementDialogFraming>
      <DialogActionsBar
        primaryAction={
          <ArrowSubmitButton enabled={!!answer.value} onClick={handleContinueClick}>
            <FormattedMessage defaultMessage="Continue" />
          </ArrowSubmitButton>
        }
      />
    </>
  )
}
